
/**
 * The default UI renderer of Asteikko. This can be overridden by setting
 * another object to be the renderer with Asteikko.reader.setUiRenderer().
 */

var AsteikkoDefaultUiRenderer = {

  options: {
    printMenuHeader: true,
    menu: [
      {
        'label': "Sisältö",
        'contentTpl': "default_menu_contentlist",
        'classes': 'clickdelay'
      },
      {
        'label': "Ohjeet",
        'contentTpl': "default_menu_readingguide",
        'classes': 'clickdelay'
      },
      {
        'label': "Haku",
        'contentTpl': "default_menu_search",
        'classes': 'clickdelay online-only asmag-search'
      }
    ]
  },

  _initProgressBar: function( contextIssueId, initialPageIndex, pageCount, pageNumberingOffset ) {

    var pageIndicatorTimeout = 0;
    var pageProgressWidth = 0;
    var pageProgressStepWidth = 0;

    var pageShowNumber = true;

    var pageIndicatorTimeoutLength = 3000;
    var pageProgressMinWidth = 50;

    function schedulePageIndicatorTimeout() {
      
      clearTimeout(pageIndicatorTimeout);
      pageIndicatorTimeout = setTimeout(function() {
        if ($('#issueMenu').hasClass('open')) return;
        $("#pageIndicator").fadeOut();
      }, pageIndicatorTimeoutLength);
    }

    function updateProgressSize() {
    
      if ( pageCount < 2 ) return;
      
      var totalWidth = $("#pageTrack").width();
      var widthPerPage = totalWidth / pageCount;

      /* Determine the progress width */
      pageProgressWidth = widthPerPage > pageProgressMinWidth ?
                          widthPerPage : pageProgressMinWidth;

      /* Calculate step width */
      pageProgressStepWidth = (totalWidth - pageProgressWidth) / (pageCount - 1);

      /* Set the style */
      $("#pageProgress").width(pageProgressWidth);
    }

    function updateProgressForCurrentIndex() {
      updateProgress( ( window.currentIndex !== undefined ) ? window.currentIndex : initialPageIndex );
    }

    function updateProgress( currentIndex ) {
    
      if ( pageCount < 2 )
        return;
      
      updateProgressSize();
    
      if (!pageProgressWidth || !pageProgressStepWidth || currentIndex < 0 || currentIndex >= pageCount ) {
        return;
      }

      var left = pageProgressStepWidth * currentIndex;
      var progress = $("#pageProgress");
      if (typeof progress.transit !== "undefined") {
        progress.transit({x: left + "px"});
      } else {
        progress.css({"left": left + "px"});
      }

      if (pageShowNumber) {
        progress.html(currentIndex + 1 + ( pageNumberingOffset || 0 ) + "/" + pageCount);
      } else {
        progress.html("");
      }
    }

    var progressResizeTimeout;
    $( window ).off( "debouncedresize.asmagissue_progbar" );
    $( window ).on( "debouncedresize.asmagissue_progbar", function() {
      window.clearTimeout( progressResizeTimeout );
      progressResizeTimeout = window.setTimeout( updateProgressForCurrentIndex, 500 );
    } );

    $( window ).off( 'pageIndexChanged.asmagissue_progbar' );
    $( window ).on( 'pageIndexChanged.asmagissue_progbar', function() {
      if ( settings.pageprogressindicator.showonpageflip ) {
        $("#pageIndicator").fadeIn("fast");
      }
      schedulePageIndicatorTimeout();
      updateProgressForCurrentIndex();
    } );
    
    $( window ).off( 'asmag_startpagecaching.asmagissue_progbar' );
    $( window ).on( 'asmag_startpagecaching.asmagissue_progbar', function( event, issueId ) {

      if ( issueId != contextIssueId )
        return;
      $( '#pageDownloadProgress' ).show().width( 0 );
    } );
    
    $( window ).off( 'asmag_endpagecaching.asmagissue_progbar' );
    $( window ).on( 'asmag_endpagecaching.asmagissue_progbar', function( event, issueId ) {

      if ( issueId != contextIssueId )
        return;
      $( '#pageDownloadProgress' ).fadeOut( 2000 );
      window.setTimeout( function() {
        $( '#pageDownloadProgress' ).width( 0 );
      }, 2000 );
    } );
    
    $( window ).off( 'asmag_pagecached.asmagissue_progbar' );
    $( window ).on( 'asmag_pagecached.asmagissue_progbar', function( event, issueId, pageNr, totalPages ) {

      if ( issueId != contextIssueId )
        return;
      $( '#pageDownloadProgress' ).width( Math.round( ( pageNr / totalPages ) * 100 ) +'%' );
    } );
    
    // Bind the indicator drag event handlers
  
    var hasTouch = 'ontouchstart' in window;
    var pageIndicatorStartEvent = hasTouch ? 'touchstart' : 'mousedown';
    var pageIndicatorMoveEvent = hasTouch ? 'touchmove' : 'mousemove';
    var pageIndicatorEndEvent = hasTouch ? 'touchend' : 'mouseup';
    var prevX = 0;
    var draggedToIndex = 0;
    var dragging = false;
    
    function onStart( e ) {
  
      if ( !window.pages || pages.length < 2 ) return;
      
      var origEvent = e.originalEvent;
      clearTimeout(pageIndicatorTimeout);
      // Make sure indicator is visible
      $("#pageIndicator").fadeIn();
      e.preventDefault();
      e.stopPropagation();
      dragging = true;
      prevX = hasTouch ? origEvent.touches[0].pageX : origEvent.pageX;
    }
    
    function onMove( e ) {
      
      if ( !dragging ) return true;
      
      var origEvent = e.originalEvent;
      e.preventDefault();
      
      var point = hasTouch ? origEvent.touches[0] : origEvent;
      var deltaX = point.pageX - prevX;
      var progress = $("#pageProgress");
      // 'x' exists if using jQuery.transit, otherwise we're using CSS 'left'
      var left = parseInt( progress.css('x') || progress.css('left'), 10 );
      var newLeft = left + deltaX || 0;
      newLeft = ( newLeft > pageProgressStepWidth * ( pages.length - 1 ) )
        ? pageProgressStepWidth * ( pages.length - 1 )
        : newLeft;
      newLeft = ( newLeft < 0 ) ? 0 : newLeft;
      
      draggedToIndex = Math.round( newLeft / pageProgressStepWidth );
      
      if (pageShowNumber) progress.html(draggedToIndex + 1 + ( pageNumberingOffset || 0 ) + "/" + pages.length);
      else progress.html("");
      
      if (typeof progress.transit !== "undefined") {
        progress.transit({x: newLeft + "px", duration: 0});
      } else {
        progress.css({"left": newLeft + "px"});
      }
      
      prevX = point.pageX;
    }
    
    function onEnd( e ) {
    
      if ( !dragging ) return true;
      
      var origEvent = e.originalEvent;
      e.preventDefault();
      
      dragging = false;
      var point = hasTouch ? origEvent.changedTouches[0] : origEvent;
      var deltaX = point.pageX - prevX;
      var progress = $("#pageProgress");
      // 'x' exists if using jQuery.transit, otherwise we're using CSS 'left'
      var left = parseInt( progress.css('x') || progress.css('left'), 10 );
      var newLeft = left + deltaX || 0;
      newLeft = ( newLeft > pageProgressStepWidth * ( pages.length - 1 ) )
        ? pageProgressStepWidth * ( pages.length - 1 )
        : newLeft;
      newLeft = ( newLeft < 0 ) ? 0 : newLeft;
      draggedToIndex = Math.round( newLeft / pageProgressStepWidth );
      goToPageById( parseInt( pages[draggedToIndex].id, 10 ) );
    }
    
    $("#pageProgress").
      on( pageIndicatorStartEvent, onStart );
    // Move and end handlers need to be in the window element, as the cursor 
    // might not be over the #pageIndicator when dragging the element
    $(window).
      off( pageIndicatorMoveEvent +'.asteikkoissue' ). // unbind old handlers first
      off( pageIndicatorEndEvent +'.asteikkoissue' ).
      on( pageIndicatorMoveEvent +'.asteikkoissue', onMove ).
      on( pageIndicatorEndEvent +'.asteikkoissue', onEnd );
    
    $( '#issueMenu' ).on( 'trayClosed', function() {
      schedulePageIndicatorTimeout();
    } );
    
    $( '#issueMenu' ).on( 'trayOpened', function() {
      clearTimeout(pageIndicatorTimeout);
      $("#pageIndicator").fadeIn('fast');
      updateProgressForCurrentIndex();
    } );

    updateProgressForCurrentIndex();
  },

  beforeRender: function( context ) {

    context.menu = this.menu;

    for ( var i = 0; i < context.issue.pages.length; i++ ) {

      var page = context.issue.pages[ i ];

      // Is advertisement?
      if ( page.template && page.template.indexOf( 'ilmoitus' ) > -1 )
        page.ignore_in_toc = true;

      // Thumbnail
      if ( page.thumbnail )
        page.thumbnail_for_tpl = page.thumbnail;
      else
        page.thumbnail_for_tpl = context.env.base_url +'/'+ settings.styling.tocholder;
    }
    
    context.allowPageChanging = !this.disableDefaultPageFlip( context );
  },

  render: function( context, uiRootElem ) {

    var outsideWrapper = Asteikko.ui.renderTemplate( 'default_ui_outside_wrapper', context );
    if ( outsideWrapper )
      $( uiRootElem ).append( outsideWrapper );

    var insideWrapper = Asteikko.ui.renderTemplate( 'default_ui_inside_wrapper', context );
    if ( insideWrapper )
      $( uiRootElem ).find( '#magazine-wrapper' ).append( insideWrapper );
  },

  afterRender: function( context, uiRootElem ) {
    
    var $issueMenu = $( uiRootElem ).find( "#issueMenu" );
    var menuItems = [];
    
    for ( var i = 0; i < this.options.menu.length; i++ ) {
      
      var menuItem = { label: this.options.menu[ i ].label };
      
      if ( this.options.menu[ i ].contentTpl ) {
        var langPostfix = ( context.issue.language )
          ? '_'+ context.issue.language
          : '';
        // Try a language-specific template that has a postfix like '_en'
        var html = Asteikko.ui.renderTemplate( this.options.menu[ i ].contentTpl + langPostfix, context );
        if ( !html )
          html = Asteikko.ui.renderTemplate( this.options.menu[ i ].contentTpl, context );
        menuItem.contentHtml = html;
      }
      
      if ( this.options.menu[ i ].classes )
        menuItem.classes = this.options.menu[ i ].classes;
      
      menuItems.push( menuItem );
    }

    $issueMenu.on( 'click', '.tableOfContents li a', function( event ) {
      // We only prevent the A element default navigation if the permalink 
      // points to a page that's open in the current issue. Otherwise we let 
      // the browser navigate to the href.
      if ( Asteikko.reader.goToPageByPermalink( $( this ).attr( 'href' ) ) ) {
        event.preventDefault();
        event.stopPropagation();
        $("#issueMenu").closeTray();
      }
    } );

    if ( this.options.printMenuHeader ) {

      $issueMenu.trayMenu({
        headerHtml: Asteikko.ui.renderTemplate( 'default_menu_header', context ), 
        headerHeight: 75, 
        list: menuItems
      });
    }
    else {

      $issueMenu.trayMenu({
        list: menuItems
      });
    }

    $issueMenu.find(".trayMenuItem").click(function(e) {
      e.stopPropagation();
    });
    $issueMenu.find(".clickdelay").each(function(t) {
      new NoClickDelay(this);
    });
    $issueMenu.on("trayClosed", function(){
      $( '#overlay' ).fadeOut( 200 );
    });

    if ( context.allowPageChanging )
      this._initProgressBar( context.issue.id, context.initialPageIndex, context.issue.pages.length, context.issue.page_numbering_offset );

    $( window ).off( 'pageIndexChanged.asmagissue_defaultui' );
    $( window ).on( 'pageIndexChanged.asmagissue_defaultui', function( event, oldIndex, currentIndex ) {

      if ( !window.asteikkoIssue )
        return;

      $( '#androidhelperleft, #androidhelperright' ).show();
      if ( currentIndex == 0 )
        $( '#androidhelperleft' ).hide();
      if ( currentIndex == asteikkoIssue.pages.length - 1 )
        $( '#androidhelperright' ).hide();
      if (oldIndex > -1)
        $('#toc-item-' + oldIndex).removeClass("toc-item-selected");
      if (currentIndex > -1)
        $('#toc-item-' + currentIndex).addClass("toc-item-selected");
    } );
  },

  beforeUnload: function( context, uiRootElem ) {

    // TODO: unbind event handlers here, instead of right before binding them 
    // in afterRender
  },

  afterUnload: function( context ) {

    // This is a stub. This method can be used to do things after the issue
    // has fully closed.
  },

  disableDefaultPageFlip: function( context ) {

    // This method can be used to disable the default page switching logic in
    // the reader and renderer, i.e. the reader's horizontal swiping and 
    // visibility of the renderer's page switching buttons. Pages can still be
    // changed with the reader's JavaScript functions (see Asteikko.reader).
    return false;
  }
};

Asteikko.reader.setGetRendererFunction( function( context ) {
  return AsteikkoDefaultUiRenderer;
} );
