if ( !window.Asteikko ) window.Asteikko = {};

(function() {

  "use strict";

  /* Member structs */

  Asteikko.enableLog = true;
  Asteikko.isDeveloper = false; // E.g. a WP admin

  // Default connectivity status is online (vs offline). This is updated as 
  // often as possible.
  Asteikko.online = true;

  Asteikko.log = function(msg) {
    if ( Asteikko.enableLog && typeof window.console === 'object' && window.console.log ) {
      console.log(msg);
    }
  };

  Asteikko.warn = function(msg) {
    if ( Asteikko.enableLog && typeof window.console === 'object' ) {
      if ( window.console.warn )
        console.warn(msg);
      else
        console.log(msg);
    }
  };

  Asteikko.error = function(msg) {
    if ( Asteikko.enableLog && typeof window.console === 'object' ) {
      if ( window.console.error )
        console.error(msg);
      else
        console.log(msg);
    }
  };

  Asteikko.center = function (elem) {
    $(elem).each(function() {
      $(this).css({
        left:($(window).width() - $(this).width()) / 2, 
        top: ($(window).height() - $(this).height()) / 2
      });
    });
  };

  jQuery.fn.center = function () {
    this.css("top", Math.max(0, (($(window).height() - this.outerHeight()) / 2)) + "px");
    this.css("left", Math.max(0, (($(window).width() - this.outerWidth()) / 2)) + "px");
    return this;
  };

  // Online/offline related functions. These are used to replace certain 
  // methods in Asteikko when connectivity changes (listen for the 
  // "asmag_connectivity" event on the window object). This is done so that 
  // client code doesn't have to call different methods when online or offline. 
  // The online and offline functions must have the same interface 
  // (arguments and return values).
  Asteikko.onlineFuncs = {};
  Asteikko.offlineFuncs = {};

  // If the browser doesn't support CSS transforms, disable animations and 
  // fallback to immediate show/hide.
  if ( !asmagModernizr.csstransforms ) {
    $.fx.off = true;
    $.transit.enabled = false;
  }


  /*****************************************************************************
    Issue buying
  *****************************************************************************/

  Asteikko._closeIssuePaywall = function( event ) {

    if ( event )
      event.preventDefault();
    $( '#asmag-paywall-wrapper' ).fadeOut();
  };

  Asteikko._onClickIssuePaywallWrapper = function( event ) {

    if ( !$( '#asmag-paywall-wrapper' ).is( event.target ) )
      return;
    Asteikko._closeIssuePaywall( event );
  };

  Asteikko._initIssuePaywall = function() {

    $( '#asmag-paywall-wrapper' ).remove();

    var button = $( '<i class="asmag-dialog-close fa fa-times-circle"></i>' ).
      click( Asteikko._closeIssuePaywall );

    $( '<div id="asmag-paywall-wrapper" class="asmag-dialog-wrapper"></div>' ).
      click( Asteikko._onClickIssuePaywallWrapper ).
      append(
        $( '<div id="asmag-paywall" class="asmag-notificationBox"></div>' ).
          append( '<div class="asmag-notificationBoxContent"></div>' ).
          append( button )
      ).
      appendTo( 'body' );

    $( '#asmag-paywall-wrapper .asmag-notificationBox' ).center();
  };

  Asteikko._onPaywallContentLoaded = function( data ) {
    setTimeout( function() {
      $( '#asmag-paywall-wrapper .asmag-notificationBoxContent' ).prepend( data );
      $( '#asmag-paywall-wrapper .asmag-notificationBox' ).center();
    }, 500 );
  };

  Asteikko.showIssuePaywall = function( issueId ) {

    Asteikko._initIssuePaywall();
    $.get( Asteikko.env.site_url +'/asmag/issuepaywall?id='+ parseInt( issueId, 10 ) ).
      done( Asteikko._onPaywallContentLoaded );
  };

  Asteikko.showIssueLoggedInPaywall = function( issueId ) {

    Asteikko._initIssuePaywall();
    $.get( Asteikko.env.site_url +'/asmag/issueloggedinpaywall?id='+ parseInt( issueId, 10 ) ).
      done( Asteikko._onPaywallContentLoaded );
  };


  /*****************************************************************************
    Session stuff
  *****************************************************************************/

  Asteikko.lastLoginStatus = false;

  // FIXME: override Asteikko.getUserInfo() in asteikko-iosdecorator.js and make
  // it use the Asteikko.sessionToken variable. Cookies (i.e. WP's login cookie)
  // are not supported in the Asteikko Native app, so we need to send
  // Asteikko.sessionToken in all requests that need user authentication.
  Asteikko.getUserInfo = function( callback ) {

    function updateLoginStatus( newStatus ) {
      if (Asteikko.lastLoginStatus != newStatus) {
        Asteikko.lastLoginStatus = Boolean( newStatus );
        $(window).trigger('asmag_loginstatus', Asteikko.lastLoginStatus);
      }
    }

    if ( !Asteikko.online ) {
      updateLoginStatus( false );
      if (typeof callback === 'function') callback(false);
      return;
    }

    $.ajax({
      url: Asteikko.env.site_url + '/asmag/getuserinfo?t=' + new Date().getTime(),
      type: 'GET',
      dataType: 'json',
      success: function(data) {
        if (data.code == 0 && data.result) {
          if ( data.result.isAdmin ) Asteikko.isDeveloper = true;
          else Asteikko.isDeveloper = false;
          updateLoginStatus( data.result.isLoggedIn );
          if (typeof callback === 'function') callback(data.result);
        }
        else {
          updateLoginStatus( false );
          if (typeof callback === 'function') callback(false);
        }
      },
      error: function( jqXhr, textStatus, errorThrown ) {

        updateLoginStatus( false );
        if (typeof callback === 'function') callback(false);
      }
    });
  };

  // Whenever connectivity changes, re-check login status
  $(window).bind('asmag_connectivity', Asteikko.getUserInfo);


  /*****************************************************************************
    Cookie related functions
  *****************************************************************************/

  Asteikko.createCookie = function (name, value, days) {
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      var expires = "; expires=" + date.toGMTString();
    } else {
      var expires = "";
    }
    document.cookie = name + "=" + escape(value) + expires + "; path=/";
  };

  Asteikko.readCookie = function (name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) == 0) {
        return unescape(c.substring(nameEQ.length, c.length));
      }
    }
    return null;
  };

  Asteikko.eraseCookie = function (name) {
    Asteikko.createCookie(name, "", -1);
  };


  /*****************************************************************************
    Overlay control
  *****************************************************************************/

  Asteikko.overlayClosePostFunc = null;
  Asteikko.overlayClosePreFunc = null;
  Asteikko.overlayOpenPostFunc = null;
  Asteikko.overlayOpenPreFunc = null;

  Asteikko.overlayClose = function (params) {
    if (typeof params !== "undefined" && typeof params.target !== "undefined" && params.target) {
      var speed = "slow";
      if (typeof params.speed !== "undefined") {
        speed = params.speed;
      }
    } else {
      return false;
    }
    if (typeof params !== "undefined" && params && typeof params.preFunc !== "undefined" && typeof params.preFunc === "function") {
      params.preFunc();
    }
    if (typeof Asteikko.overlayClosePreFunc === "function") { Asteikko.overlayClosePreFunc(); }
    $(params.target).fadeOut(speed, function() {
      if (typeof params !== "undefined" && params && typeof params.postFunc !== "undefined" && typeof params.postFunc === "function") {
        params.postFunc();
      }
      if (typeof Asteikko.overlayClosePostFunc === "function") { Asteikko.overlayClosePostFunc(); }      
    });
    Asteikko.overlayClosePostFunc = null;
    Asteikko.overlayClosePreFunc = null;
  };

  Asteikko.overlayOpen = function (params) {
    if (typeof params !== "undefined" && typeof params.target !== "undefined" && params.target) {
      var speed = "slow";
      if (typeof params.speed !== "undefined") {
        speed = params.speed;
      }
    } else {
      return false;
    }
    if (typeof params !== "undefined" && params && typeof params.preFunc !== "undefined" && typeof params.preFunc === "function") {
      params.preFunc();
    }
    if (typeof Asteikko.overlayOpenPreFunc === "function") { Asteikko.overlayOpenPreFunc(); }
    $(params.target).fadeIn(speed, function() {
      if (typeof params !== "undefined" && params && typeof params.postFunc !== "undefined" && typeof params.postFunc === "function") {
        params.postFunc();
      }
      if (typeof Asteikko.overlayOpenPostFunc === "function") { Asteikko.overlayOpenPostFunc(); }      
    });
    Asteikko.overlayOpenPostFunc = null;
    Asteikko.overlayOpenPreFunc = null;
  };


  /*****************************************************************************
    Online/offline polymorphism
  *****************************************************************************/

  // Certain methods have polymorphism depending on internet connectivity, 
  // meaning that their implementation changes (but interface stays the same).

  Asteikko.populateOnlineFuncs = function( obj ) {
    for ( var method in obj.onlineFuncs ) {
      if ( obj.onlineFuncs.hasOwnProperty( method ) ) {
        obj[ method ] = obj.onlineFuncs[ method ];
      }
    }
  };

  Asteikko.populateOfflineFuncs = function( obj ) {
    for ( var method in obj.offlineFuncs ) {
      if ( obj.offlineFuncs.hasOwnProperty( method ) ) {
        obj[ method ] = obj.offlineFuncs[ method ];
      }
    }
  };

  Asteikko.showOrHideElementsForConnectivity = function( currStatus ) {

    if ( currStatus ) {
      $('html, body').removeClass('asmag-offline');
      $('html, body').addClass('asmag-online');
      $(".online-only").show();
      $(".offline-only").hide();
    }
    else {
      $('html, body').removeClass('asmag-online');
      $('html, body').addClass('asmag-offline');
      $(".online-only").hide();
      $(".offline-only").show();
    }
  };

  Asteikko.updateOnlineStatus = function( currStatus ) {

    if ( currStatus !== Asteikko.online ) {

      Asteikko.log( 'Connectivity changed: '+ ( currStatus ? 'online' : 'offline' ) );

      Asteikko.online = currStatus;
      Asteikko.showOrHideElementsForConnectivity( currStatus );
      $( window ).trigger( 'asmag_connectivity', currStatus );
    }
  };

  Asteikko.onlineFuncs.getSettings = function( callback ) {

    // The settings object is cached for max 15 minutes in the browser
    var settingsCacheTime = 60 * 15 * 1000;

    $.ajax( {
      type: 'GET',
      url: Asteikko.env.cache_url +'/js/blog'+ Asteikko.env.blog_id +'_settings.json?_cachebust='+ parseInt( ( new Date().getTime() ) / settingsCacheTime ),
      dataType: 'text'
    } ).
      done( function( settingsStr ) {
        Asteikko.localStorage.setAsync( 'asmag_settings', settingsStr );
        callback( JSON.parse( settingsStr ) );
      } ).
      fail( function() {
        Asteikko.offlineFuncs.getSettings( callback );
      } );
  };

  Asteikko.offlineFuncs.getSettings = function( callback ) {

    var settingsStr = Asteikko.localStorage.get( 'asmag_settings' );

    if ( settingsStr )
      callback( JSON.parse( settingsStr ) );
    else
      callback( null );
  };

  Asteikko.initConnectivityChecks = function() {

    // Update Asteikko.online by checking the results of AJAX requests. 
    // We do this instead of using navigator.onLine, as navigator.onLine is 
    // not implemented consistently across desktop browsers, and only tells 
    // us if we have a connection to a router, not the Internet.
    $( document ).ajaxSuccess( function( event, jqXhr, ajaxOpts, data ) {
      if ( jqXhr.status == 200 )
        Asteikko.updateOnlineStatus( true );
    } );

    function onSuccess( data, textStatus, jqXhr ) {
      if ( jqXhr.status == 200 )
        Asteikko.updateOnlineStatus( true );
    }

    function onFail() {
      Asteikko.updateOnlineStatus( false );
    }

    function sendConnectivityTestRequest() {
      $.ajax( { type: 'HEAD', url: Asteikko.env.plugin_url +'/version.txt', cache: false, timeout: 20000 } ).
        done( onSuccess ).
        fail( onFail );
    }

    var pageChangeTestRequestTimeout;

    /**
     * If we're offline, the reader is not necessarily making any HTTP 
     * requests when changing issue page. For Asteikko.online to update
     * we must try to do an HTTP request.
     *
     * When online, we'll want to check if we've gone offline as often as
     * possible. Making test requests to the server in a tight interval all the
     * the time (e.g. with window.setInterval) is not wise, so we'll instead
     * hook into the pageIndexChanged event to make test requests, so that we're
     * only checking connectivity when we know the user is being active.
     */
    $( window ).on( 'pageIndexChanged', function() {
      window.clearTimeout( pageChangeTestRequestTimeout );
      // Don't thrash the server with requests when user changes pages quickly
      pageChangeTestRequestTimeout = window.setTimeout( sendConnectivityTestRequest, 5000 );
    } );

    /**
     * Extra connectivity check for when we're online. Polls the server in a 
     * _long_ interval to see if we've gone offline while reading an issue. This
     * is here because the user might be reading a page, and hasn't changed the
     * page in a long while (i.e. the pageIndexChanged handler above hasn't been
     * run).
     */
    window.setInterval( function() {
      if ( window.asteikkoIssue && Asteikko.online ) {
        sendConnectivityTestRequest();
      }
    }, 60000 );

    // Initial connectivity check
    sendConnectivityTestRequest();
  };


  /*****************************************************************************
    Initialization. This must be called on documentready.
  *****************************************************************************/

  Asteikko.init = function( env ) {

    Asteikko.log('Asteikko.init');

    if ( Asteikko.initialized )
      return;

    Asteikko.initialized = true;
    Asteikko.env = env;

    if ( !Asteikko.online )
      Asteikko.populateOfflineFuncs( Asteikko );
    else
      Asteikko.populateOnlineFuncs( Asteikko );

    // When connectivity changes, populate the global Asteikko object with either 
    // online or offline specific methods.
    $( window ).bind( 'asmag_connectivity', function( event, isOnline ) {
      if ( isOnline )
        Asteikko.populateOnlineFuncs( Asteikko );
      else
        Asteikko.populateOfflineFuncs( Asteikko );
    } );

    Asteikko.initConnectivityChecks();

    // Web Workers are abstracted using the 'operative' library. Before creating
    // any operative workers, we must define the operative script's self URL
    // for IE10 support.
    var jsBaseUrl = env.plugin_url +'/includes/system/js/';
    operative.setBaseURL( jsBaseUrl );
    operative.setSelfURL( jsBaseUrl +'operative.js?ver='+ env.build );

    Asteikko.localStorage.init();

    Asteikko.getSettings( function( settings ) {

      // TODO: window.settings is an ugly global, but we need it because of all
      // the legacy code (in AsMag core and AsMag customizations) that depend on
      // it. Get rid of this when you can.
      window.settings = settings;

      // Init DEPRECATED properties that some external code still uses.
      // These are postfixed with a '/' unlike the URLs in Asteikko.env.
      // TODO: get rid of these.
      /**
       * @deprecated since 5.0.0
       */
      Asteikko.siteurl = Asteikko.env.site_url.replace( /\/+$/g, '' ) +'/';
      Asteikko.pluginurl = Asteikko.env.plugin_url.replace( /\/+$/g, '' ) +'/';


      Asteikko.issueDownloader.init();
      Asteikko.reader.init();
      Asteikko.widgets.magList.init();
      Asteikko.widgets.search.init();

      Asteikko.upgrade.doUpgrades( Asteikko.env.system_version );
      Asteikko.showOrHideElementsForConnectivity( Asteikko.online );

      // Add a class to body for the operating system
      $( 'body' ).addClass( 'asmag-os-'+ deviceDetect.getOS() );

      if ( asmagModernizr.touchevents || ( deviceDetect.getOS() == 'windowsphone' && asmagModernizr.pointerevents ) )
        $( 'body' ).addClass( 'asmag-touch' );

      // Listen for URL hash changes
      AsteikkoPath.listen();

      Asteikko.log( 'Firing the asmag_initialized event' );
      $( window ).trigger( 'asmag_initialized' );
      $( window ).off( 'asmag_initialized' );
    } );
  };

})();


/********************
 * Input placeholders
 *******************/
$(document).ready(function() {

  // Check to see if the browser already supports placeholder text (introduced in HTML5). If it does,
  // then we don't need to do anything.
  var i = document.createElement('input');
  if ('placeholder' in i) {
      return;
  }

  var isPassword = function(input) {
    return $(input).attr('realType') == 'password';
  };

  var valueIsPlaceholder = function(input) {
    return input.value == $(input).attr('placeholder');
  };

  var showPlaceholder = function(input, loading) {
    // FF and IE save values when you refresh the page. If the user refreshes the page
    // with the placeholders showing they will be the default values and the input fields won't
    // be empty. Using loading && valueIsPlaceholder is a hack to get around this and highlight
    // the placeholders properly on refresh.
    if (input.value == '' || (loading && valueIsPlaceholder(input))) {
      if (isPassword(input)) {
        // Must use setAttribute rather than jQuery as jQuery throws an exception
        // when changing type to maintain compatability with IE.
        // We use our own "compatability" method by simply swallowing the error.
        try {
          input.setAttribute('type', 'input');
        } catch (e) { }
      }
      input.value = $(input).attr('placeholder');
      $(input).addClass('placeholder');
    }
  };

  var hidePlaceholder = function(input) {
    if (valueIsPlaceholder(input) && $(input).hasClass('placeholder')) {
      if (isPassword(input)) {
        try {
          input.setAttribute('type', 'password');
          // Opera loses focus when you change the type, so we have to refocus it.
          input.focus();
        } catch (e) { }
      }

      input.value = '';
      $(input).removeClass('placeholder');
    }
  };

  $(':text[placeholder],:password[placeholder]').each(function(index) {
    // We change the type of password fields to text so their placeholder shows.
    // We need to store somewhere that they are actually password fields so we can convert
    // back when the users types something in.
    if ($(this).attr('type') == 'password') {
      $(this).attr('realType', 'password');
    }

    showPlaceholder(this, true);

    $(this).focus(function() { hidePlaceholder(this); });
    $(this).blur(function() { showPlaceholder(this, false); });
  });
});
