/**
 * Listens for URL hash changes and fires callbacks for matching hashes. Uses 
 * jQuery and PathJS (https://github.com/mtrpcic/pathjs/).
 *
 * AsteikkoPath makes it easy to add a transition between two specific hash 
 * paths (e.g. application states). PathJS makes this a bit clumsy be default, 
 * as it only lets us specify a callback depending on the next hash path, not 
 * depending on the previous one as well.
 *
 * Register transitions from one path to another with 
 *   var trans = AsteikkoPath.createStateTransition('#/fromPath', '#/toPath')
 *
 * Assign handlers to the transition with
 *   trans.
 *     on( function( params ) { alert( 'Fired first handler' ); } ).
 *     on( function( params ) { console.log( params ); } );
 *
 * Parameterize parts of a path by prefixing them with ':' and splitting them 
 * with '/'.
 *   AsteikkoPath.createStateTransition('#/home', '#/page/:pageId').
 *     on( function( params ) { console.log( params ); } );
 *
 * Make parts of path optional by wrapping them in parentheses.
 *   AsteikkoPath.createStateTransition('#/home', '#/page(/:pageId)').
 *     on( function( params ) { alert( params.pageId ); } );
 *
 * When using parameterized and optionalized paths, make sure to type them
 * exactly the same in the first parameter of createStateTransition(), 
 * e.g. AsteikkoPath.createStateTransition('#/page(/:pageId)', '#/home').
 * Otherwise the transition from that path might not work.
 *
 * Transitions without a from-path will always trigger when moving to them:
 *   AsteikkoPath.createStateTransition('', '#/home')
 *
 * Returning false from a handler will cancel that transition and return to the 
 * from-path. Changing window.location.hash in a handler will prevent any 
 * following handlers from firing.
 *
 * When all transition handlers have been registered, call AsteikkoPath.listen()
 *
 * Supports search engine hash-bang style paths, e.g. '#!/home'. The '!' will be
 * stripped, so you can safely create transitions from '#/home', but set 
 * window.location.hash to '#!/home', or vice-versa.
 *
 * Non-registered paths will be ignored, so setting the window.location.hash,
 * in order, to:
 *   #/home ---> #someNonRegisteredPath ---> #/page/123
 * will trigger the transition handlers for:
 *   #/home ---> #/page/123
 */
window.AsteikkoPath = ( function( $, Path ) {

  "use strict";

  if ( typeof $ !== "function" || typeof $.prototype.jquery !== "string" )
    throw( "AsteikkoPath requires jQuery" );
  if ( typeof Path !== 'object' ) 
    throw( 'AsteikkoPath requires PathJS' );
  
  var listening = false;
  var transitionHandlers = {};
  var rescueHandler = null;
  var manuallySettingHash = false;
  var lastManuallySetHash = '';
  var lastValidHash = '';
  
  function handleHashChange() {
  
    if ( manuallySettingHash ) {
      manuallySettingHash = false;
      if ( window.location.hash === lastManuallySetHash ) {
        lastValidHash = normalizePath( lastManuallySetHash );
        // If we're manually setting the path with setPath(), we won't call the 
        // transition handlers.
        return;
      }
    }
  
    var i;
    var fromRoute = Path.match( lastValidHash );
    var fromPath = ( fromRoute ) ? normalizePath( fromRoute.path ) : '';
    var toPath = normalizePath( this.path );
    var handlers = transitionHandlers[ fromPath +'--->'+ toPath ];
    var handlerTriggeringHash = normalizePath( window.location.hash );
    
    // Handle cases where the from-hash is the same as the to-hash, e.g. when
    // going from the search-engine-friendly #!/home to #/home.
    if ( handlerTriggeringHash === lastValidHash )
      return;
    
    lastValidHash = handlerTriggeringHash;
    
    // Fire from-to handlers
    if ( handlers ) {
  
      for ( i = 0; i < handlers.length; i++ ) {
        // Cancel state transition if handler returns false
        if ( handlers[ i ].call( this, this.params ) === false )
          window.location.hash = Path.routes.previous || Path.routes.root;
        // If the handler changed the hash, or we reverted to the old one, bail
        if ( normalizePath( window.location.hash ) !== handlerTriggeringHash ) 
          return false;
      }
    }
    
    // Fire handlers that don't care about the previous state
    if ( fromPath && transitionHandlers[ '--->'+ toPath ] ) {
    
      handlers = transitionHandlers[ '--->'+ toPath ];
      for ( i = 0; i < handlers.length; i++ ) {
        // Cancel state transition if handler returns false
        if ( handlers[ i ].call( this, this.params ) === false )
          window.location.hash = Path.routes.previous || Path.routes.root;
        // If the handler changed the hash, or we reverted to the old one, bail
        if ( normalizePath( window.location.hash ) !== handlerTriggeringHash ) 
          return false;
      }
    }
  }
  
  function handleRescue() {
  
    if ( manuallySettingHash ) {
      manuallySettingHash = false;
      if ( window.location.hash === lastManuallySetHash ) {
        // If we're manually setting the path with setPath(), we won't call the 
        // rescue handler.
        return;
      }
    }
    if ( typeof rescueHandler === 'function' ) 
      rescueHandler();
  }
  
  function normalizePath( path ) {
  
    if ( !path || typeof path !== 'string' )
      return '';
    path = '#'+ path.replace( /^#?!?/, '' );
    return path;
  }
  
  var aState = function( fromPath, toPath ) {
  
    if ( !fromPath || typeof fromPath !== 'string' )
      fromPath = '';
    else 
      fromPath = normalizePath( fromPath );
    
    if ( !toPath || typeof fromPath !== 'string' )
      throw( 'Invalid path given' );
    else 
      toPath = normalizePath( toPath );
    
    if ( !Path.routes.defined[ toPath ] ) {
      Path.map( toPath ).to( handleHashChange );
      // Also support search engine hash-bang URLs (e.g. '#!/home' )
      Path.map( toPath.replace( /^#/, '#!' ) ).to( handleHashChange );
    }
    
    if ( !transitionHandlers[ fromPath +'--->'+ toPath ] )
      transitionHandlers[ fromPath +'--->'+ toPath ] = [];
    
    this.fromPath = fromPath;
    this.toPath = toPath;
  };
  
  aState.prototype = {
  
    on: function( func ) {
    
      if ( typeof func !== 'function' ) 
        return false;
      var handlers = transitionHandlers[ this.fromPath +'--->'+ 
        this.toPath ];
      if ( $.inArray( func, handlers ) === -1 )
        handlers.push( func );
      return this;
    }
  };
  
  
  var aPath = {};
  
  aPath.setRootPath = function( statePath ) {
  
    if ( listening ) 
      throw( 'Cannot call this after calling AsteikkoPath.listen()' );
    if ( !statePath || typeof statePath !== 'string' ) 
      throw 'Invalid path given';
    statePath = normalizePath( statePath );
    lastValidHash = statePath;
  };
  
  aPath.createStateTransition = function( fromPath, toPath ) {
  
    if ( listening ) 
      throw( 'Cannot call this after calling AsteikkoPath.listen()' );
    return new aState( fromPath, toPath );
  };
  
  /**
   * This function is used to set window.location.hash without triggering any 
   * of the state transitions.
   */
  aPath.setPath = function( newPath ) {
  
    if ( !newPath || typeof newPath !== 'string' ) 
      throw 'Invalid path given';
    newPath = '#'+ newPath.replace( /^#/, '' );
    manuallySettingHash = true;
    lastManuallySetHash = newPath;
    // Setting the window.location.hash here will trigger either handleHashChange()
    // or handleRescue(). We will not trigger the handler functions (registered 
    // with AsteikkoPath.rescue() or AsteikkoPath.createStateTransition()) in 
    // either one.
    window.location.hash = newPath;
  };
  
  aPath.rescue = function( fn ) {
  
    if ( typeof fn === 'function' ) 
      rescueHandler = fn;
  };
  
  aPath.listen = function() {
  
    listening = true;
    Path.rescue( handleRescue );
    Path.listen();
  };
  
  return aPath;
  
} )( window.jQuery, window.Path );