window.AsteikkoAnalyticsUA = ( function() {

  var APP_TYPE = 'webapp';

  var blogId = 0;
  var trackingId = null;
  var clientId = null;
  var initialized = false;
  var userId = null;
  var userType = null;
  var dimAppType = 1;
  var dimUserType = 2;
  var dimIssue = 3;
  var dimArticle = 4;
  var dimIssuePrice = 5;
  var dimIssueTags = 6;
  var eventCache = [];

  function purgeEventCache() {
    if ( !initialized || !eventCache.length || !Asteikko.online ) return;

    var sentEvent = eventCache.shift();

    // Assign custom dim values to correct indices
    sentEvent.data[ 'dimension'+ dimAppType ] = APP_TYPE;
    if ( userType ) sentEvent.data[ 'dimension'+ dimUserType ] = userType;
    if ( sentEvent.issue ) sentEvent.data[ 'dimension'+ dimIssue ] = sentEvent.issue;
    if ( sentEvent.article ) sentEvent.data[ 'dimension'+ dimArticle ] = sentEvent.article;
    if ( sentEvent.issuePrice ) sentEvent.data[ 'dimension'+ dimIssuePrice ] = sentEvent.issuePrice;
    if ( sentEvent.issueTags ) sentEvent.data[ 'dimension'+ dimIssueTags ] = sentEvent.issueTags;

    // Set the Queue Time parameter to account for the offset between recorded and sent time
    sentEvent.data.queueTime = ( new Date() ).getTime() - sentEvent.origTime;
    asteikkoGA( 'asteikkomag.send', sentEvent.data );
    Asteikko.log( 'Sent cached event of type "'+ sentEvent.data.hitType +'"' );
    Asteikko.log( 'Events left: '+ eventCache.length );

    // Make sure we don't exceed the analytics.js hit limit:
    // "Each web property starts with 20 hits that are replenished at a rate 
    // of 2 hit per second"
    if ( eventCache.length ) window.setTimeout( purgeEventCache, 1000 );
  }

  function onConnectivityChange( isOnline ) {
    if ( isOnline ) {
      purgeEventCache();
    }
  }

  function onInitGA() {
    var tracker = asteikkoGA.getByName( 'asteikkomag' );
    clientId = tracker.get( 'clientId' );
    purgeEventCache();
  }

  var al = {};

  al.initGa = function( wpBlogId, gaConfigFromServer ) {
    if ( initialized || window.asteikkoGA ) {
      return;
    }

    blogId = wpBlogId;

    /* jshint ignore:start */
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','asteikkoGA');
    /* jshint ignore:end */

    trackingId = gaConfigFromServer.trackingId;
    // WP user ID
    userId = gaConfigFromServer.userId;
    // User type: subscriber/nonsubscriber/notloggedin
    userType = gaConfigFromServer.userType;
    // Custom dimensions
    dimAppType = gaConfigFromServer.dimAppType;
    dimUserType = gaConfigFromServer.dimUserType;
    dimIssue = gaConfigFromServer.dimIssue;
    dimArticle = gaConfigFromServer.dimArticle;
    dimIssuePrice = gaConfigFromServer.dimIssuePrice;
    dimIssueTags = gaConfigFromServer.dimIssueTags;

    initialized = true;

    if ( trackingId ) {
      asteikkoGA( 'create', trackingId, {
        name: 'asteikkomag',
        cookieDomain: gaConfigFromServer.cookieDomain || 'auto',
        // GDPR support. Don't store cookies, and anonymize IPs.
        // Note that `storage: 'none'` will not store a unique ID for
        // the browser, and therefore if the user reloads the browser page
        // he will end up being counted as a _new_ user in Google Analytics.
        // You can see this if you go to the realtime view in GA and reload
        // the Asteikko Mag issue reading view browser page multiple times.
        // The result is that the GA realtime view will show multiple users
        // (one user per browser page reload).
        storage: 'none',
        anonymizeIp: true
      } );
      asteikkoGA( onInitGA );
    }
    // No GA tracking ID, so we're never gonna send analytics to GA
    else {
      eventCache.length = 0;
    }

    $( window ).bind( 'asmag_connectivity', onConnectivityChange );
  }

  /**
   * issueTags should be string of comma-separated tags.
   */
  al.sendEvent = function( category, action, label, value, issue, article, issuePrice, issueTags ) {
    var data = {
      'hitType': 'event',
      'eventCategory': category,
      'eventAction': action,
      'eventLabel': label,
      'eventValue': value
    };

    if ( window.asteikkoGA && initialized && Asteikko.online ) {
      // Set up custom dimensions
      data[ 'dimension'+ dimAppType ] = APP_TYPE;
      if ( userType ) data[ 'dimension'+ dimUserType ] = userType;
      if ( issue ) data[ 'dimension'+ dimIssue ] = issue;
      if ( article ) data[ 'dimension'+ dimArticle ] = article;
      if ( issuePrice ) data[ 'dimension'+ dimIssuePrice ] = issuePrice;
      if ( issueTags ) data[ 'dimension'+ dimIssueTags ] = issueTags;

      asteikkoGA( 'asteikkomag.send', data );
    }
    else {
      Asteikko.log( 'Caching sendEvent' );
      eventCache.push( {
        origTime: ( new Date() ).getTime(),
        data: data,
        issue: issue,
        article: article,
        issuePrice: issuePrice,
        issueTags: issueTags
      } );
    }
  };

  /**
   * issueTags should be string of comma-separated tags.
   */
  al.sendPageView = function( page, title, issue, article, issuePrice, issueTags ) {
    var data = {
      'hitType': 'pageview'
    };

    if ( page ) data.page = page;
    if ( title ) data.title = title;

    // Convert absolute URLs to relative URLs
    if ( ( /^https?:\/\//i ).test( page ) ) {
      page = page.replace( /^https?:\/\/[^\/]+/i, '' );
    }

    if ( window.asteikkoGA && initialized && Asteikko.online ) {
      // Set up custom dimensions
      data[ 'dimension'+ dimAppType ] = APP_TYPE;
      if ( userType ) data[ 'dimension'+ dimUserType ] = userType;
      if ( issue ) data[ 'dimension'+ dimIssue ] = issue;
      if ( article ) data[ 'dimension'+ dimArticle ] = article;
      if ( issuePrice ) data[ 'dimension'+ dimIssuePrice ] = issuePrice;
      if ( issueTags ) data[ 'dimension'+ dimIssueTags ] = issueTags;

      asteikkoGA( 'asteikkomag.send', data );
    }
    else {
      Asteikko.log( 'Caching sendPageView' );
      eventCache.push( {
        origTime: ( new Date() ).getTime(),
        data: data,
        issue: issue,
        article: article,
        issuePrice: issuePrice,
        issueTags: issueTags
      } );
    }
  };

  return al;

} )();
