
( function( $ ) {

	/*todo:
		Fixes:
		- render handlebars template from settings
		- setting files and languages
		- refactoring - spaces
	*/

	function adminMenuFunc( e ) {

		var _clickIndex = $( this ).index();
		$( '.menu-item' ).eq( _clickIndex ).addClass( 'active-menu' );
		$( '.menu-item' ).eq( !_clickIndex ).removeClass( 'active-menu' );

		var _domain = window.Asteikko[ window.settings.editor_notes.admintoolbar.domain ];
		var _menus = window.settings.editor_notes.admintoolbar.menu;

		for( var _menu in _menus ) {

			if ( _menus[_menu]['id'] === $( '.menu-item' ).eq( _clickIndex ).attr( 'id' ) ) {
				var _fn = _menus[_menu]['handler'];

				if ( typeof _fn === 'string' ) {
					_fn = _fn.split( '.' );

					_domain[_fn[0]][_fn[1]].call( this, '#mag' );
				}
			}
		}

		e.stopPropagation();
	}

	$( window ).on( 'pageIndexChanged', function() {
		if ( !Asteikko.reader._seeAdminBar || !settings.editor_notes.enabled ) {
			return;	
		}

		Asteikko.log( 'Page index is changed - Terminate events on admin bar' );

		$( '#asmag-admin-bar' ).find( '.menu-item' ).off( 'click.adminBar' );
	});

	$( window ).on( 'pageShown', function() {
		if ( !Asteikko.reader._seeAdminBar || !settings.editor_notes.enabled ) {
			return;	
		}

		Asteikko.log('Init admin bar and events');

		$( '#asmag-admin-bar' ).find( '.menu-item' ).each( function() {
			$( this ).on( 'click.adminBar', adminMenuFunc );
		});	
	});

}( jQuery ) );