/*******************************************************************************
  Asteikko's user interface templates, rendering, etc.
*******************************************************************************/

if ( !window.Asteikko ) window.Asteikko = {};

( function() {

  'use strict';

  Asteikko.ui = {

    // This is filled at build-time when Handlebars templates are compiled
    _templates: {},

    /**
     * @param {string} template - Template name
     * @param {object} context - Context variables for the template
     */
    renderTemplate: function( template, context ) {

      var postfix = Asteikko.env.client_postfix;

      // Try a template like 'mytemplate_ios.hbs'
      if ( this._templates[ template + postfix ] )
        return this._templates[ template + postfix ]( context );

      // Try a template like 'mytemplate.hbs'
      if ( this._templates[ template ] )
        return this._templates[ template ]( context );

      return null;
    }
  };

} )();
