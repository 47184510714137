
window.asteikkoImg = ( function( $ ) {

"use strict";

if ( typeof $ !== "function" || typeof $.prototype.jquery !== "string" ) {
  throw( "asteikkoImg: jQuery is not loaded" );
}
if ( window.asteikkoImgRatio ) {
  throw( "asteikkoImg has already been loaded once" );
}

var asImg = {};


/**
 * Will check if the Image has loaded. If it has, the given callback will be run. 
 * Notice: we're polling for the 'complete' property to see if the image has 
 * loaded - this is usually more reliable than using the 'onload' event, which 
 * might never fire for cached images on some browsers.
 */
asImg.checkImageIsLoaded = function ( imgObj, callback, timeout ) {

  var interval;
  var hasTimedOut;
  var jImg = $( imgObj );
  var startTime = ( new Date() ).getTime();

  if ( !jImg.is( "img" ) || typeof callback !== "function" ) {
    return false;
  }

  if ( !timeout ) {
    // We expect file:// URLs to load in 2 secs or less
    timeout = ( ( /^file:\/\// ).test( imgObj.src ) )
      ? 2
      : 60;
  }

  interval = window.setInterval( function() {
    hasTimedOut = ( new Date() ).getTime() - startTime > timeout * 1000;
    if ( !imgObj.src || ( imgObj.complete && imgObj.naturalWidth ) || hasTimedOut ) {
      window.clearInterval( interval );
      callback.call( null, imgObj );
    }
  }, 200 );

  return true;
};


/**
 * Will check if all IMG elements inside the given parent element have been 
 * loaded. Calls the callback with the first argument being the parent element, 
 * and the second argument being all the matched IMG elements.
 */
asImg.checkImagesAreLoadedInside = function( parentElem, callback, timeout ) {

  var i;
  var parent = $( parentElem ).eq( 0 );
  var imgs = parent.find( "img" );
  var imgTotal = imgs.length;
  var imgLoaded = 0;
  var eachCallback = function() {
    imgLoaded++;
    if ( imgLoaded === imgTotal ) {
      callback.call( null, parent.get( 0 ), imgs.get() );
    }
  };

  if ( typeof callback !== "function" ) return false;
  if ( !imgTotal ) callback.call( null, parent.get( 0 ), [] );

  for ( i = 0; i < imgTotal; i++ ) {
    asImg.checkImageIsLoaded( imgs[ i ], eachCallback );
  }

  return true;
};


/**
 * Asteikko image aspect ratio checker. Allows one to check for the aspect 
 * ratios of any images matched by a jQuery string, and once an image has 
 * loaded, a callback can be run on the image with its aspect ratio as an 
 * argument. Eases layout development, enabling more advanced design based on 
 * individual images' aspect ratios.
 *
 * The given callback will be run for each image once it has loaded, with the 
 * IMG element as the 'this' keyword, and a float specifying the image's 
 * aspect ratio as the first argument ( > 1.0 equals horizontal).
 * 
 * Example:
 *
 * asteikkoImg.checkRatio( "body *", function( imageRatio ) {
 *   if ( imageRatio >= 1 ) $( this ).css( "border", "3px solid blue" );
 *   else $( this ).css( "border", "3px solid red" );
 * } );
 */
asImg.checkRatio = ( function() {

  var imgRatio = function( jQSelector, callback, options ) {

    var i;
    var timeout = ( typeof options === "object" && options.timeout ) ? options.timeout : 30;
    var jQResult = $( jQSelector ).filter( "img" );
    var eachCallback = function( imgObj ) {
      if ( imgObj && typeof callback === "function" ) {
        callback.call( imgObj, imgObj.width / imgObj.height );
      }
    };

    if ( !jQResult.length ) return false;
    if ( typeof options !== "object" ) options = {};

    for ( i = 0; i < jQResult.length; i++ ) {
      asImg.checkImageIsLoaded( jQResult[ i ], eachCallback, timeout );
    }
    return true;
  };

  return imgRatio;

} )();


/**
 * Asteikko Lazyloader
 * -------------------
 * Makes images (img elements and background images) start loading after the DOM has 
 * fully loaded, and only shows them once they have fully loaded. This makes the images 
 * appear more gracefully than when loading them normally. Any temporary image can be 
 * shown on the element before lazyloading the full-size image: the only requirement is 
 * that the element has a data-attribute named 'data-fullsrc'.
 *
 * Call asteikkoLazyloader with any jQuery selector string. If any of the matched elements 
 * has a data-fullsrc attribute, the URL in that attribute will determine what image will 
 * be shown on the element, once the image has fully loaded. If it's an IMG element, the 
 * src attribute will be set. If it's any other element, the background-image CSS rule 
 * will be set.
 *
 * Usage: asteikkoImg.lazyLoad( jQSelector, optionsObj );
 *
 * The options object provides the following options:
 *   imgCallback:   Run for each loaded image in the queue. Has one argument: 
 *                  the URL of the loaded image, or false if the loading timed 
 *                  out. The 'this' variable is bound to the element that the 
 *                  image was loaded for.
 *   finalCallback: Run after all the images in the queue have been loaded. Has 
 *                  one argument: the array of elements that were matched by 
 *                  the jQuery selector jQSelector.
 *   imgLoadTimeout: How long to wait until moving on to the next image load in 
 *                   the queue. If an image load is timed out, imgCallback will 
 *                   still be called.
 */
asImg.lazyLoad = ( function() {

/**
 * Will load an image from a URL to an Image object. This should make the browser cache 
 * the result, so we can show the image immediately on any HTML element once it has 
 * fully loaded. Notice: we're polling for the 'complete' property to see if the image 
 * has loaded - this is usually more reliable than using the 'onload' event, which might 
 * never fire for cached images on some browsers.
 */
var loadImage = function( url, callback, timeout ) {

  var img;
  var interval;
  var hasTimedOut;
  var result;

  var startTime = ( new Date() ).getTime();

  img = new Image();
  img.src = url;
  interval = window.setInterval( function() {
    hasTimedOut = ( new Date() ).getTime() - startTime > timeout * 1000;
    if ( ( img.complete && img.naturalWidth ) || hasTimedOut ) {
      result = ( hasTimedOut ) ? false : url;
      window.clearInterval( interval );
      if ( typeof callback === "function" ) {
        callback.call( null, result );
      }
    }
  }, 200 );
};

var loadFullSrcForElem = function( elem, callback, timeout ) {

  var fullSrcAttr;
  var loadImageCallback;

  if ( typeof elem !== "object" || !elem.tagName ) {
    if ( typeof callback === "function" ) callback.call( elem, false );
    return false;
  }
  fullSrcAttr = elem.getAttribute( "data-fullsrc" );
  if ( !fullSrcAttr ) {
    if ( typeof callback === "function" ) callback.call( elem, false );
    return false;
  }

  // Img elements
  if ( elem.tagName.toLowerCase() === "img" ) {
    loadImageCallback = function( url ) {
      if ( url ) elem.src = fullSrcAttr;
      if ( typeof callback === "function" ) callback.call( elem, url );
    };
  }
  // Any other element
  else {
    loadImageCallback = function( url ) {
      if ( url ) elem.style.backgroundImage = 'url('+ fullSrcAttr +')';
      if ( typeof callback === "function" ) callback.call( elem, url );
    };
  }

  loadImage( fullSrcAttr, loadImageCallback, timeout );
  return true;
};

var processQueueNext = function( queue ) {

  var elem = queue.elems[ queue.elemsIndex++ ];
  if ( elem ) {
    loadFullSrcForElem(
      elem,
      function( url ) {
        if ( typeof queue.imgCallback === "function" ) {
          queue.imgCallback.call( this, url );
        }
        processQueueNext( queue );
      },
      queue.imgLoadTimeout
    );
  }
  else {
    if ( typeof queue.finalCallback === "function" ) {
      queue.finalCallback.call( queue, queue.elems );
    }
  }
};


/**
 * Lazyloads any images for elements, that are matched by the given jQuery selector, AND 
 * that have a data-fullsrc property.
 *
 * See asImg.lazyLoad comments for information about possible options.
 */
var lazyloader = function( jQSelector, options ) {

  var i;
  var length;
  var jQResult;
  var elem;
  var queue;

  if ( typeof jQSelector !== "string" ) return false;
  if ( typeof options !== "object" ) options = {};

  queue = {
    elems: [],
    elemsIndex: 0,
    imgCallback: options.imgCallback,
    finalCallback: options.finalCallback,
    imgLoadTimeout: options.imgLoadTimeout || 30
  };

  jQResult = $( jQSelector );
  length = jQResult.length;
  for ( i = 0; i < jQResult.length; i++ ) {
    queue.elems.push( jQResult[ i ] );
  }

  processQueueNext( queue );
};

return lazyloader;

} )();


return asImg;

} )( window.jQuery );