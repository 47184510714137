
if ( !window.Asteikko ) window.Asteikko = {};

( function() {

  'use strict';


  function prefixStyle(style) {
    if ( vendor === '' ) return style;
    style = style.charAt(0).toUpperCase() + style.substr(1);
    return vendor + style;
  }

  var dummyStyle = document.createElement('div').style,
    vendor = (function () {
      var vendors = 't,webkitT,MozT,msT,OT'.split(','),
        t,
        i = 0,
        l = vendors.length;

      for ( ; i < l; i++ ) {
        t = vendors[i] + 'ransform';
        if ( t in dummyStyle ) {
          return vendors[i].substr(0, vendors[i].length - 1);
        }
      }

      return false;
    })(),
    cssVendor = vendor ? '-' + vendor.toLowerCase() + '-' : '',
    transform = prefixStyle('transform'),
    transitionDuration = prefixStyle('transitionDuration'),
    has3d = prefixStyle('perspective') in dummyStyle,
    translateZ = has3d ? ' translateZ(0)' : '';
  

  Asteikko.pageSwitcher = {

    xhrs: [],
    pageIsBeingRemoved: false,
    isPanning: false,
    isPanningHorizontal: false,
    hammer: null,

    getActiveIndicator: function( e ) {

      if ( e.offsetDirection === Hammer.DIRECTION_RIGHT )
        return this.indicatorRight;
      else if ( e.offsetDirection === Hammer.DIRECTION_LEFT )
        return this.indicatorLeft;
      return null;
    },

    onPanStart: function( e ) {

      Asteikko.pageSwitcher.isPanning = true;
      Asteikko.pageSwitcher.isPanningHorizontal = false;
    },

    onPanMove: function( e ) {

      if ( !Asteikko.pageSwitcher.isPanning )
        return;

      if ( !Asteikko.pageSwitcher.isPanningHorizontal ) {

        var absDeltaX = Math.abs( e.deltaX );
        var absDeltaY = Math.abs( e.deltaY );

        // Take a few pixels to see in which direction the user is panning to
        if ( absDeltaX < 10 && absDeltaY < 10 )
          return;

        // We are scrolling vertically, so skip pageSwitcher and give the control back to the browser
        if ( absDeltaY > absDeltaX ) {
          Asteikko.pageSwitcher.isPanning = false;
          return;
        }

        Asteikko.pageSwitcher.isPanningHorizontal = true;
      }

      e.preventDefault();

      var indicator = Asteikko.pageSwitcher.getActiveIndicator( e );

      if ( !indicator ) {
        // User panned back to starting position
        Asteikko.pageSwitcher.indicatorLeft.add( 
          Asteikko.pageSwitcher.indicatorRight ).hide();
        return;
      }

      var swipePct = Math.abs( e.deltaX ) / Asteikko.pageSwitcher.snapThreshold;
      if ( swipePct > 1 )
        swipePct = 1;

      if ( e.offsetDirection === Hammer.DIRECTION_RIGHT ) {

        Asteikko.pageSwitcher.indicatorLeft.hide();

        indicator[ 0 ].style[ transform ] = 'translate(-'+ Math.floor( swipePct * 67 ) +'px,0)'+ translateZ;
        indicator.show();
      }
      else if ( e.offsetDirection === Hammer.DIRECTION_LEFT ) {

        Asteikko.pageSwitcher.indicatorRight.hide();

        indicator[ 0 ].style[ transform ] = 'translate('+ Math.floor( swipePct * 67 ) +'px,0)'+ translateZ;
        indicator.show();
      }
      else {
        Asteikko.pageSwitcher.indicatorLeft.hide();
        Asteikko.pageSwitcher.indicatorRight.hide();
      }
    },

    onPanEnd: function( e ) {

      if ( !Asteikko.pageSwitcher.isPanning || !Asteikko.pageSwitcher.isPanningHorizontal )
        return;

      e.preventDefault();

      Asteikko.pageSwitcher.isPanning = false;
      Asteikko.pageSwitcher.isPanningHorizontal = false;

      var leftInd = Asteikko.pageSwitcher.indicatorLeft;
      var rightInd = Asteikko.pageSwitcher.indicatorRight;

      if ( e.offsetDirection === Hammer.DIRECTION_RIGHT ) {
        var activeInd = rightInd;
        var inactiveInd = leftInd;
      }
      else if ( e.offsetDirection === Hammer.DIRECTION_LEFT ) {
        var activeInd = leftInd;
        var inactiveInd = rightInd;
      }
      else {
        // User panned back to 0
        leftInd.add( rightInd ).hide();
        leftInd[ 0 ].style[ transitionDuration ] = '0s';
        leftInd[ 0 ].style[ transform ] = 'translate(0,0)'+ translateZ;
        rightInd[ 0 ].style[ transitionDuration ] = '0s';
        rightInd[ 0 ].style[ transform ] = 'translate(0,0)'+ translateZ;
        return;
      }

      if ( Math.abs( e.deltaX ) >= Asteikko.pageSwitcher.snapThreshold ) {

        leftInd.add( rightInd ).hide();
        leftInd[ 0 ].style[ transitionDuration ] = '0s';
        leftInd[ 0 ].style[ transform ] = 'translate(0,0)'+ translateZ;
        rightInd[ 0 ].style[ transitionDuration ] = '0s';
        rightInd[ 0 ].style[ transform ] = 'translate(0,0)'+ translateZ;

        if ( e.offsetDirection === Hammer.DIRECTION_RIGHT )
          Asteikko.pageSwitcher.switchNext();
        else
          Asteikko.pageSwitcher.switchPrevious();
      }
      else {

        inactiveInd.hide();
        inactiveInd[ 0 ].style[ transitionDuration ] = '0s';
        inactiveInd[ 0 ].style[ transform ] = 'translate(0,0)'+ translateZ;

        activeInd[ 0 ].style[ transitionDuration ] = '500ms';
        activeInd[ 0 ].style[ transform ] = 'translate(0,0)'+ translateZ;

        window.setTimeout( function() {
          activeInd.hide();
          activeInd[ 0 ].style[ transitionDuration ] = '0s';
        }, 520 );
      }
    },

    createUiElements: function() {

      this.indicatorLeft = $( '<div class="pageswitcher-indicator-left" style="display: none;"></div>' );
      this.indicatorRight = $( '<div class="pageswitcher-indicator-right" style="display: none;"></div>' );

      this.container.append( this.indicatorLeft ).append( this.indicatorRight );
    },

    switchNext: function() {

      if (currentIndex < pages.length - 1)
        Asteikko.pageSwitcher.switchToIndex(currentIndex + 1);
    },

    switchPrevious: function() {

      if (currentIndex > 0)
        Asteikko.pageSwitcher.switchToIndex(currentIndex - 1);
    },
    
    switchToIndex: function( newIndex ) {

      var oldIndex = currentIndex;
      Asteikko.log("switching from " + oldIndex + " to " + newIndex);

      if (newIndex == oldIndex)
        return;
      
      // Change the index

      currentIndex = newIndex; // update the global

      if ( Asteikko.pageSwitcher.indexChangedFunc )
        Asteikko.pageSwitcher.indexChangedFunc( oldIndex, newIndex );

      if ( Asteikko.pageSwitcher.xhrs.length ) {
        for ( var i = 0; i < Asteikko.pageSwitcher.xhrs.length; i++ )
          Asteikko.pageSwitcher.xhrs[ i ].abort();
        Asteikko.pageSwitcher.xhrs.length = 0;
      }

      // Get the newly indexed page's HTML

      Asteikko.pageSwitcher.pageHtml = ( typeof Asteikko.pageSwitcher.getCachedHtmlFunc === 'function' )
        ? Asteikko.pageSwitcher.getCachedHtmlFunc( newIndex )
        : null;

      if ( !Asteikko.pageSwitcher.pageHtml ) {

        Asteikko.pageSwitcher.container.addClass( 'asmag-pageswitcher-loading' );

        Asteikko.pageSwitcher.xhrs.push( $.ajax( {
          url: pages[ newIndex ].url,
          dataType: 'html',
          success: function( response, status, jqXHR ) {

            if ( newIndex != currentIndex )
              return;

            if ( !Asteikko.pageSwitcher.pageIsBeingRemoved )
              Asteikko.pageSwitcher.showPageForHtml( newIndex, response );
            else // We'll add the HTML to DOM later, when the old page has been removed
              Asteikko.pageSwitcher.pageHtml = response;
          },
          error: function( jqXHR, status ) {

            if ( !window.pages || !pages.length || status === 'abort' || status === 'timeout' )
              return;
            
            if ( Asteikko.pageSwitcher.pageLoadErrorFunc )
              Asteikko.pageSwitcher.pageLoadErrorFunc( status, jqXHR.status );
          }
        } ) );
      }

      if ( !Asteikko.pageSwitcher.pageIsBeingRemoved ) {

        // First page view (index was -1)
        if ( oldIndex < 0 ) {

          if ( Asteikko.pageSwitcher.pageHtml )
            Asteikko.pageSwitcher.showPageForHtml( currentIndex, Asteikko.pageSwitcher.pageHtml );
        }
        else {

          Asteikko.pageSwitcher.pageIsBeingRemoved = true;
        
          // Fade out and remove the old page. We make sure to add the new page's
          // HTML to the DOM only after the old page has been faded out and removed.
          // There are two possibilities:
          // 1. We get the new HTML before the old page has been removed; we must
          //    wait until the old page has been removed and only then add to DOM.
          // 2. We get the new HTML after the old page has been removed; we can add
          //    the new HTML to the DOM immediately.
          var oldPageElem = Asteikko.pageSwitcher.container.children( '.'+ Asteikko.pageSwitcher.pageClass );
          oldPageElem.fadeOut( 220 );

          window.setTimeout( function() {

            Asteikko.pageSwitcher.pagePreRemoveFunc( oldPageElem.attr( 'data-index' ) );
            oldPageElem.remove();
            Asteikko.pageSwitcher.pageIsBeingRemoved = false;

            // The server or local cache returned the HTML earlier, so add it 
            // to the DOM immediately. Otherwise we're still retrieving it from 
            // the server.
            if ( Asteikko.pageSwitcher.pageHtml )
              Asteikko.pageSwitcher.showPageForHtml( currentIndex, Asteikko.pageSwitcher.pageHtml );
          }, 240 );
        }
      }
    },
    
    showPageForHtml: function( index, html ) {

      Asteikko.pageSwitcher.container.removeClass( 'asmag-pageswitcher-loading' );

      var pageElem = $( '<div id="asmag-pageswitcher-page-'+ index +'" class="'+ 
        this.pageClass + '" data-index="'+ index +'" style="display: none;"></div>' );

      pageElem.html( html ).appendTo( this.container );

      if ( pages[ index ].zoomable )
        pageElem.zoomable();
      else
        pageElem.zoomable( 'destroy' );

      pageElem.fadeIn( 300 );

      window.setTimeout( function() {

        if ( Asteikko.pageSwitcher.pageShownFunc )
          Asteikko.pageSwitcher.pageShownFunc( index );

        // Run a global Displayed123() function, where 123 is the issue page's
        // ID. This function is usually defined in an issue page's article
        // template.
        var displayedCallback = Asteikko.pageSwitcher.pageDisplayedFuncPrefix + pages[ index ].id;
        if ( typeof window[ displayedCallback ] === 'function' )
          window[ displayedCallback ].call();

        if ( Asteikko.pageSwitcher.afterPageDisplayedFunc )
          Asteikko.pageSwitcher.afterPageDisplayedFunc( index );

        if (  Asteikko.pageSwitcher.pageFinishLoadFunc )
           Asteikko.pageSwitcher.pageFinishLoadFunc( index );

      }, 320 );
    },

    destroy: function() {

      if ( this.hammer )
        this.hammer.destroy();
      this.hammer = null;
    },
    
    create: function( settings ) {
      
      if ( typeof settings !== 'object' || !settings )
        throw 'First argument must be a settings object';
        
      var required = [ 'containerId', 'pageClass' ];
      for ( var i = 0; i < required.length; i++ ) {
        if ( !settings[ required[ i ] ] )
          throw 'Setting \''+ required[ i ] +'\' is required';
      }
      
      this.containerId = settings.containerId.toString();
      this.pageClass = settings.pageClass.toString();
      this.pageDisplayedFuncPrefix = settings.pageDisplayedFuncPrefix || 'Displayed';
      this.indexChangedFunc = settings.indexChangedFunc || null;
      this.pageFinishLoadFunc = settings.pageFinishLoadFunc || null;
      this.pagePreRemoveFunc = settings.pagePreRemoveFunc || null;
      this.pageShownFunc = settings.pageShownFunc || null;
      this.afterPageDisplayedFunc = settings.afterPageDisplayedFunc || null;
      this.getCachedHtmlFunc = settings.getCachedHtmlFunc || null;
      this.pageLoadErrorFunc = settings.pageLoadErrorFunc || null;

      this.snapThreshold = parseInt( settings.snapThreshold, 10 ) || 70;
      this.container = $( '#'+ settings.containerId );

      // A weird effect was seen on Android's nameless browser: touch events
      // weren't fired reliably when touching text on a page, but only when
      // a container element of the text didn't have corresponding move event
      // handlers bound. In addition to this hack, one might have to add move 
      // event handers to any element that has transform3d or translateZ
      // CSS styling.
      if ( deviceDetect.getBrowser() === 'androidnameless' ) {
        this.container.
          off( 'mousedown.pageswitcher' ).
          off( 'mousemove.pageswitcher' ).
          off( 'mouseup.pageswitcher' ).
          on( 'mousedown.pageswitcher', function() {} ).
          on( 'mousemove.pageswitcher', function() {} ).
          on( 'mouseup.pageswitcher', function() {} );
      }

      this.createUiElements();
      
      if ( !settings.disablePageFlip ) {

        this.hammer = new Hammer.Manager( this.container[ 0 ] );
        this.hammer.add( new Hammer.Pan( {
          direction: Hammer.DIRECTION_HORIZONTAL,
          threshold: 10,
          pointers: 1
        } ) );

        this.hammer.on( 'panstart', this.onPanStart );
        this.hammer.on( 'panmove', this.onPanMove );
        this.hammer.on( 'panend', this.onPanEnd );
      }

      window.currentIndex = -1;
      this.switchToIndex( settings.initialIndex || 0 );
    }
  };
  
} )();
