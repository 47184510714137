/*****************************************************************************
  Highlight functions
*****************************************************************************/

if ( !window.Asteikko ) window.Asteikko = {};

( function() {
  
  function saveServerHighlightsLocally(highlights) {
  
    var localHighlights = JSON.parse(Asteikko.localStorage.get('highlights_cache'));
    if (!localHighlights) localHighlights = [];
    
    // First remove local highlights that haven't been created locally, or haven't 
    // been modified locally for a while.
    for (var i = localHighlights.length - 1; i >= 0; i--) {
      if ( !localHighlights[i]._createdLocally && 
       ( !localHighlights[i]._modifiedLocally || localHighlightExpired(localHighlights[i]) ) )
      {
        localHighlights.splice( i, 1 );
      }
    }
    
    // Populate local highlights with highlights from server
    for (var i = 0; i < highlights.length; i++) {
      var localHighlightSeen = false;
      for (var j = 0; j < localHighlights.length; j++) {
        if (highlights[i].id == localHighlights[j].id && !localHighlights[j]._createdLocally) {
          localHighlightSeen = true;
          if (!localHighlights[j]._modifiedLocally) {
            localHighlights[j] = highlights[i];
          }
          break;
        }
      }
      if (!localHighlightSeen) {
        localHighlights.push(highlights[i]);
      }
    }
    return Asteikko.localStorage.set('highlights_cache', JSON.stringify(localHighlights));
  }
  
  function localHighlightExpired( localHighlight ) {

    var highlightExpireDate = new Date( localHighlight._lastModified );
    highlightExpireDate.setUTCDate( highlightExpireDate.getUTCDate() + 7 ); // 1 week
    var highlightExpire = highlightExpireDate.getTime();
    var now = ( new Date() ).getTime();
    return now >= highlightExpire;
  }

  function getHighlightLocal( id, issue, page ) {
    
    var highlights = JSON.parse(Asteikko.localStorage.get('highlights_cache'));
    if ( highlights && highlights.length ) {
      for ( var i = 0; i < highlights.length; i++ ) {
        if ( highlights[ i ].id == id 
          || ( highlights[ i ].issue_id == issue && highlights[ i ].page_id == page ) ) 
        {
          return highlights[ i ];
        }
      }
    }
    return null;
  }
  
  function addHighlightLocal( id, issue, page, highlight ) {
    
    var highlights = JSON.parse(Asteikko.localStorage.get('highlights_cache'));
    var highlightFound = false;
    var highlightId = 1;
    
    if ( highlights && highlights.length ) {
      for ( var i = 0; i < highlights.length; i++ ) {
        if ( highlights[ i ].id == id 
          || ( highlights[ i ].issue_id == issue && highlights[ i ].page_id == page ) ) 
        {
          highlights[i].highlight = highlight;
          highlights[i]._modifiedLocally = true;
          highlights[i]._lastModified = ( new Date() ).getTime();
          highlightId = highlights[i].id;
          highlightFound = true;
        }
      }
    }
    else {
      highlights = [];
    }
    
    if ( !highlightFound ) {
      // addHighlight() returns the highlight's database ID when the highlight is created. We need 
      // to create an ID when offline, so we'll find an ID that isn't used by the 
      // highlights in the local cache, but this ID might conflict with a highlight's ID on the 
      // server. Therefore we'll add an additional property to any highlights created locally
      // (_createdLocally), so we know that their ID should NOT be used to update a 
      // server-highlight with the data of a local-highlight.
      for (var i = 0; i < highlights.length; i++) {
        if (highlights[i].id >= highlightId) highlightId = parseInt( highlights[i].id, 10 ) + 1;
      }
      highlights.push( {
        _createdLocally: true,
        id: String(highlightId),
        highlight: highlight,
        issue_id: String(issue),
        page_id: String(page),
        user_id: null
      } );
    }
    
    Asteikko.localStorage.set('highlights_cache', JSON.stringify(highlights));
    return highlightId;
  }
  
  Asteikko.onlineFuncs.addHighlight = function(issue, page, highlight, callback) {
    if (typeof issue === "undefined" || !issue || typeof page === "undefined" ||
        !page || typeof highlight === "undefined") {
      return;
    }
    
    // If user is not logged in, use offline functionality (localStorage)
    if (Asteikko.lastLoginStatus === false) {
      return Asteikko.offlineFuncs.addHighlight(issue, page, highlight, callback);
    }

    $.ajax({
      url: Asteikko.env.site_url +'/asmag/highlights/?action=add',
      type: 'POST',
      dataType: 'json',
      data: {issue: issue, page: page, highlight: highlight}
    }).done(function(stat) {
      if (typeof callback === "function" && callback) {
        if (stat !== false) {
          callback(stat);
        } else {
          callback(false);
        }
      }
    }).fail(function() {
      if (typeof callback === "function" && callback) {
        callback(false);
      }
    });
  };
  
  Asteikko.offlineFuncs.addHighlight = function(issue, page, highlight, callback) {
  
    if ( !window.settings.highlights.enabled ) throw 'Highlights are not enabled';
    
    if (typeof issue === "undefined" || !issue || typeof page === "undefined" ||
        !page || typeof highlight === "undefined") {
      return;
    }
    
    var highlightId = addHighlightLocal( null, issue, page, highlight );
    if (typeof callback === "function") callback(highlightId);
  };

  Asteikko._getHighlightsReal = function(params, callback) {
    if (typeof params === "undefined" || !params || typeof callback === "undefined" || !callback) {
      return;
    }

    $.ajax({
      url: Asteikko.env.site_url +'/asmag/highlights/?action=get',
      type: 'POST',
      dataType: 'json',
      data: params
    }).done(function(data) {
      if (typeof callback === "function" && callback) {
        if (data) {
          saveServerHighlightsLocally(data);
          callback(data);
        } else {
          callback(null);
        }
      }
    }).fail(function() {
      if (typeof callback === "function" && callback) {
        callback(null);
      }
    });
  };

  Asteikko.onlineFuncs.getHighlights = function(issue, page, callback) {
    if (typeof issue === "undefined" || !issue || typeof callback === "undefined" ||
        !callback) {
      return;
    }
    
    // If user is not logged in, use offline functionality (localStorage)
    if (Asteikko.lastLoginStatus === false) {
      return Asteikko.offlineFuncs.getHighlights(issue, page, callback);
    }

    var params = {issue: issue};

    if (typeof page !== "undefined" && page) {
      params.page = page;
    }

    Asteikko._getHighlightsReal(params, callback);
  };

  Asteikko.offlineFuncs.getHighlights = function(issue, page, callback) {
  
    if ( !window.settings.highlights.enabled ) throw 'Highlights are not enabled';
    
    if (typeof issue === "undefined" || !issue || typeof callback === "undefined" ||
        !callback) {
      return;
    }

    var highlights = JSON.parse(Asteikko.localStorage.get('highlights_cache'));
    if (typeof callback === "function") callback(highlights);
  };

  Asteikko.onlineFuncs.getHighlightByID = function(highlight, callback) {
    if (typeof highlight === "undefined" || !highlight || typeof callback === "undefined" ||
        !callback) {
      return;
    }
    
    // If user is not logged in, use offline functionality (localStorage)
    if (Asteikko.lastLoginStatus === false) {
      return Asteikko.offlineFuncs.getHighlightByID(highlight, callback);
    }

    Asteikko._getHighlightsReal({highlight: highlight}, callback);
  };

  Asteikko.offlineFuncs.getHighlightByID = function(highlight, callback) {
  
    if ( !window.settings.highlights.enabled ) throw 'Highlights are not enabled';
    
    if (typeof highlight === "undefined" || !highlight || typeof callback === "undefined" ||
        !callback) {
      return;
    }

    var highlight = getHighlightLocal(highlight);
    if (typeof callback === "function") callback(highlight);
  };

  Asteikko._removeHighlightsReal = function(params, callback) {
    if (typeof params === "undefined" || !params ) {
      return;
    }

    $.ajax({
      url: Asteikko.env.site_url +'/asmag/highlights/?action=remove',
      type: 'POST',
      dataType: 'json',
      data: params
    }).done(function(stat) {
      if (typeof callback === "function" && callback) {
        if (stat !== false) {
          callback(true);
        } else {
          callback(false);
        }
      }
    }).fail(function() {
      if (typeof callback === "function" && callback) {
        callback(false);
      }
    });
  };

  Asteikko.onlineFuncs.removeHighlight = function(issue, page, callback) {
    if (typeof issue === "undefined" || !issue || typeof page === "undefined" || !page ) {
      return;
    }
    
    // If user is not logged in, use offline functionality (localStorage)
    if (Asteikko.lastLoginStatus === false) {
      return Asteikko.offlineFuncs.removeHighlight(issue, page, callback);
    }

    Asteikko._removeHighlightsReal({issue: issue, page: page}, callback);
  };

  Asteikko.offlineFuncs.removeHighlight = function(issue, page, callback) {
  
    if ( !window.settings.highlights.enabled ) throw 'Highlights are not enabled';
    
    if (typeof issue === "undefined" || !issue || typeof page === "undefined" || !page ) {
      return;
    }
    
    addHighlightLocal( null, issue, page, '' );
    if (typeof callback === "function") callback(true);
  };

  Asteikko.onlineFuncs.removeHighlightByID = function(highlight, callback) {
    if (typeof highlight === "undefined" || !highlight ) {
      return;
    }
    
    // If user is not logged in, use offline functionality (localStorage)
    if (Asteikko.lastLoginStatus === false) {
      return Asteikko.offlineFuncs.removeHighlightByID(highlight, callback);
    }

    Asteikko._removeHighlightsReal({highlight: highlight}, callback);
  };

  Asteikko.offlineFuncs.removeHighlightByID = function(highlight, callback) {
  
    if ( !window.settings.highlights.enabled ) throw 'Highlights are not enabled';
    
    if (typeof highlight === "undefined" || !highlight ) {
      return;
    }
    
    addHighlightLocal( highlight, null, null, '' );
    if (typeof callback === "function") callback(true);
  };
  
  // When user is logged in, save locally added highlights to the server.
  // asmag_loginstatus is also fired when network connectivity changes.
  $(window).bind('asmag_loginstatus', function(newStatus) {

    if ( !window.settings.highlights.enabled )
      return;

    if (newStatus === false)
      return;
  
    var localHighlights = JSON.parse(Asteikko.localStorage.get('highlights_cache'));
    if (!localHighlights || !localHighlights.length)
      return;

    function onHighlightSaved() {
    
      currSaveIndex++;
      
      if ( currSaveIndex === highlightsToAdd.length ) {
        // Cache saved to server, update the local cache from the server
        Asteikko.localStorage.remove('highlights_cache');
        // Get IDs of all the issues that have highlights
        var highlightIssueIds = [];
        for ( var i = 0; i < localHighlights.length; i++ ) {
          if ( $.inArray( localHighlights[ i ].issue_id, highlightIssueIds ) === -1 ) {
            highlightIssueIds.push( localHighlights[ i ].issue_id );
          }
        }
        var getHighlightsCount = 0;
        var allHighlightsFromServer = [];
        for ( var i = 0; i < highlightIssueIds.length; i++ ) {
          // Calling this function will update the local cache
          Asteikko.onlineFuncs.getHighlights( highlightIssueIds[ i ], null, function( serverIssueHighlights ) {
            getHighlightsCount++;
            allHighlightsFromServer = allHighlightsFromServer.concat( serverIssueHighlights );
            if ( getHighlightsCount === highlightIssueIds.length ) {
              $( window ).trigger( 'asmag_highlights_localsavedtoserver', [ localHighlights, allHighlightsFromServer ] );
            }
          } );
        }
      }
      else {
        saveNextHighlight();
      }
    }
    
    function saveNextHighlight() {
      var issueId = highlightsToAdd[ currSaveIndex ].issue_id;
      var pageId = highlightsToAdd[ currSaveIndex ].page_id;
      var highlight = highlightsToAdd[ currSaveIndex ].highlight;
      Asteikko.onlineFuncs.addHighlight(issueId, pageId, highlight, onHighlightSaved);
    }
    
    var highlightsToAdd = [];
    var currSaveIndex = 0;
    
    for ( var i = 0; i < localHighlights.length; i++ ) {
      if ( localHighlights[i]._createdLocally ) {
        highlightsToAdd.push(localHighlights[i]);
      }
      else if ( localHighlights[i]._modifiedLocally ) {
        if ( !localHighlightExpired( localHighlights[i] ) ) {
          highlightsToAdd.push(localHighlights[i]);
        }
      }
    }
    if ( highlightsToAdd.length ) saveNextHighlight();
  });

} )();
