
window.asteikkoMosaicGallery = function( containers, imageContainerSelector ) {

  function createMosaics() {
    $( containers ).each( function() {
      var that = this;
      asteikkoImg.checkImagesAreLoadedInside( this, function() {
        createMosaicInContainer( that );
      } );
    } );
  }

  function createMosaicInContainer( container ) {

    var container = $( container );

    // Reset

    container.find( imageContainerSelector ).removeClass( 'clear-left' ).css( 'width', '' );
    container[ 0 ].className = container[ 0 ].className.replace( /(^| )mosaic-\d+/g, '' );

    // Create mosaic

    var imgs = container.find( 'img' );
    container.addClass( 'mosaic imgcount-'+ imgs.length );
    
    var containerW = Math.floor( container.width() ) - 1;
    var imagesPerRow = 3;
    var currRowH = 0;
    // Widths that would normalize the heights of the images on the current row
    var currRowNewImageWidths = [];
    var currRowImageCount = 0;
    var landscapeRatio = 1.25;

    var nextImg = imgs.eq( 0 );
    var nextImgW = nextImg.width();
    var nextImgH = nextImg.height();

    for ( var i = 0; i < imgs.length; i++ ) {

      var img = nextImg;
      var imgW = nextImgW;
      var imgH = nextImgH;

      var isLandscape = ( imgW / imgH > landscapeRatio );

      // First image of the row
      if ( !currRowH ) {
        currRowH = imgH;
        currRowNewImageWidths.push( imgW );
        img.closest( imageContainerSelector ).addClass( 'clear-left' );
      }
      else {
        var imgToContainerHRatio = imgH / currRowH;
        currRowNewImageWidths.push( imgW / imgToContainerHRatio );
        img.closest( imageContainerSelector ).removeClass( 'clear-left' );
      }

      // Landscape image is the width of two portrait images
      currRowImageCount += ( isLandscape ) ? 2 : 1;

      nextImg = imgs.eq( i + 1 );
      nextImgW = nextImg.width();
      nextImgH = nextImg.height();
      var nextImgIsLandscape = ( nextImg.length ) ? nextImgW / nextImgH > landscapeRatio : false;
      // Cover a case where we first have two portrait images, then a landscape image.
      // We want the two portrait images on row 1, and the landscape image on row 2.
      var nextImgTooBigForCurrRow = Boolean( nextImg.length && !isLandscape && nextImgIsLandscape && currRowImageCount >= imagesPerRow - 1 );

      if ( currRowImageCount >= imagesPerRow || nextImgTooBigForCurrRow || i === imgs.length - 1 ) {

        var totalW = 0;
        for ( var j = 0; j < currRowNewImageWidths.length; j++ ) {
          totalW += currRowNewImageWidths[ j ];
        }

        var totalMargin = 0;
        for ( var j = 0; j < currRowNewImageWidths.length; j++ ) {
          var resizedImg = imgs.eq( i - currRowNewImageWidths.length + 1 + j );
          var imgWrapper = resizedImg.closest( imageContainerSelector );
          totalMargin += Math.ceil( imgWrapper.outerWidth( true ) - imgWrapper.outerWidth() );
        }

        var newWidthModifier = ( containerW - totalMargin ) / totalW;
        for ( var j = 0; j < currRowNewImageWidths.length; j++ ) {
          var resizedImg = imgs.eq( i - currRowNewImageWidths.length + 1 + j );
          var imgWrapper = resizedImg.closest( imageContainerSelector );
          imgWrapper.outerWidth( Math.floor( currRowNewImageWidths[ j ] * newWidthModifier ) );
        }

        currRowH = 0;
        currRowNewImageWidths.length = 0;
        currRowImageCount = 0;
      }
    }
  }

  createMosaics();

  var timeout;
  function onResize() {
    window.clearTimeout( timeout );
    timeout = window.setTimeout( createMosaics, 500 );
  }
  $( window ).resize( onResize );

  $( window ).on( 'asmag_issueclosed', function() {
    $( window ).off( 'resize', onResize );
  } );
};
