/*******************************************************************************
  Issue text search
*****************************************************************************/

if ( !window.Asteikko ) window.Asteikko = {};

( function() {
  
  /**
   * Setting contextSize to 0 will prevent search result excerpts/contexts 
   * from being generated, which will greatly increase performance.
   */
  Asteikko.search = function(query, callback, issues, offset, limit, contextSize, issuePublishStart, issuePublishEnd) {
    if ( typeof query === "undefined" || !query || typeof callback !== "function" || !callback) {
      return;
    }

    var requestData = {query: query, context_size: 3};

    if ( typeof issues !== "undefined" && issues) {
      if ( issues instanceof Array ) {
        requestData.issues = issues;
      } else {
        requestData.issues = [issues];
      }
    }
    if ( typeof offset !== "undefined") {
      requestData.offset = offset;
    }
    if ( typeof limit !== "undefined" && parseInt(limit) > 0) {
      requestData.limit = limit;
    }
    if ( typeof contextSize !== "undefined" && parseInt(contextSize) >= 0) {
      requestData.context_size = contextSize;
    }
    if ( typeof issuePublishStart !== "undefined" && parseInt(issuePublishStart) >= 0) {
      requestData.issue_publish_date_start = issuePublishStart;
    }
    if ( typeof issuePublishEnd !== "undefined" && parseInt(issuePublishEnd) >= 0) {
      requestData.issue_publish_date_end = issuePublishEnd;
    }

    $.ajax({
      url: Asteikko.env.site_url +'/asmag/search/',
      type: 'POST',
      dataType: 'json',
      data: requestData
    }).done(function(data) {
      if (data !== false) {
        callback(data);
      } else {
        callback(false);
      }
    }).fail(function() {
      callback(false);
    });
  };

} )();