
( function( $ ) {

	var editorNotes = Asteikko.editorNotes;

	var editorNotesUI = editorNotes.ui = {
		isAllowedToRun: false,
		currentUser: null,
		currentPageId: null,
		currentNote: null,
		selectedContainer: null,
		totalNotes: 0,
		priorityStatus: {}
	};

	function handleMessage( message ) {
		
		if ( !message || message.length < 0 ) {
			return;
		}

		var _timeoutID = null;
		function _showMessage( message ) {
			$( '#asmag-admin-bar > .message-bar:first' ).text( message );
			$( '#asmag-admin-bar > .message-bar:first' ).css( 'display', 'block' );
		}

		function _setup( message ) {
			if ( _timeoutID !== null ) {
				clearTimeout( _timeoutID );
			}
			
			_timeoutID = setTimeout( function() {
				$( '#asmag-admin-bar > .message-bar:first' ).css( 'display', 'none' );
			}, 3000 );

			_showMessage( message );
		}

		_setup( message );
	}

	function getEventHandlersForDom() {
		var _inlineOnclick = null;

		var _eventList = [];

		function _sanitizeSelection( target ) {
			var _target = target;
			var _element = $( _target )[0];
			var _displayStyle;

			if ( _element ) {
				if ( window.getComputedStyle ) {
					_displayStyle = window.getComputedStyle( _element, null ).getPropertyValue( 'display' );
				} else {
					_displayStyle = _element.currentStyle.display;
				}
			}
			
			if ( [ 'inline', 'inline-block' ].indexOf( _displayStyle ) > -1 ) {
				_target = _target.parentNode;
			}

			return _target;
		}

		var _mouseOver = {
			name: 'mouseover.asmag_editornote_domevents',
			handler: function( e ) {

				if ( $( '#asmag-addNoteButton' ).is( ':visible' ) ) {
					if ( !$( '#asmag-addNoteButton' ).hasClass( 'disabled-button' ) ) {
						$( '#asmag-addNoteButton' ).hide();
					}
				}

				e.target = _sanitizeSelection( e.target );

				if ( $( e.target ).hasClass( 'note-wrapper' ) || $( e.target ).children( ':first' ).hasClass( 'note-wrapper' ) ) {
					return;
				}

				$( e.target ).addClass( 'editor-mode-hover' );

				if ( $( e.target ).attr( 'onclick' ) ) {
					_inlineOnclick = $( e.target ).attr( 'onclick' );
					$( e.target ).attr( 'onclick', false );
				}
			}
		};

		var _mouseLeave = {
			name: 'mouseleave.asmag_editornote_domevents',
			handler: function( e ) {
				if ( $( e.target ).hasClass( 'note-wrapper' ) || $( e.target ).children( ':first' ).hasClass( 'note-wrapper' ) ) {
					return;
				}

				$( '.editor-mode-hover' ).removeClass( 'editor-mode-hover' );

				if ( $( this ).attr( 'onclick' ) ) {
					$( this ).attr( 'onclick', _inlineOnclick );
					_inlineOnclick = null;
				}
			}
		};

		var _click = {
			name: 'click.asmag_editornote_domevents',
			handler: function( e ) {
				// console.log(e.target);

				e.target = _sanitizeSelection( e.target );

				if ( $( e.target ).hasClass( 'note-wrapper' ) || $( e.target ).children( ':first' ).hasClass( 'note-wrapper' ) ) {
					return;
				}

				var _winWidth = $( window ).outerWidth();
				var _magWidth = $( '#mag' ).width();
				var _diffWidth = ( _winWidth > _magWidth ) ? Math.floor( ( _winWidth - _magWidth ) / 2 ) : 0;
				
				var _left = e.pageX - _diffWidth + 10;
				var _top = e.pageY - $( '#asmag-addNoteButton' ).height() - 40;
				
				$( '#asmag-addNoteButton' ).css({ top: 0, left: 0 });
				$( '#asmag-addNoteButton' ).css({
					top: _top,
					left: _left
				});
				$( '#asmag-addNoteButton' ).show( 'fast' );

				editorNotesUI.selectedContainer = $( e.target );
				
				e.preventDefault();
				e.stopPropagation();
			}
		};

		_eventList.push( _mouseOver );
		_eventList.push( _mouseLeave );
		_eventList.push( _click );

		return _eventList;
	}

 	function makeUTCToLocal( stringDate ) {

 		if ( !stringDate || typeof stringDate !== 'string' ) {
 			return;
 		}

 		function _formatLocalTime( dateObj ) {
 			var _date = dateObj.getDate();
 			var _month = ( dateObj.getMonth() + 1 ) < 10 ? '0' + ( dateObj.getMonth() + 1 ) : ( dateObj.getMonth() + 1 );
 			var _year = dateObj.getFullYear();
 			var _hours = dateObj.getHours();
 			var _minutes = dateObj.getMinutes();

 			return _date + '/' + _month + '/' + _year + ' ' + _hours + ':' + _minutes;
 		}

		var _str = stringDate || "";
	    var _year = +_str.substr( 0, 4 ) || 0;
	    var _month = ( +_str.substr( 5, 2 ) - 1 );
	    var _day = +_str.substr( 8, 2 ) || 0;
	    var _hour = +_str.substr( 11, 2 ) || 0;
	    var _min = +_str.substr( 14, 2 ) || 0;
	    
	    if ( !_str || !_year || _month < 0 || !_day ) return null;

	    return _formatLocalTime( new Date( Date.UTC( _year, _month, _day, _hour, _min ) ) );
	}

 	function parsingDataToPanel( data, user ) {
		$( '#asmag-editorPanel' ).attr( 'note-data', 'asmag-editornote-' + data.id );

		var _statusDefaults = window.settings.editor_notes.editornoteview.status_list;
		var _statusMeta = _statusDefaults[ $.trim( data.status.toLowerCase() ) ];
		
		$( '#asmag-editorPanel' ).find( '.status-dropdown:first' ).css( 'background-color', '#' + _statusMeta.background );
		$( '#asmag-editorPanel' ).find( '.status:first' ).text( _statusMeta.translation );

		$( '#asmag-statusList' ).find( 'span[name="' + _statusMeta.class_key + '"]').css( 'display', 'none' );

		$( '#asmag-noteUser > .loggedInTime' ).text( makeUTCToLocal( data.created_at ) );

		$( '#asmag-noteUser > .userName' ).append( user.userDisplayName );

		$( '#asmag-noteComment' ).find( 'textarea' ).val( data.comment );
	}

	function unparsingDataOffPanel() {
		$( '#asmag-editorPanel' ).removeAttr( 'note-data' );

		var _onStatus = $.trim( editorNotesUI.currentNote.status.toLowerCase() );

		if ( $( '#asmag-statusList' ).is( ':visible' ) ) {
			$( '#asmag-statusList' ).hide();
		}
		$( '#asmag-statusList > span[name="' + _onStatus + '"]' ).show();

		$( '#asmag-editorPanel' ).find( '.status-dropdown:first' ).css( 'background', 'none' );
		$( '#asmag-editorPanel' ).find( '.status:first' ).text( '' );

		$( '#asmag-noteUser > .loggedInTime' ).text( '' );

		$( '#asmag-noteUser > .userName' ).contents().filter( function() {
			return this.nodeType === 3;
		}).remove();

		$( '#asmag-noteComment' ).find( 'textarea' ).val( '' );
		$( '.inArea' ).removeAttr( 'disabled' );

		$( '#asmag-noteButtons > a' ).removeClass( 'disabled-button' );
	}

	function removeSingleNote( noteId ) {

		if ( $( '#' + noteId ).attr( 'note-view' ) !== 'editorNotes' ) {
			return;
		}

		if ( $( '#' + noteId ).hasClass( 'note-mask' ) || 
			$( '#' + noteId ).hasClass( 'note-mask-opened' ) ) {

			$( '#' + noteId ).remove();

			if ( $( '#asmag-lostPanel .display-notes:first' ).children().length <= 0 ) {
				$( '#asmag-lostPanel .display-notes:first' ).contents().remove();

				/* For Mobile View Only */
				if ( $( '.lost-title-mobi' ).is( ':visible' ) ) {
					$( '#asmag-lostPanel .display-notes:first' ).hide();
				}

				$( '#asmag-lostPanel' ).hide();
			}

		} else {
			$( '#' + noteId ).find( 'i' ).remove();
			$( '#' + noteId ).contents().unwrap();
		}

		if ( $( '#asmag-editorPanel' ).is( ':visible' ) ) {
			unparsingDataOffPanel();
			$( '#asmag-editorPanel' ).hide();
	 		editorNotesUI.currentNote = null;
		}
	}

	function terminateAllWrappers() {

		$( '.note-wrapper' ).each( function() {
			removeSingleNote( $( this ).attr( 'id' ) );
		});
		$( '#asmag-addNoteButton' ).remove();

		$( '.with-panels' ).removeClass( 'with-panels' );
		$( '.with-lost-mobi' ).removeClass( 'with-lost-mobi' );
		$( '.with-editor-mobi' ).removeClass( 'with-editor-mobi' );

		$( '.fix-right' ).removeClass( 'fix-right' );
		$( '.fix-right-no-lost' ).removeClass( 'fix-right-no-lost' );
		$( '.fix-left' ).removeClass( 'fix-left' );
	}

	function updateDataForNoteCount( highPriorityStatusList ) {
		var _noteCount = {
			color: null,
			count: 0
		};
		var _highPriorityStatusList = null;

		if ( highPriorityStatusList ) {
			
			_highPriorityStatusList = highPriorityStatusList;			
		} else {
			var _defaultStatusList = settings.editor_notes.editornoteview.status_list;
			_highPriorityStatusList = {};

			for( var prop in _defaultStatusList ) {
				if ( _defaultStatusList[prop].priority && _defaultStatusList[prop].priority > 0 ) {
					_highPriorityStatusList[prop] = _defaultStatusList[prop];
				}
			}
		}
		

		var _propStatus = null;
		for( var prop in editorNotesUI.priorityStatus ) {
			if( !_propStatus ) {
				// console.log('called');
				_propStatus = {};
				_propStatus.name = prop;
				_propStatus.value = editorNotesUI.priorityStatus[prop];
			} else {
				if ( _propStatus.value < editorNotesUI.priorityStatus[prop] ) {
					_noteCount.color = _highPriorityStatusList[prop].class_key;
				} else if ( _propStatus.value > editorNotesUI.priorityStatus[prop] ) {
					_noteCount.color = _highPriorityStatusList[_propStatus.name].class_key;
				} else {
					var _check = _highPriorityStatusList[prop].priority - _highPriorityStatusList[_propStatus.name].priority;
					if ( _check < 0 ) {
						_noteCount.color = _highPriorityStatusList[_propStatus.name].class_key;
					} else {
						_noteCount.color = _highPriorityStatusList[prop].class_key;
					}
				}
			}
		}

		for( var prop in editorNotesUI.priorityStatus ) {
			_noteCount.count += editorNotesUI.priorityStatus[prop];
		}

		return _noteCount;
	}

	function defaultNoteCount() {
		for( var prop in settings.editor_notes.editornoteview.status_list ) {
			if ( $( '#asmag-admin-bar .note-counts' ).hasClass( prop ) ) {
				$( '#asmag-admin-bar .note-counts' ).removeClass( prop );
			}
		}

		$( '#asmag-admin-bar .note-counts' ).addClass( 'default' );
		$( '#asmag-admin-bar .note-counts' ).text( '' );
	}

	function publishUpdatedCount() {

		if ( $( '#asmag-admin-bar .note-counts' ).hasClass( 'default' ) ) {
			$( '#asmag-admin-bar .note-counts' ).removeClass( 'default' );
		} else {
			for( var prop in settings.editor_notes.editornoteview.status_list ) {
				if ( $( '#asmag-admin-bar .note-counts' ).hasClass( prop ) ) {
					$( '#asmag-admin-bar .note-counts' ).removeClass( prop );
				}
			}
		}
		
		var _noteCount = updateDataForNoteCount();
		$( '#asmag-admin-bar .note-counts' ).addClass( _noteCount.color );
		$( '#asmag-admin-bar .note-counts' ).text( _noteCount.count + '/' + editorNotesUI.totalNotes );
	}

	function initializeFetchingNotesByPage() {
		
		var _asmagPageId = editorNotesUI.currentPageId;
		var _parsables = [];

		Asteikko.log( 'Fetching notes from server for page ' + _asmagPageId );

		function _findTextRecursive( asmagPage, text ) {

 			if ( $( asmagPage ).is( 'iframe' ) )
 				return null;

			var _foundNode = null;
			var _contents = $( asmagPage ).contents();
			var _textContents = $( _contents ).filter( function() {
				return ( this.nodeType === 3 );
			});

			if ( _textContents.length > 0 ) {
				for( var i = 0; i < _textContents.length; i++ ) {
					var _testContent = _textContents[i].nodeValue;
					_testContent = $.trim( _testContent );
					if ( _testContent.length > 0 ) {
						var _trimmedContent = $.trim( _testContent.replace( /\s+/g, '' ) );
						var _index = _trimmedContent.indexOf( text );
						if ( _index === 0 ) {
							_foundNode = _textContents[i].parentNode;
							break;
						}
					}
				}
			}

			if ( _foundNode ) {
				return _foundNode;
			}

			var _elemContents = $( _contents ).filter( function() {
				return ( this.nodeType === 1 );
			});

			for ( var i = 0; i < _elemContents.length; i++ ) {
				_foundNode = _findTextRecursive( _elemContents.eq( i ), text );
				if ( _foundNode )
					return _foundNode;
			}
		}

		function _searchByAsmagPage( note ) {
			
			var _asmagPage = '#asmag-page-' + _asmagPageId;

			var _foundNode = _findTextRecursive( _asmagPage, note.text_content );
			
			if ( _foundNode ) {
				var _newPath = '';
				if ( $( _foundNode ).attr( 'id' ) ) {
					_newPath = '#' + $( _foundNode ).attr( 'id' );
 				} else  if ( $( _foundNode ).attr( 'class' ) ) {
 					var _ifHasClass = $( _foundNode ).attr( 'class' ) + ' ';
 					_newPath = '.' + _ifHasClass.split(' ')[0];
 				} else {
 					_newPath = $( _foundNode ).prop( 'tagName' ).toLowerCase();
 				}
 				
 				var _elemIndex = $( _asmagPage + ' ' + _newPath ).index( _foundNode );
 				note.selector_path = _asmagPage + ' ' + _newPath + ':eq(' + _elemIndex + ')';

 				_parsables.push( note );
 				editorNotes.ajaxFuncs.updateEditorNote( note );

			} else {
				var _lostPanel = '#asmag-lostPanel';
				var _displayLost = $( _lostPanel ).find( '.display-notes' ).first();
				var _noteMask = '<span id="asmag-editornote-' + note.id + '" class="note-wrapper note-mask" note-view="editorNotes"></span>';
				$( _noteMask ).appendTo( _displayLost );

				var _defaults = window.settings.editor_notes.editornoteview.status_list;
				$( '#asmag-editornote-' + note.id ).addClass( _defaults[$.trim( note.status.toLowerCase() )].class_key );

				var _comment = note.comment;
				_comment = $.trim( _comment );
				if ( _comment.length > 0 ) {
					if ( _comment.length >= 31 ) {
						_comment = _comment.slice( 0, 35 ) + '...';
					}
					$( '#asmag-editornote-' + note.id ).text( _comment );
				} else {
					$( '#asmag-editornote-' + note.id ).addClass( 'no-comment' );
				}
				$( '#asmag-editornote-' + note.id ).prepend( '<i class="icon_pushpin"></i>' );			
			}
			return;
		}

		function _searchByTextContainer( pathHeader, note ) {
			
			var _containerId = pathHeader.split('=')[1];
			_containerId = _containerId.replace( /"+|]/g, '' );

			var _container = $( '*[data-asmag-id="' + _containerId + '"]' );
			if ( _container && _container.length > 0 ) {

				var _containerKids = _container.children();
				var _found = false;

				for( var i = 0; i < _containerKids.length; i++ ) {

					if ( $( this ).is( 'iframe' ) )
						continue;

					var _testContent = $( this ).text();
					_testContent = $.trim( _testContent.replace( /\s+/g, '' ) );
					if ( _testContent.indexOf( note.text_content ) === 0 ) {
						_found = {
							index: $( this ).index(),
							tag: $( this ).prop( 'tagName' ).toLowerCase()
						};
						break;
					} 
				}

				if ( _found ) {
					note.selector_path = '*[data-asmag-id="' + _containerId + '"] ' + _found.tag + ':eq(' + _found.index + ')';
					
					_parsables.push( note );
					editorNotes.ajaxFuncs.updateEditorNote( note );
				} else {
					_searchByAsmagPage( note );
				}
			} else {
				_searchByAsmagPage( note );
			}
		}

		function _breakSelectorPaths( notes ) {

			for( var i = 0; i < notes.length; i++ ) {
				var _pathHeader = notes[i].selector_path.split( ' ' )[0];

				if ( _pathHeader.indexOf( 'data-asmag-id' ) > -1 ) {
					_searchByTextContainer( _pathHeader, notes[i] );
				} else {
					_searchByAsmagPage( notes[i] );
				}
			}
		}

		function _filterNotes( notes, callbackForRogues ) {
			var _rogueNotes = [];

			for( var i = 0; i < notes.length; i++ ) {
				var _path = $( notes[i].selector_path );
				if ( _path && _path.length > 0 ) {
					var _pathText = $( _path ).text();
					_pathText = $.trim( _pathText.replace( /\s+/g, '' ) );
					if ( _pathText.indexOf( notes[i].text_content ) === 0 ) {
						_parsables.push( notes[i] );
					} else {
						_rogueNotes.push( notes[i] );
					}
				} else {
					_rogueNotes.push( notes[i] );
				}
			}

			if ( callbackForRogues && typeof callbackForRogues === 'function' ) {
				callbackForRogues( _rogueNotes );
			}
		}

		function _parseNotesToUI( notes, callAfterParsing ) {
			var _defaults = window.settings.editor_notes.editornoteview.status_list;
			for( var i = 0; i < notes.length; i++ ) {

				var _status = $.trim( notes[i].status.toLowerCase() );
				var _wrapper = '<span id="asmag-editornote-' + notes[i].id + '" class="note-wrapper note-marker ' + _defaults[_status].class_key + '" note-view="editorNotes"></span>';

				$( notes[i].selector_path ).wrapInner( _wrapper );
				$( '<i class="icon_pushpin"></i>' ).appendTo( '#asmag-editornote-' + notes[i].id );
			}

			if ( callAfterParsing && typeof callAfterParsing === 'function' ) {
				callAfterParsing( onViewChanged );
			}
		}

		function _afterParsingNotes( onViewChangedCallback ) {
			var _lostNotes = $( '#asmag-lostPanel > .display-notes:first' ).children();
			if ( _lostNotes && _lostNotes.length > 0 ) {
				$( '#asmag-lostPanel' ).show();
			}

			if ( onViewChangedCallback && typeof onViewChangedCallback === 'function' ) {
				onViewChangedCallback();
			}
		}

		function _initNoteCount( notes ) {
			var _defaultStatusList = settings.editor_notes.editornoteview.status_list;
			var _highPriorityStatusList = {};

			for( var prop in _defaultStatusList ) {
				if ( _defaultStatusList[prop].priority && _defaultStatusList[prop].priority > 0 ) {
					_highPriorityStatusList[prop] = _defaultStatusList[prop];
					editorNotesUI.priorityStatus[prop] = 0;
				}
			}

			if ( notes ) {
				editorNotesUI.totalNotes = notes.length;

				for( var i = 0; i < notes.length; i++ ) {
					var _status = $.trim( notes[i].status.toLowerCase() );
					if ( _defaultStatusList[_status] && _highPriorityStatusList[_status] ) {
						editorNotesUI.priorityStatus[_status]++;
					}
				}

				var _noteCount = updateDataForNoteCount( _highPriorityStatusList );
				if ( $( '#asmag-admin-bar .note-counts' ).hasClass( 'default' ) ) {
					$( '#asmag-admin-bar .note-counts' ).removeClass( 'default' );
					$( '#asmag-admin-bar .note-counts' ).addClass( _noteCount.color );
				}
				
				$( '#asmag-admin-bar .note-counts' ).text( _noteCount.count + '/' + editorNotesUI.totalNotes );
			}			
		}

		function _actionsAfterFetching( notes ) {
			_initNoteCount( notes );
			_filterNotes( notes, _breakSelectorPaths );
			_parseNotesToUI( _parsables, _afterParsingNotes );
		}

		editorNotes.ajaxFuncs.getEditorNotesByPage( asteikkoIssue.id, _asmagPageId, function( notes ) {
			
			if ( notes && notes.length > 0 ) {
				_actionsAfterFetching( notes );
			} else {
				_initNoteCount();
			}
		});
	} 

 	function makeDomResponsiveForNotes( elem ) {

 		var _ignoredElements = { elems: [ 'SCRIPT', 'STYLE', 'VIDEO', 'IFRAME', 'BUTTON', 'I' ] };
 		
 		var _addNotePopup = $( '#asmag-addNoteButton' );
	    if ( _addNotePopup.length <= 0 ) {
			_addNotePopup = $( '<div></div>');
			_addNotePopup.attr( 'id', 'asmag-addNoteButton' );
			_addNotePopup.attr( 'class', 'add-note-button' );
			_addNotePopup.append( '<i class="icon_loading" style="display:none"></i>' );
			_addNotePopup.append( '<i class="icon_plus_alt"></i>' );
			_addNotePopup.append( '<span>Add note</span>' );
			$( elem || 'body' ).append( _addNotePopup );
	    }

	    function _addDomEventsToNode( node ) {
	    	var _eventList = getEventHandlersForDom(); // eventList array
	    	for( var i = 0; i < _eventList.length; i++ ) {
	    		$( node ).on( _eventList[i].name, _eventList[i].handler );
	    	}
	    }

		function _recursiveDomOnEvents( container, ignored ) {
			var _elemsToBeIgnored = $.extend( { elems: [] }, ignored );

			var _contents = $( container ).contents();
			var _textContents = $( _contents ).filter( function() {
				return ( 
					this.nodeType === 3 &&
					_elemsToBeIgnored.elems.indexOf( this.parentNode.nodeName ) < 0
				);
			});

			if ( _textContents.length > 0 ) {
				for( var i = 0; i < _textContents.length; i++ ) {
					var _testContent = _textContents[i].nodeValue;
					_testContent = $.trim( _testContent );
					if ( _testContent.length > 0 ) {
						_addDomEventsToNode( _textContents[i].parentNode );
					}
				}
			}

			var _elemContents = $( _contents ).filter( function() {
				return (
					this.nodeType === 1 && 
					_elemsToBeIgnored.elems.indexOf( this.nodeName ) < 0
				);
			});

			if ( _elemContents.length > 0 ) {
				for( var i = 0; i < _elemContents.length; i++ ) {
					_recursiveDomOnEvents( _elemContents[i], _ignoredElements );
				}
			}
		}

		var _superContainer = $( elem );
		var _asmagPages = _superContainer.find( '.asmag-page' );
		
		$( _asmagPages ).each( function() {
			_recursiveDomOnEvents( this, _ignoredElements );
		});
 	}

 	function makeDomBackToNormal( elem ) {

 		function _recursiveDomOffEvents( container ) {

 			if ( $( container ).is( 'iframe' ) )
 				return;

			var _contents = $( container ).contents();
			var _textContents = $( _contents ).filter( function() {
				return ( this.nodeType === 3 );
			});

			$( _textContents ).each( function() {
				$( this.parentNode ).off( '.asmag_editornote_domevents' );
			});

			var _elemContents = $( _contents ).filter( function() {
				return ( this.nodeType === 1 );
			});

			$( _elemContents ).each( function() {
				_recursiveDomOffEvents( this );
			});
		}

		var _superContainer = $( elem );
		var _asmagPages = _superContainer.find( '.asmag-page' );
		
		$( _asmagPages ).each( function() {
			_recursiveDomOffEvents( this );
		});
 	}

 	function initializeNoteWrapper() {

 		var _asmagPageId = editorNotesUI.currentPageId;

 		function _addNoteFunc( e ) {
 			if ( !editorNotesUI.selectedContainer ) {
 				return;
 			}

 			$( '#asmag-addNoteButton' ).show();
 			$( '#asmag-addNoteButton' ).addClass( 'disabled-button' );
 			$( '#asmag-addNoteButton' ).find( '.icon_loading' ).show();
 			$( '#asmag-addNoteButton' ).find( '.icon_plus_alt' ).hide();
 			
 			var _container = editorNotesUI.selectedContainer;
 			editorNotesUI.selectedContainer = null;

 			$( '*[note-view="editorNotes"]' ).off( '.asmag_onviewchanged' );
 			$( document ).off( '.asmag_onviewchanged' );

 			$( _container ).wrapInner( "<span id='initializedSpan' class='note-wrapper'></span>" );
 			$( '#mag' ).trigger( 'asmag_editornoteschanged', [ _container, _asmagPageId ] );
 			e.stopPropagation();
 		}

 		$( '#asmag-addNoteButton' ).on( 'click', _addNoteFunc );
 	}

 	function initializeNoteCreatedInDB( selected, asmagPageId ) {

 		var _showPage = 'asmag-page-' + asmagPageId;

 		function _sanitizeTextSnippet( selected ) {
 			return $.trim( $( selected ).text().replace( /\s+/g, '' ) );
 		}

 		function _getContainerSelectorPath( selected, showPage ) {
 			var _selectorPath = '';

 			var _ancestor = $( selected ).closest('*[data-asmag-id]');
 			var _selectedTag = $( selected ).prop( 'tagName' ).toLowerCase();
 			var _elemIndex = $( _ancestor ).find( _selectedTag ).index( selected );

 			if ( _ancestor.length && _ancestor.length > 0 ) {
 				var _ancestorId = $( _ancestor ).attr( 'data-asmag-id' );
 				var _ancestorTag = $( _ancestor ).prop( 'tagName' ).toLowerCase();
 				_selectorPath = _ancestorTag + '[data-asmag-id="' + _ancestorId + '"] ' + _selectedTag + ':eq(' + _elemIndex + ')';

 			} else if ( _ancestor.length <= 0 ) {
 				var _selectedSignal = null;
 				_ancestor = '#' + _showPage;
 				if ( $( selected ).attr( 'id' ) ) {
					_selectedSignal = '#' + $( selected ).attr( 'id' );
 				} else  if ( $( selected ).attr( 'class' ) ) {
 					var _ifHasClass = $( selected ).attr( 'class' ) + ' ';
 					_selectedSignal = '.' + _ifHasClass.split(' ')[0];
 				} else {
 					_selectedSignal = $( selected ).prop( 'tagName' ).toLowerCase();
 				}
 				
 				_elemIndex = $( _ancestor + ' ' + _selectedSignal ).index( selected );
 				_selectorPath = _ancestor + ' ' + _selectedSignal + ':eq(' + _elemIndex + ')';
 			}
 			
 			return _selectorPath;
 		}

 		var _containerSelectorPath = _getContainerSelectorPath( selected, _showPage );
 		var _textContents = _sanitizeTextSnippet( selected ).slice( 0, 50 );
 		
 		editorNotes.ajaxFuncs.addEditorNotes( asteikkoIssue.id, asmagPageId, {
 			selector_path: _containerSelectorPath,
 			text_content: _textContents
 		}, parsingCreatedNoteToUI );
 	}

 	function parsingCreatedNoteToUI( data ) {

 		if ( !data ) {
 			return;
 		}

 		var _editorPanel = '#asmag-editorPanel';

		if ( $( _editorPanel ).is( ':visible' ) ) {
			var _shownPanelData = $( _editorPanel ).attr( 'note-data' );
			if ( $( '#' + _shownPanelData ).hasClass( 'note-mask-opened' ) ) {
				$( '#' + _shownPanelData ).removeClass( 'note-mask-opened' );
				$( '#' + _shownPanelData ).addClass( 'note-mask' );
			} else {
				$( '#' + _shownPanelData ).removeClass( 'note-opened' );
				$( '#' + _shownPanelData ).addClass( 'note-marker' );
			}
			
			unparsingDataOffPanel();
			$( _editorPanel ).hide();
		}

		editorNotesUI.currentNote = data.note;
		var _status = $.trim( data.note.status.toLowerCase() );

		$( '#initializedSpan' ).attr( 'id', 'asmag-editornote-' + data.note.id );
		$( '#asmag-editornote-' + data.note.id ).attr( 'note-view', 'editorNotes' );
		$( '#asmag-editornote-' + data.note.id ).addClass( 'note-opened' );
		$( '#asmag-editornote-' + data.note.id ).addClass( _status );
		$( "<i class='icon_pushpin'></i>" ).appendTo( '#asmag-editornote-' + data.note.id );		

		parsingDataToPanel( data.note, data.user );
		$( _editorPanel ).show();

		$( '#asmag-addNoteButton' ).hide();
		$( '#asmag-addNoteButton' ).removeClass( 'disabled-button' );
		$( '#asmag-addNoteButton' ).find( '.icon_loading' ).hide();
		$( '#asmag-addNoteButton' ).find( '.icon_plus_alt' ).show();

		editorNotesUI.priorityStatus[_status]++;
		editorNotesUI.totalNotes++;
		publishUpdatedCount();

		handleMessage( settings.lang.editornoteview.messages.created );

		onViewChanged( data.user );
 	}

 	function onViewChanged( noteUser ) {
 		
 		var _asmagPageId = editorNotesUI.currentPageId || null;
 		var _currentNote = editorNotesUI.currentNote || null;
 		var _currentUser = editorNotesUI.currentUser || null;
 		var _noteUser = noteUser || null;
 		var _currentWinW = $( window ).width();

 		function _authorizeUser( currentUser, noteUser ) {

 			if ( !noteUser ) {
 				return null;
 			}

 			var _authority = {
 				editComment: false,
 				deleteNote: false
 			};

 			if ( currentUser.adminCapable ) {
 				for( var prop in _authority ) {
 					_authority[prop] = true;
 				}
 			} else if ( currentUser.editorCapable && !currentUser.adminCapable ) {
 				if ( parseInt( noteUser.userId ) === currentUser.userId ) {
 					for( var prop in _authority ) {
	 					_authority[prop] = true;
	 				}
 				}
 			}

 			return _authority;
 		}

 		var _authorized = _authorizeUser( _currentUser, _noteUser );
 		
 		function _toggleWrapperPanel( data ) {
 			if ( !data ) {
 				return;
 			}

 			if ( $( '#asmag-editorPanel' ).is( ':visible' ) ) {
 				var _openNoteId = $( '#asmag-editorPanel' ).attr( 'note-data' );

 				if ( $( '#' + _openNoteId ).hasClass( 'note-opened' ) ) {
 					$( '#' + _openNoteId ).removeClass( 'note-opened' );
					$( '#' + _openNoteId ).addClass( 'note-marker' );
 				} else {
 					$( '#' + _openNoteId ).removeClass( 'note-mask-opened' );
					$( '#' + _openNoteId ).addClass( 'note-mask' );
 				}
				
				unparsingDataOffPanel();
			}

 			_currentNote = editorNotesUI.currentNote = data.note;
 			_noteUser = data.user;

 			_authorized = _authorizeUser( _currentUser, _noteUser );
 			
 			var _item = $( '#asmag-editornote-' + _currentNote.id ); 			

			if ( $( _item ).hasClass( 'note-marker' ) ) {
				$( _item ).removeClass( 'note-marker' );
				$( _item ).addClass( 'note-opened' );
			} else if ( $( _item ).hasClass( 'note-mask' ) ) {
				$( _item ).removeClass( 'note-mask' );
				$( _item ).addClass( 'note-mask-opened' );
			}

			parsingDataToPanel( _currentNote, _noteUser );

			if ( !_authorized.editComment ) {
				$( '.inArea' ).attr( 'disabled', 'true' );
			}
			if ( !_authorized.deleteNote ) {
				$( '#asmag-delNote' ).addClass( 'disabled-button' );
			}

			if ( _currentWinW >= settings.editor_notes.min_tablet_view &&
				_currentWinW <= settings.editor_notes.max_tablet_view ) {

				if ( $( '#asmag-lostPanel' ).is( ':hidden' ) ) {
					$( '#asmag-editorPanel' ).removeClass( 'fix-right' );
					$( '#asmag-editorPanel' ).addClass( 'fix-right-no-lost' );
				} else {
					$( '#asmag-editorPanel' ).removeClass( 'fix-right-no-lost' );
					$( '#asmag-editorPanel' ).addClass( 'fix-right' );
				}
				$( '#magazine-wrapper' ).addClass( 'with-panels' );
			}

			if ( _currentWinW < settings.editor_notes.min_tablet_view ) {
				$( '#magazine-wrapper' ).removeClass( 'with-lost-mobi' );
				$( '#magazine-wrapper' ).addClass( 'with-editor-mobi' );
			}

			$( '#asmag-editorPanel' ).show();
 		}

 		function _switchButtons( remove ) {
 			if ( remove ) {
 				$( '#asmag-status-close > .status-dropdown' ).removeClass( 'disabled-button' );
				$( '.note-close-button' ).removeClass( 'disabled-button' );
				return;
 			}

 			$( '#asmag-status-close > .status-dropdown' ).addClass( 'disabled-button' );
			$( '.note-close-button' ).addClass( 'disabled-button' );
 		}

 		function _checkStatusPriority( note, oldStatus ) {
			if ( !note ) {
				return;
			}

			var _oldStatus = oldStatus || null;
			var _status = note.status;
			_status = $.trim( _status.toLowerCase() );
			var _defaultStatusList = settings.editor_notes.editornoteview.status_list;
			var _isCountingDone = false;

			// when save note
			if ( _oldStatus ) {
				_oldStatus = $.trim( _oldStatus.toLowerCase() );
				if ( _defaultStatusList[_oldStatus] && _defaultStatusList[_oldStatus].priority >= 0 ) {
					var _check = _defaultStatusList[_status].priority - _defaultStatusList[_oldStatus].priority;
					if ( _check > 0 ) {
						if ( _defaultStatusList[_oldStatus].priority > 0 ) { // _oldStatus = 1
							editorNotesUI.priorityStatus[_oldStatus]--;
						}
						editorNotesUI.priorityStatus[_status]++;
						_isCountingDone = true;
					} else if ( _check < 0 ) {
						if ( _defaultStatusList[_status].priority > 0 ) { // _oldStatus = 2
							editorNotesUI.priorityStatus[_status]++;
						}
						editorNotesUI.priorityStatus[_oldStatus]--;
						_isCountingDone = true;
					}
					// _check = 0 means there is no update to status => _isCountingDone = false				
				}
			} else { // For delete note
				if ( _defaultStatusList[_status] && _defaultStatusList[_status].priority > 0 ) {
					editorNotesUI.priorityStatus[_status]--;
				}
				_isCountingDone = true;
			}

			return _isCountingDone;
		}

 		function _onSaveNote( check ) {
 			// change color of status on wrapper
 			if ( check ) {
 				var _noteId = _currentNote.id;
 				var _oldStatus = null;
 				var _defaults = window.settings.editor_notes.editornoteview.status_list;
 				for( var _prop in _defaults ) {
 					if ( $( '#asmag-editornote-' + _noteId ).hasClass( _defaults[_prop].class_key ) ) {
 						_oldStatus = _defaults[_prop].class_key;
 						$( '#asmag-editornote-' + _noteId ).removeClass( _defaults[_prop].class_key );
 					}
 				}
 				$( '#asmag-editornote-' + _noteId ).addClass( _defaults[$.trim( _currentNote.status.toLowerCase() )].class_key );

 				var _isCountingDone = _checkStatusPriority( _currentNote, _oldStatus );
 				if ( _isCountingDone ) {
 					publishUpdatedCount();
 				}

 				if ( $( '#asmag-editornote-' + _noteId ).hasClass( 'note-mask-opened' ) ) {
 					var _testComment = _currentNote.comment;
 					_testComment = $.trim( _testComment );
 					if ( _testComment.length > 0 ) {
 						if ( $( '#asmag-editornote-' + _noteId ).hasClass( 'no-comment' ) ) {
	 						$( '#asmag-editornote-' + _noteId ).removeClass( 'no-comment' );
	 					}

	 					if ( _testComment.length >= 31 ) {
	 						_testComment = _testComment.slice( 0, 35 ) + '...';
	 					}

	 					$( '#asmag-editornote-' + _noteId ).contents().remove();

	 					$( '#asmag-editornote-' + _noteId ).text( _testComment );

	 					$( '#asmag-editornote-' + _noteId ).prepend( '<i class="icon_pushpin"></i>' );

 					} else {
 						if ( !$( '#asmag-editornote-' + _noteId ).hasClass( 'no-comment' ) ) {
	 						$( '#asmag-editornote-' + _noteId ).addClass( 'no-comment' );
	 						$( '#asmag-editornote-' + _noteId ).contents().filter( function() {
	 							return this.nodeType === 3;
	 						}).remove();
	 					}
 					}
 				}

 				$( '#asmag-noteButtons > a' ).removeClass( 'disabled-button' );

 				if ( !_authorized.deleteNote ) {
					$( '#asmag-delNote' ).addClass( 'disabled-button' );
				}

				$( '#asmag-saveNote' ).find( '.icon_check_alt' ).show();
				$( '#asmag-saveNote' ).find( '.icon_loading' ).hide();

				_switchButtons( true );

 				handleMessage( settings.lang.editornoteview.messages.updated );
 			}
 		}

 		function _onDeleteNote( check ) {
 			if ( check ) {

 				removeSingleNote( 'asmag-editornote-' + _currentNote.id );

 				var _isCountingDone = _checkStatusPriority( _currentNote );
 				if ( _isCountingDone ) {
 					editorNotesUI.totalNotes--;
 					if ( editorNotesUI.totalNotes > 0 ) {
 						publishUpdatedCount();
 					} else {
 						defaultNoteCount();
 					} 					
 				}

 				$( '#asmag-noteButtons > a' ).removeClass( 'disabled-button' );
				$( '#asmag-delNote' ).find( '.icon_trash' ).show();
				$( '#asmag-delNote' ).find( '.icon_loading' ).hide();

				_switchButtons( true );

				if ( _currentWinW >= settings.editor_notes.min_tablet_view &&
					_currentWinW <= settings.editor_notes.max_tablet_view ) {

					if ( $( '#asmag-lostPanel' ).is( ':hidden' ) ) {
						$( '#magazine-wrapper' ).removeClass( 'with-panels' );
					}
				}

				if ( _currentWinW < settings.editor_notes.min_tablet_view ) {
					if ( $( '#asmag-lostPanel' ).is( ':visible' ) ) {
						$( '#magazine-wrapper' ).addClass( 'with-lost-mobi' );
					}
					$( '#magazine-wrapper' ).removeClass( 'with-editor-mobi' );
				}

 				handleMessage( settings.lang.editornoteview.messages.deleted );
 			}
 		}

 		if ( _currentWinW <= settings.editor_notes.max_tablet_view ) {

 			if ( $( '#asmag-lostPanel' ).is( ':visible' ) || $( '#asmag-editorPanel' ).is( ':visible' ) ) {
 				$( '#magazine-wrapper' ).addClass( 'with-panels' );
 			}

 			if ( _currentWinW >= settings.editor_notes.min_tablet_view ) {
 				$( '#asmag-editorPanel' ).removeClass( 'fix-right' );
 				$( '#asmag-editorPanel' ).addClass( 'fix-right-no-lost' );

 				if ( $( '#asmag-lostPanel' ).is( ':visible' ) ) {
 					$( '#asmag-lostPanel' ).addClass( 'fix-left');
 					$( '#asmag-editorPanel' ).removeClass( 'fix-right-no-lost' );
 					$( '#asmag-editorPanel' ).addClass( 'fix-right' );
 				} 				
 			}

 			if ( _currentWinW < settings.editor_notes.min_tablet_view ) {
 				$( '#magazine-wrapper' ).removeClass( 'with-panels' );

 				if ( $( '#asmag-lostPanel' ).is( ':visible' ) ) {
	 				$( '#magazine-wrapper' ).addClass( 'with-lost-mobi' );
	 			}

	 			if ( $( '#asmag-editorPanel' ).is( ':visible' ) ) {
	 				$( '#magazine-wrapper' ).removeClass( 'with-lost-mobi' );
	 				$( '#magazine-wrapper' ).addClass( 'with-editor-mobi' );
	 			}
 			}
 		}

 		$( window ).on( 'resize', function() {

 			_currentWinW = $( window ).width();

 			if ( _currentWinW <= settings.editor_notes.max_tablet_view ) { 				
	 			if ( $( '#asmag-lostPanel' ).is( ':visible' ) || $( '#asmag-editorPanel' ).is( ':visible' ) ) {
	 				$( '#magazine-wrapper' ).removeClass( 'with-lost-mobi' );
	 				$( '#magazine-wrapper' ).removeClass( 'with-editor-mobi' );
	 				$( '#magazine-wrapper' ).addClass( 'with-panels' );
	 			}

	 			if ( _currentWinW >= settings.editor_notes.min_tablet_view ) {
	 				if ( $( '#asmag-lostPanel' ).is( ':visible' ) ) {
	 					$( '#asmag-lostPanel' ).addClass( 'fix-left');
	 					$( '#asmag-lostPanel .display-notes' ).show();
	 					$( '#asmag-editorPanel' ).addClass( 'fix-right' );
	 				} else {
	 					$( '#asmag-editorPanel' ).removeClass( 'fix-right' );
	 					$( '#asmag-editorPanel' ).addClass( 'fix-right-no-lost' );
	 				}
	 				return;
	 			}

	 			if ( _currentWinW < settings.editor_notes.min_tablet_view ) {
	 				$( '#magazine-wrapper' ).removeClass( 'with-panels' );
	 				$( '#magazine-wrapper' ).removeClass( 'with-lost-mobi' );
	 				$( '#magazine-wrapper' ).removeClass( 'with-editor-mobi' );

	 				if ( $( '#asmag-lostPanel' ).is( ':visible' ) ) {
		 				$( '#magazine-wrapper' ).addClass( 'with-lost-mobi' );
		 			} 

		 			if ( $( '#asmag-editorPanel' ).is( ':visible' ) ) {
		 				$( '#magazine-wrapper' ).removeClass( 'with-lost-mobi' );
		 				$( '#magazine-wrapper' ).addClass( 'with-editor-mobi' );
		 			}
	 			}

	 		} else { // full width
	 			$( '#magazine-wrapper' ).removeClass( 'with-panels' );
	 			$( '#magazine-wrapper' ).removeClass( 'with-lost-mobi' );
		 		$( '#magazine-wrapper' ).removeClass( 'with-editor-mobi' );

	 			$( '#asmag-lostPanel' ).removeClass( 'fix-left' );
	 			$( '#asmag-editorPanel' ).removeClass( 'fix-right' );
	 			$( '#asmag-editorPanel' ).removeClass( 'fix-right-no-lost' );
	 		}
 		});

 		$( '.lost-title-mobi' ).on( 'click.asmag_onviewchanged', function() {
 			if ( $( '#asmag-lostPanel .display-notes' ).is( ':visible' ) ) {
 				$( '#asmag-lostPanel .display-notes' ).hide();
 			} else {
 				$( '#asmag-lostPanel .display-notes' ).show();
 			}
 		});

 		$( '.note-wrapper' ).each( function() {
 			
			$( this ).on( 'click.asmag_onviewchanged', function( e ) {

				var _item = $( this );
				if ( _item.hasClass( 'note-opened' ) ) {
					_item.removeClass( 'note-opened' );
					_item.addClass( 'note-marker' );
					unparsingDataOffPanel();
					$( '#asmag-editorPanel' ).hide();

					_noteUser = null;
					editorNotesUI.currentNote = null;

					if ( _currentWinW >= settings.editor_notes.min_tablet_view &&
						_currentWinW <= settings.editor_notes.max_tablet_view ) {

						if ( $( '#asmag-lostPanel' ).is( ':hidden' ) ) {
							$( '#magazine-wrapper' ).removeClass( 'with-panels' );
						}
					}

					if ( _currentWinW < settings.editor_notes.min_tablet_view ) {
						if ( $( '#asmag-lostPanel' ).is( ':hidden' ) ) {
							$( '#magazine-wrapper' ).removeClass( 'with-editor-mobi' );
						} else {
							$( '#magazine-wrapper' ).removeClass( 'with-editor-mobi' );
							$( '#magazine-wrapper' ).addClass( 'with-lost-mobi' );
						}						
					}

					e.preventDefault();
					e.stopPropagation();
					return;
				} 
				else if ( _item.hasClass( 'note-mask-opened' ) ) {
					_item.removeClass( 'note-mask-opened' );
					_item.addClass( 'note-mask' );
					unparsingDataOffPanel();
					$( '#asmag-editorPanel' ).hide();

					_noteUser = null;
					editorNotesUI.currentNote = null;

					if ( _currentWinW < settings.editor_notes.min_tablet_view ) {
						$( '#magazine-wrapper' ).removeClass( 'with-editor-mobi' );
						$( '#magazine-wrapper' ).addClass( 'with-lost-mobi' );						
					}

					e.preventDefault();
					e.stopPropagation();
					return;
				}
				else if ( _item.hasClass( 'note-marker' ) || _item.hasClass( 'note-mask' ) ) {
				// todo: make editor panel appears first with loading icon, instead of showing a short period of silence after user clicks a wrapper to view note
					var _numberId = parseInt( _item.attr( 'id' ).split( '-' )[2], 10 );
					editorNotes.ajaxFuncs.getEditorNoteById( _numberId, asteikkoIssue.id, _asmagPageId, _toggleWrapperPanel );
				}

				e.preventDefault();
				e.stopPropagation();
			});
		});

		$( document ).on( 'click.asmag_onviewchanged', function() {
			$( '#asmag-statusList' ).hide();
			$( '#asmag-addNoteButton' ).hide();
		});

		$( '#asmag-statusList' ).find( 'span' ).each( function() {
			$( this ).on( 'click.asmag_onviewchanged', function( e ) {

				var _statusDefaults = window.settings.editor_notes.editornoteview.status_list;

				var _activeStatus = $.trim( _currentNote.status.toLowerCase() );
				$( '#asmag-statusList' ).find( 'span[name="' + _statusDefaults[_activeStatus].class_key + '"]' ).show();
				$( this ).hide();

				var _pick = $.trim( $(this).attr( 'name' ).toLowerCase() );
				$( '#asmag-status-close > .status-dropdown' ).find( 'span' ).text( _statusDefaults[_pick].translation );
				$( '#asmag-status-close > .status-dropdown' ).css( 'background-color', '#' + _statusDefaults[_pick].background );

				_currentNote.status = _statusDefaults[_pick].class_key;

				$( '#asmag-statusList' ).slideUp( 10 );
				e.stopPropagation();
			});
		});

		$( '#asmag-status-close > .status-dropdown' ).on( 'click.asmag_onviewchanged', function( e ) {
			if ( $(this).hasClass( 'disabled-button' ) ) {
				return;
			}
			
			if ( $( '#asmag-statusList' ).is( ':hidden' ) ) {
				$( '#asmag-statusList' ).slideDown( 10 );
			} else {
				$( '#asmag-statusList' ).slideUp( 10 );
			}
			// This is to stop event from catching click function on document
			e.stopPropagation();
		});

 		$( '.note-close-button' ).on( 'click.asmag_onviewchanged', function( e ) {
 			if ( $(this).hasClass( 'disabled-button' ) ) {
				return;
			}
			
 			var _openPanelData = $( '#asmag-editorPanel' ).attr( 'note-data' );

 			if ( $( '#' + _openPanelData ).hasClass( 'note-mask-opened' ) ) {
 				$( '#' + _openPanelData ).removeClass( 'note-mask-opened' );
 				$( '#' + _openPanelData ).addClass( 'note-mask' );
 			} else {
 				$( '#' + _openPanelData ).removeClass( 'note-opened' );
 				$( '#' + _openPanelData ).addClass( 'note-marker' );
 			}
 			
 			unparsingDataOffPanel();
			$( '#asmag-editorPanel' ).hide();
			editorNotesUI.currentNote = null;

			if ( _currentWinW >= settings.editor_notes.min_tablet_view &&
				_currentWinW <= settings.editor_notes.max_tablet_view ) {

				if ( $( '#asmag-lostPanel' ).is( ':hidden' ) ) {
					$( '#magazine-wrapper' ).removeClass( 'with-panels' );
				}
			}

			if ( _currentWinW < settings.editor_notes.min_tablet_view ) {
				if ( $( '#asmag-lostPanel' ).is( ':visible' ) ) {
					$( '#magazine-wrapper' ).addClass( 'with-lost-mobi' );
				}
				$( '#magazine-wrapper' ).removeClass( 'with-editor-mobi' );
			}
			e.stopPropagation();
		});

		$( '.inArea' ).on( 'focus.asmag_onviewchanged', function() {
	      $( '.outArea' ).addClass( 'comment-focus' );
	    });

	    $( '.inArea' ).on( 'blur.asmag_onviewchanged', function() {
	      $( '.outArea' ).removeClass( 'comment-focus' );
	    });

	    $( '.inArea' ).on( 'input.asmag_onviewchanged', function() {
	      	_currentNote.comment = $( this ).val();
	    });

	    $( '.inArea' ).on( 'keyup.asmag_onviewchanged', function( e ) {
	    	if ( e.which === 37 || e.which === 39 ) {
	    		e.stopPropagation();
	    	}
	    });

	    $( '#asmag-delNote' ).on( 'click.asmag_onviewchanged', function( e ) {
			// console.log(_currentNote);
			if ( $(this).hasClass( 'disabled-button' ) ) {
				return;
			}

			if ( $( '#asmag-statusList' ).is( ':visible' ) ) {
				$( '#asmag-statusList' ).hide();
			}
			
			_switchButtons( false );

			$( '#asmag-noteButtons > a' ).addClass( 'disabled-button' );
			$( this ).find( '.icon_trash' ).hide();
			$( this ).find( '.icon_loading' ).show();
			editorNotes.ajaxFuncs.removeEditorNote( _currentNote, _onDeleteNote );
			e.stopPropagation();
		});

		$( '#asmag-saveNote' ).on( 'click.asmag_onviewchanged', function( e ) {
			// console.log(_currentNote);
			if ( $(this).hasClass( 'disabled-button' ) ) {
				return;
			}

			if ( $( '#asmag-statusList' ).is( ':visible' ) ) {
				$( '#asmag-statusList' ).hide();
			}
			
			_switchButtons( false );

			if ( $( '#asmag-delNote' ).hasClass( 'disabled-button' ) ) {
				$( this ).addClass( 'disabled-button' );
			} else {
				$( '#asmag-noteButtons > a' ).addClass( 'disabled-button' );
			}

			$( this ).find( '.icon_check_alt' ).hide();
			$( this ).find( '.icon_loading' ).show();
			editorNotes.ajaxFuncs.updateEditorNote( _currentNote, _onSaveNote );
			e.stopPropagation();
		});
 	}

 	function terminateNoteFuncs( elem ) {

 		$( elem ).off( "asmag_editornoteschanged" );

 		terminateAllWrappers();
 		makeDomBackToNormal( elem );

 		$( elem ).find( '.asmag-page' ).each( function() {
 			$( this ).off();
 		});

 		$( '*[note-view="editorNotes"]' ).off( '.asmag_onviewchanged' );
 		$( document ).off( '.asmag_onviewchanged' );

 		if ( !$( '#asmag-admin-bar .note-counts' ).hasClass( 'editornote-hidden' ) ) {
			$( '#asmag-admin-bar .note-counts' ).addClass( 'editornote-hidden' );
			defaultNoteCount();
		}

 		editorNotesUI.currentPageId = null;
 	}

 	function initializeNoteFuncs( elem ) {

 		editorNotesUI.currentPageId = pages[ currentIndex ].id;
 		editorNotesUI.currentNote = null;
 		editorNotesUI.selectedContainer = null;

 		if ( $( '#asmag-admin-bar .note-counts' ).hasClass( 'editornote-hidden' ) ) {
			$( '#asmag-admin-bar .note-counts' ).removeClass( 'editornote-hidden' );
		}

 		initializeFetchingNotesByPage();
 		makeDomResponsiveForNotes( elem );
 		initializeNoteWrapper();

 		$( elem ).on( "asmag_editornoteschanged", function( event, selected, asmagPageId ) {

	    	initializeNoteCreatedInDB( selected, asmagPageId );
	    } );
 	}

 	editorNotesUI.init = function( elem ) {
 		
 		editorNotesUI.currentUser = null;

 		function _initCallback( userInfo ) {
 			if ( editorNotesUI.isAllowedToRun ) {
 				editorNotesUI.currentUser = userInfo;
 				initializeNoteFuncs( elem );
 			}
 			return;
 		}

 		Asteikko.getUserInfo( function( userInfo ) {
 			_initCallback( userInfo );
 		});
 	};

 	editorNotesUI.deactivateEditorNotes = function( elem ) {
 		if ( !editorNotesUI.isAllowedToRun ) {
 			terminateNoteFuncs( elem );
 			editorNotesUI.currentUser = null;
 		}
 	};

 	editorNotesUI.activateDefaultView = function( elem ) {

 		if ( editorNotesUI.isAllowedToRun ) {
 			editorNotesUI.isAllowedToRun = false;

 			$( '#asmag-issue #magazine-wrapper' ).removeClass( 'editornote-noleft' );

 			handleMessage( settings.lang.editornoteview.messages.default_active );

	 		editorNotesUI.deactivateEditorNotes( elem );

	 		$( window ).off( 'pageShown.editornoteview' );
	 		$( window ).on( 'pageShown.defaultview', function() {
				terminateNoteFuncs( '#mag' );
			});
 		}
 		return;
 	};

	editorNotesUI.activateEditorNoteView = function( elem ) {

		if ( !editorNotesUI.isAllowedToRun ) {
			editorNotesUI.isAllowedToRun = true;

			if ( $( 'body' ).hasClass( 'adminbar-active' ) ) {
				$( '#asmag-issue #magazine-wrapper' ).addClass( 'editornote-noleft' );
			}

			handleMessage( settings.lang.editornoteview.messages.note_active );

			if ( $( '#asmag-admin-bar .note-counts' ).hasClass( 'editornote-hidden' ) ) {
				$( '#asmag-admin-bar .note-counts' ).removeClass( 'editornote-hidden' );
			}

			editorNotesUI.init( elem );

			$( window ).on( 'pageShown.editornoteview', function() {
				terminateNoteFuncs( '#mag' );
				initializeNoteFuncs( '#mag' );
			});
		}
		return;
	};


} )( jQuery );
