
if ( !window.Asteikko ) window.Asteikko = {};
if ( !Asteikko.widgets ) Asteikko.widgets = {};

Asteikko.widgets.search = {

  _months: ['Tammikuu','Helmikuu','Maaliskuu','Huhtikuu','Toukokuu','Kesäkuu','Heinäkuu','Elokuu','Syyskuu','Lokakuu','Marraskuu','Joulukuu'],

  _initWidget: function( widgetElem ) {

    var widgetObj = Object.create( this._widgetProto );

    widgetObj.widgetElem = widgetElem;
    widgetObj.searchInput = widgetElem.find( '.searchField input' );
    widgetObj.searchClear = widgetElem.find( '.searchControl .searchClear' );
    widgetObj.searchProgress = widgetElem.find( '.searchControl .searchProgress' );
    widgetObj.textChangeTimeout = null;
    widgetObj.pageData = [];
    widgetObj.lastInput = '';
    widgetObj.resultsPerPage = parseInt( widgetElem.data( 'results-per-page' ), 10 ) || 10;
    widgetObj.contextSize = parseInt( widgetElem.data( 'context-size' ), 10 ) || 0;
    widgetObj.issueIds = $.trim( widgetElem.data( 'issues' ) || '' ).split( /\s*,\s*/ ).map( parseFloat ).filter( parseFloat );
    widgetObj.currentPage = 0;
    widgetObj.jumpTo = null;
    widgetObj.jumpPadding = 30;

    // 'this' always points to the widget object
    widgetObj.onGetResults = $.proxy( widgetObj.onGetResults, widgetObj );
    widgetObj.tooShortStringWarning = $.proxy( widgetObj.tooShortStringWarning, widgetObj );
    widgetObj.searchForCurrentInput = $.proxy( widgetObj.searchForCurrentInput, widgetObj );
    widgetObj.onClickSearchResult = $.proxy( widgetObj.onClickSearchResult, widgetObj );
    widgetObj.onClickNextPage = $.proxy( widgetObj.onClickNextPage, widgetObj );
    widgetObj.onClickPrevPage = $.proxy( widgetObj.onClickPrevPage, widgetObj );

    widgetObj.searchInput.change( $.proxy( widgetObj.onChangeInput, widgetObj ) );
    widgetObj.searchInput.keyup( $.proxy( widgetObj.onChangeInput, widgetObj ) );
    widgetObj.searchInput.bind( 'input', $.proxy( widgetObj.onChangeInput, widgetObj ) );

    widgetObj.searchClear.click( $.proxy( widgetObj.clearInput, widgetObj ) );

    widgetElem.find( '.searchDropdownWrapper').on('click', function() {
      widgetElem.find( '.searchDropdown').toggle();
      widgetElem.find( '.searchDropdownWrapper').toggleClass('active');
    });
  },

  _widgetProto: {

    searchForCurrentInput: function() {

      this.searchClear.hide();
      this.searchProgress.show();

      var thisRequestSearchText = this.searchInput.val();
      AsteikkoAnalytics.sendEvent( 'System', 'Search issue', '/issues/'+ thisRequestSearchText );

      var issues = ( !this.issueIds.length ) ? undefined : this.issueIds;

      Asteikko.search( thisRequestSearchText, this.onGetResults, issues, 
        this.currentPage * this.resultsPerPage, this.resultsPerPage, this.contextSize );
    },

    onGetResults: function( search ) {

      this.searchProgress.hide();
      this.searchClear.show();

      if ( search.request.query !== this.lastInput )
        return;
      this.renderResults( search );
    },

    renderResults: function( search ) {

      var results = search.results;
      var resultsElem = this.widgetElem.find( '.searchResults' );
      resultsElem.empty();
    
      if ( results.length ) {
        for ( var j = 0; j < results.length; j++ ) {

          /* Parse year data, date’[0] is year, [1] month */
          var date = new Date( results[ j ].issue_publish_date * 1000 );
          var thisResultElem = $('<div class="searchResult" data-issueid="' + results[ j ].issue + '" data-pageid="' + results[ j ].page + '"></div>').click( this.onClickSearchResult );

          if ( results[ j ].page_title )
            $('<span>' + results[ j ].page_title + '</span>').addClass('searchResultTitle').appendTo( thisResultElem );

          if ( results[ j ].page_excerpt )
            $('<span>' + results[ j ].page_excerpt + '</span>').addClass('searchResultExcerpt').appendTo( thisResultElem );

          var issueElem = $('<span>').addClass('searchResultIssue').html( results[ j ].issue_name ).appendTo( thisResultElem );
          // Removed for clearer input, left for future use
          var dateElem = $('<span>').addClass('searchResultDate').html( date.getDate() +'.'+ ( date.getMonth() + 1 ) +'.'+ date.getFullYear() ).appendTo( thisResultElem );
          if ( results[ j ].context && results[ j ].context.length && results[ j ].context[ 0 ] ) {
            var contextText = results[ j ].context[ 0 ].replace( /^([A-Za-zåäöÅÄÖ\d]+?[^A-Za-zåäöÅÄÖ\d]){0,2}/, '' );
            $( '<span class="searchResultContext">&hellip;'+ contextText +'&hellip;</span>' )
              .appendTo(thisResultElem);
          }
          resultsElem.append( thisResultElem );
        }

        if ( this.resultsPerPage < search.total_size ) {
          this.widgetElem.find('.searchPages').html('');
          var pageCount = Math.ceil( search.total_size / this.resultsPerPage );
          var pagesElem = $( '<div class="searchPages"></div>' );
          if ( this.currentPage !== 0 ) {
            var prevElem = $( '<span class="prevPage">Edellinen sivu</span>' );
            prevElem.click( this.onClickPrevPage );
            pagesElem.append( prevElem );
          }
          if ( this.currentPage < pageCount - 1 ) {
            var nextElem = $( '<span class="nextPage">Seuraava sivu</span>' );
            nextElem.click( this.onClickNextPage );
            pagesElem.append( nextElem );
          }
          resultsElem.append( pagesElem );
        }
      } else {
        resultsElem.append("<div class='noResult'>Haullasi ei löytynyt tuloksia</div>");
      }
    },

    onClickSearchPage: function( event ) {
      event.stopPropagation();
      this.currentPage = parseInt( $( this ).attr( 'data-page' ), 10 );
      this.widgetElem.find( '.searchResults' ).empty();
      this.searchForCurrentInput();
    },

    onClickPrevPage: function( event ) {
      event.stopPropagation();
      this.currentPage--;
      this.widgetElem.find( '.searchResults' ).empty();
      this.searchForCurrentInput();
    },

    onClickNextPage: function( event ) {
      event.stopPropagation();
      this.currentPage++;
      this.widgetElem.find( '.searchResults' ).empty();
      this.searchForCurrentInput();
    },

    onClickSearchResult: function( event ) {

      var pageId = $( event.target ).closest(".searchResult").attr( 'data-pageid' );
      var issueId = $( event.target ).closest(".searchResult").attr( 'data-issueid' );
      if ( !pageId || !issueId )
        return;

      this.widgetElem.find(".activeResult").removeClass("activeResult");
      $(event.target).addClass("activeResult");

      // Switch to selected
      if ($(event.target).hasClass("searchResultContext")) {
        this.jumpTo = $(event.target).html();
      } else {
        this.jumpTo = null;
      }

      // We're in web app
      if ( !Asteikko.env.client_name )
        window.open( "#!/issue/"+ parseInt( issueId, 10 ) +"/"+ parseInt( pageId, 10 ) );
      // We're in an Asteikko Native app
      else
        Asteikko.loadMagazine( parseInt( issueId, 10 ), parseInt( pageId, 10 ) );
    },

    tooShortStringWarning: function() {
      this.widgetElem.find( '.searchResults' ).append("<div class='noResult'>Valitsemasi hakusana on liian lyhyt</div>");
    },

    onChangeInput: function( event ) {

      this.currentPage = 0;
      var newVal = this.searchInput.val();

      if ( newVal === this.lastInput ) return;
      this.lastInput = newVal;
      clearTimeout( this.textChangeTimeout );

      this.searchProgress.hide();

      if ( newVal.length > 0 ) {
        this.searchClear.show();
      } else {
        this.searchClear.hide();
      }

      if ( newVal.length < 3 ) {
        this.widgetElem.find( '.searchResults' ).empty();
        if (newVal.length > 0) {
          this.textChangeTimeout = setTimeout( this.tooShortStringWarning, 2000 );
        }
        return;
      }
      this.textChangeTimeout = setTimeout( this.searchForCurrentInput, 500 );
    },

    clearInput: function() {
      this.searchInput.val("");
      this.onChangeInput();
      this.searchInput.focus();
    }
  },

  init: function() {

    // Initialize search widget event handlers when clicking on their input 
    // fields the first time. This is done so that the client code doesn't have
    // to care about JS initialization, and only needs to include the widget
    // HTML on a page (e.g. via AJAX).
    $( 'body' ).on( 'click', '.asmag-widget-search input', function() {

      var widget = $( this ).closest( '.asmag-widget-search' );
      if ( widget.data( 'initialized' ) )
        return;

      widget.data( 'initialized', true );

      Asteikko.widgets.search._initWidget( widget );
    } );
  }
};
