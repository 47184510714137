( function() {
  
  "use strict";

  AsteikkoPath.createStateTransition( 
    '', 
    '#/issue/:issueid(/:pageid)'
  ).on( function( params ) {

      var issueId = parseInt( params.issueid, 10 );
      var pageId = parseInt( params.pageid, 10 );

      if ( issueId ) {
        if ( Asteikko.online )
          handleOnlineTransition( issueId, pageId );
        else
          handleOfflineTransition( issueId, pageId );
      }
    } );

  function handleOnlineTransition( issueId, pageId ) {
  
    Asteikko.warn( '#/issue/<issue ID>/<page ID> paths are deprecated since Asteikko Magazine 5.2.0. Use issue and page permalinks instead.' );
  
    if ( window.asteikkoIssue && asteikkoIssue.id == issueId ) {
      if ( pageId )
        goToPageById( pageId );
    }
    else if ( pageId ) {
      // Fix the current URL so that pressing the back-button won't 
      // navigate straight back to the issue
      Asteikko.history.replaceState( '', '', window.location.href.replace( /#.*$/, '' ) );
      window.location.href = Asteikko.env.site_url +'/asmag/idtopermalinkredirect/?issue='+ issueId +'&page='+ pageId;
    }
    else {
      // Fix the current URL so that pressing the back-button won't 
      // navigate straight back to the issue
      Asteikko.history.replaceState( '', '', window.location.href.replace( /#.*$/, '' ) );
      window.location.href = Asteikko.env.site_url +'/asmag/idtopermalinkredirect/?issue='+ issueId;
    }
  }

  function handleOfflineTransition( issueId, pageId ) {

    if ( window.asteikkoIssue && asteikkoIssue.id == issueId ) {
      if ( pageId )
        goToPageById( pageId );
    }
    else {
      Asteikko.loadMagazine( issueId, pageId );
    }
  }
  
} )();
