(function($) {
  $.fn.trayMenu = function(options) {
    if (typeof options !== "undefined" && options &&
        typeof options.list !== "undefined" && options.list instanceof Array &&
        options.list.length > 0) {

      var settings = $.extend({
        maxWidth: 475,
        maxPercentage: 90,
        minWidth: 380,
        headerHeight: 75,
        headerSlimHeight: 75,
        contentHeaderHeight: 50,
        listPercentage: 30,
        activeItem: 0,
        stopEventPropagation: false
      }, options);

      if (deviceDetect.getOS() === "windowsphone") {
        settings.stopEventPropagation = true;
      }

      return this.each(function() {
        var $this = $(this);

        if (!$this.hasClass("trayMenu")) {
          $this.addClass("trayMenu");
        }
        if (!$this.hasClass("closed")) {
          $this.addClass("closed");
        }
        $this.hide();
        $this.removeClass("slimMode fullMode open");

        if (settings.stopEventPropagation) {
          $this.click(function(e) {e.stopPropagation();});
        }

        $this.html("<div class='trayMenuContainer' style='position: relative; height: 100%'></div>");

        $this.find(".trayMenuContainer")
          .append("<div class='trayMenuHeader' style='position: absolute; width: 100%;'>" +
                  "<div class='trayMenuBack clickdelay' style='position: absolute; z-index: 5;'></div><div " +
                  "class='trayMenuHeaderContent' style='position: " +
                  "absolute; right: 0; top: 0; height: 100%;'></div></div>")
          .find(".trayMenuBack").click(menuBack);

        if (settings.headerHtml !== "undefined" && settings.headerHtml) {
          $this.find(".trayMenuHeaderContent").html(settings.headerHtml);
        }
        if (settings.headerUrl !== "undefined" && settings.headerUrl) {
          $this.find(".trayMenuHeaderContent").load(settings.headerUrl);
        }

        $this.find(".trayMenuContainer")
          .append("<div class='trayMenuBody' style='position: absolute; top: 0; bottom: 0;'>" +
                  "<div class='trayMenuList' style='float: left; height: 100%;'>" +
                  "<ul></ul></div><div class='trayMenuContentFrame' style='" +
                  "position: relative; float: left; height: 100%; overflow: hidden; " +
                  "'><div class='trayMenuContentHeader' style='" +
                  "position: absolute; line-height: " + settings.contentHeaderHeight +
                  "px; width: 100%; top: 0;'></div><div class='trayMenuContent' " +
                  "style='position: absolute; width: 100%; bottom: 0; overflow-x: hidden; " +
                  "overflow-y: auto;'></div></div>");

        for (var i = 0; i < settings.list.length; i++) {
          if (typeof settings.list[i].label === "string" && settings.list[i].label &&
              ((typeof settings.list[i].contentHtml === "string" && settings.list[i].contentHtml) ||
              (typeof settings.list[i].contentUrl === "string" && settings.list[i].contentUrl))) {
            var classes = "trayMenuItem";
            if (typeof settings.list[i].classes === "string" && settings.list[i].classes) {
              classes += " " + settings.list[i].classes;
            }
            $this.find(".trayMenuList ul")
              .append("<li class='" + classes + "'>" + settings.list[i].label + "</li>")
              .find(".trayMenuItem").last().click(itemClicked);
            $this.find(".trayMenuContentHeader")
              .append("<div class='trayMenuContentHeaderItem' style='position: absolute; " +
                      "top: 0; right: 0; bottom: 0; left: 0;'>" + settings.list[i].label + "</div>");
            var trayMenuContentItem = $this.find(".trayMenuContent")
              .append("<div class='trayMenuContentItem trayMenuLoading'></div>")
              .find(".trayMenuContentItem")
              .last();
            if (typeof settings.list[i].contentHtml === "string" && settings.list[i].contentHtml) {
              trayMenuContentItem.html(settings.list[i].contentHtml);
              trayMenuContentItem.removeClass("trayMenuLoading");
            }
            if (typeof settings.list[i].contentUrl === "string" && settings.list[i].contentUrl) {
              trayMenuContentItem.load(settings.list[i].contentUrl, function() {
                  $(this).removeClass("trayMenuLoading");
              });
            }
          }
        }

        function itemClicked() {
          var $this = $(this);
          if (!$this.hasClass("active")) {
            $this.siblings().removeClass("active");
            $this.addClass("active");

            var index = $this.prevAll().length;
            var trayMenu =  $this.closest('.trayMenu');
            var trayMenuBody = $this.closest('.trayMenuBody');
            var contentHeader = trayMenuBody.find(".trayMenuContentHeaderItem").eq(index);
            var content = trayMenuBody.find(".trayMenuContentItem").eq(index);

            if (contentHeader.length > 0) {
              contentHeader.siblings().hide();
              contentHeader.show();
            }

            if (content.length > 0) {
              content.siblings().hide();
              content.show();
            }

            if (trayMenu.hasClass('slimMode')) {
              trayMenu.find(".trayMenuBack").removeClass("close");
              if (typeof trayMenuBody.transit !== "undefined") {
                trayMenuBody.transit({x: "-50%"});
              } else {
                trayMenuBody.css({"left": "auto", "right": "0"});
              }
            }
          }
        }

        function menuBack() {
          var $this = $(this);
          var trayMenu =  $this.closest('.trayMenu');
          var trayMenuBody = trayMenu.find('.trayMenuBody');
          trayMenuBody.find(".trayMenuItem").removeClass("active");
          if (trayMenu.hasClass('slimMode')) {
            if ($this.hasClass("close")) {
              trayMenu.closeTray();
            } else {
              if (typeof trayMenuBody.transit !== "undefined") {
                trayMenuBody.transit({x: 0});
              } else {
                trayMenuBody.css({"left": "0", "right": "auto"});
              }
              $this.addClass("close");
            }
          }
        }

        function windowResized() {
          var width = Math.min(settings.maxWidth,
                               settings.maxPercentage * window.innerWidth / 100);

          if (width < settings.minWidth) {
            width = window.innerWidth;

            if (!$this.hasClass("slimMode")) {
              $this.removeClass("fullMode");
              $this.addClass("slimMode");

              $this.find(".trayMenuHeader").css({"height": settings.headerSlimHeight + "px"});
              var trayMenuBody = $this.find(".trayMenuBody");
              trayMenuBody.attr("style", "position: absolute; bottom: 0; width: 200%; top: " +
                                settings.headerSlimHeight + "px");
              $this.find(".trayMenuItem.active").removeClass("active");
              $this.find(".trayMenuBack").addClass("close");
              $this.find(".trayMenuList").css({"width": "50%"});
              $this.find(".trayMenuContentFrame").css({"width": "50%"});
              $this.find(".trayMenuContentHeader").css({"height": settings.contentHeaderHeight + "px"});
              $this.find(".trayMenuContent").css({"top": settings.contentHeaderHeight + "px"});
              $this.find(".trayMenuBack").show();
            }
          } else if (width >= settings.minWidth && !$this.hasClass("fullMode")) {
            $this.removeClass("slimMode");
            $this.addClass("fullMode");

            var headerHeight = 0;
            if ( (settings.headerUrl !== "undefined" && settings.headerUrl) 
              || (settings.headerHtml !== "undefined" && settings.headerHtml) ) {
              headerHeight = settings.headerHeight;
            }
            $this.find(".trayMenuHeader").css({"height": headerHeight + "px"});
            $this.find(".trayMenuBody").attr("style",
              "position: absolute; bottom: 0; width: 100%; top: " +
              headerHeight + "px");
            var activeContent = $this.find(".trayMenuContentItem:visible");
            if (activeContent.length > 0) {
              $this.find(".trayMenuItem").eq(activeContent.prevAll().length).addClass("active");
            }
            $this.find(".trayMenuList").css({"width": settings.listPercentage + "%"});
            $this.find(".trayMenuContentFrame").css({"width": (100 - settings.listPercentage) + "%"});
            $this.find(".trayMenuContentHeader").css({"height": "0"});
            $this.find(".trayMenuContent").css({"top": "0"});
            $this.find(".trayMenuBack").hide();
          }

          $this.css({"width": width + "px"});
        }

        var resizeTimeout;

        $(window).on("resize", function() {
          window.clearTimeout( resizeTimeout );
          resizeTimeout = window.setTimeout( windowResized, 200 );
        } );

        /* Initialize */
        $this.find(".trayMenuItem").eq(settings.activeItem).trigger('click');
        $this.css({"position": "absolute", "overflow": "hidden",
                  "left": "100%", "top": "0", "right": "auto", "height": "100%"});
        windowResized();
      });
    } else {
      return this;
    }
  };

  $.fn.openTray = function(options) {
    return this.each(function() {
      var $this = $(this);
      if($this.hasClass("trayMenu") && !$this.hasClass("open")) {

        $this.show();
        $this.removeClass("closed");
        $this.addClass("open");

        if (typeof options !== "undefined" && typeof options.activeItem === "number") {
          $this.find(".trayMenuItem").eq(options.activeItem).trigger('click');
        }

        var noTransition = false;
        if (deviceDetect.getOS() === "windowsphone") {
          noTransition = true;
        }
        if (typeof $this.transit !== "undefined" && !noTransition) {
          $this.transit({x: "-100%"}, function() {
            $this.trigger("trayOpened");
          });
          // Firefox rendering bug: the trayMenu doesn't appear until we've 
          // resized the browser window. The code below seems to fix it...
          $this.css( 'overflow', 'visible' );
          /* jshint ignore:start */
          $this.get(0).offsetHeight; // Force redraw
          $this.css( 'overflow', 'hidden' );
          $this.get(0).offsetHeight; // Force redraw
          /* jshint ignore:end */
        } else {
          $this.css({"left": "auto", "right": "0"});
          $this.trigger("trayOpened");
        }
      }
    });
  };

  $.fn.closeTray = function() {
    return this.each(function() {
      var $this = $(this);
      if($this.hasClass("trayMenu") && !$this.hasClass("closed")) {
        $this.removeClass("open");
        $this.addClass("closed");

        var noTransition = false;
        if (deviceDetect.getOS() === "windowsphone") {
          noTransition = true;
        }
        if (typeof $this.transit !== "undefined" && !noTransition) {
          $this.transit({x: 0}, function() {
            $this.trigger("trayClosed");
            $this.hide();
          });
        } else {
          $this.css({"left": "100%", "right": "auto"});
          $this.trigger("trayClosed");
          $this.hide();
        }
      }
    });
  };
})(jQuery);
