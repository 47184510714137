/**
 * Removes built-in onclick delay (300ms) from iOS.
 */
function NoClickDelay(el) {

    if ( !el )
        return;

    var isWinPhone = navigator.userAgent.indexOf("Windows Phone") !== -1;
    var isIOS = /iP(ad|hone|od)/.test(navigator.userAgent) && !isWinPhone;

    if ( !isIOS )
        return;

    this.element = typeof el == 'object' ? el : document.getElementById(el);
    if (window.Touch && !$(this.element).data('asmag_clickdelay')) {
      $(this.element).data('asmag_clickdelay', true);
      this.element.addEventListener('touchstart', this, false);
    }
}
NoClickDelay.prototype = {
    handleEvent: function (e) {
        switch (e.type) {
        case 'touchstart':
            this.onTouchStart(e);
            break;
        case 'touchmove':
            this.onTouchMove(e);
            break;
        case 'touchend':
            this.onTouchEnd(e);
            break;
        }
    },
    onTouchStart: function (e) {
        e.preventDefault();
        this.moved = false;
        this.theTarget = document.elementFromPoint(e.targetTouches[0].clientX, e.targetTouches[0].clientY);
        if (this.theTarget.nodeType == 3) this.theTarget = theTarget.parentNode;
        this.theTarget.className += ' pressed';
        this.element.addEventListener('touchmove', this, false);
        this.element.addEventListener('touchend', this, false);
    },
    onTouchMove: function (e) {
        this.moved = true;
        this.theTarget.className = this.theTarget.className.replace(/ ?pressed/gi, '');
    },
    onTouchEnd: function (e) {
        this.element.removeEventListener('touchmove', this, false);
        this.element.removeEventListener('touchend', this, false);
        if (!this.moved && this.theTarget) {
            this.theTarget.className = this.theTarget.className.replace(/ ?pressed/gi, '');
            var theEvent = document.createEvent('MouseEvents');
            theEvent.initEvent('click', true, true);
            this.theTarget.dispatchEvent(theEvent);
        }
        this.theTarget = undefined;
    }
};