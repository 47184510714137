/*******************************************************************************
  Upgrading client local state to the latest system version.
*******************************************************************************/

if ( !window.Asteikko ) window.Asteikko = {};

Asteikko.upgrade = ( function() {

  "use strict";

  /**
   * Normalizes a version string (e.g. '5.0.0') into an integer, so that we can 
   * easily compare version strings with different amount of characters, like 
   * '5.0.123' and '6.0.1'.
   */
  function versionToInt( systemVersionStr ) {

    if ( typeof systemVersionStr !== 'string' && typeof systemVersionStr !== 'number' )
      return 0;

    var nr = '';
    var parts = systemVersionStr.split( '.' );
    // Version string must have 3 parts
    if ( parts.length < 3 ) {
      for ( var i = parts.length; i < 3; i++ )
        parts.push( '0' );
    }
    // Max supported version number is '999.999.999', i.e. 3 digits per part
    for ( var i = 0; i < parts.length; i++ ) {
      // Right-pad all parts with zeroes
      for ( var j = parts[ i ].length; j < 3; j++ )
        parts[ i ] = '0'+ parts[ i ];
      nr += parts[ i ];
    }
    
    return parseInt( nr, 10 );
  }

  function do522Upgrades() {

    // Remove all the old asmag items, because in 5.2.2 the items will be 
    // compressed with LZString
    try {
      for ( var i in window.localStorage ) {
        if ( /^asmag_/.test( i ) )
          window.localStorage.removeItem( i );
      }
    }
    catch ( e ) {
      Asteikko.warn( e );
    }
  }

  function do523Upgrades() {

    try {
      // Remove some old caches that weren't removed in 5.2.2
      for ( var i in window.localStorage ) {
        if ( /^custom-(css|js)/.test( i ) || i === 'issues' )
          window.localStorage.removeItem( i );
      }
    }
    catch ( e ) {
      Asteikko.warn( e );
    }
  }

  function do526Upgrades() {

    // A bug in 5.2.5 Asteikko.resourceDownloader created multiple localStorage 
    // items per one URL
    try {
      for ( var i in window.localStorage ) {
        if ( /^as-(css|js).+_cachebust=/.test( i ) )
          window.localStorage.removeItem( i );
      }
    }
    catch ( e ) {
      Asteikko.warn( e );
    }
  }

  var ret = {};

  ret.doUpgrades = function( newestVersion ) {

    var currVersion = Asteikko.localStorage.get( 'asmag_system_version' );

    Asteikko.log( 'Newest system version: '+ newestVersion );
    Asteikko.log( 'Current system version: '+ currVersion );

    try {

      var currVersionInt = versionToInt( currVersion );

      if ( currVersionInt < versionToInt( '5.2.2' ) )
        do522Upgrades();
      if ( currVersionInt < versionToInt( '5.2.3' ) )
        do523Upgrades();
      if ( currVersionInt < versionToInt( '5.2.6' ) )
        do526Upgrades();
    }
    catch ( e ) {
      Asteikko.error( e );
    }

    Asteikko.localStorage.set( 'asmag_system_version', newestVersion );
  };

  return ret;

} )();
