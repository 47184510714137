/*
 * Copyright Aste Helsinki Oy.
 */

jQuery.fn.zoomable = function( cmd ) {

  if ( cmd === 'destroy' ) {
    this.each( function() {
      var zoomable = $( this ).data( 'zoomable' );
      if ( zoomable ) {
        zoomable.destroy();
      }
    } );
  }
  else {

    this.each( function() {
      if ( $( this ).data( 'zoomable' ) ) {
        var zoomable = $( this ).data( 'zoomable' );
        zoomable.refresh();
        return;
      }

      var zoomable = init( this );
      $( this ).data( 'zoomable', zoomable );
    } );
  }

  function init( zoomElem ) {

    /* Setting parameters */
    var hasTouch = asmagModernizr.touchevents || deviceDetect.getOS() === 'windowsphone';
    var horizontalScroller = (typeof pageScroller == "undefined" || !hasTouch) ? null : pageScroller;
    var isSwitcherPageChanged = false;
    var MIN_ZOOM = 1;
    var MAX_ZOOM = 1;

    /* General variables */
    var vendorPrefixes = ["", "-webkit-", "-moz-", "-o-", "-ms-", "-khtml-"];
    var content = $( zoomElem ).eq( 0 );

    // For computeMaxZoom(). If the page content has any text content (aside from
    // <style> and <script> contents), we consider the page to belong to a
    // PDF-lookalike issue, where the page only includes an <img> of a PDF page.
    // This variable allows us to do some fine-tuning of the MAX_ZOOM level.
    var isPdfLookalikeArticleIssue = ( $.trim( content.find( '*' ).not( 'script, :has(script), style, :has(style)' ).text() ) ) === '';

    function computeMaxZoom() {

      MAX_ZOOM = ( 1600 / window.innerHeight ) * 1.2;

      // Special case for article templates that show only one zoomable image,
      // e.g. in PDF-lookalike issues. Get the desired max zoom from the image's
      // current shown size, not the whole page size, as the page can be a lot
      // taller than the image.
      if ( isPdfLookalikeArticleIssue ) {
        var imgHeight = content.find( 'img' ).first().height();
        if ( imgHeight ) {
          var maxZoomBasedOnImgHeight = ( 1600 / imgHeight ) * 1.2;
          MAX_ZOOM = Math.max( MAX_ZOOM, maxZoomBasedOnImgHeight );
        }
      }
    }

    var expDecreaseBase = 0.5; /* 0 < variable < 1 */
    var tooSmallRestoreDuration = "0.3s";
    var expIncreaseBase = 0.9; /* 0 < variable < 1 */
    var tooLargeRestoreDuration = "0.2s";
    var expOutOfBoundBase = 0.995; /* 0 < variable < 1 */
    var outOfBoundRestoreDuration = "0.2s";
    var kineticFriction = 1;

    /* Scale related variables */
    var origin = {x: 0, y: 0};
    var screenOrigin = {x: 0, y: 0};
    var translate = {x: 0, y: 0};
    var lastValidTranslate = {x: 0, y: 0};
    var scaleFactor = 1;
    var prevScaleFactor = 1;
    var width;
    var height;
    var lastValidWidth;
    var lastValidHeight;
    var unzoomedWidth;
    var unzoomedHeight;

    lastValidWidth = width = unzoomedWidth = content.width();
    lastValidHeight = height = unzoomedHeight = content.height();

    /* Panning related variables */
    var prevDragPoint = {x: 0, y: 0};


    function setMoveCursor(move) {
      if (!hasTouch) {
        if (move) {
          content.css("cursor", "move");
        } else {
          if (deviceDetect.isWebKit()) {
            content.css("cursor", "-webkit-zoom-in");
          } else {
            content.css("cursor", "-moz-zoom-in");
          }
        }
      }
    }

    function resetZoom() {

      origin = {x: 0, y: 0};
      screenOrigin = {x: 0, y: 0};
      translate = {x: 0, y: 0};
      lastValidTranslate = {x: 0, y: 0};
      scaleFactor = 1;
      prevScaleFactor = 1;
      lastValidWidth = width = unzoomedWidth = content.width();
      lastValidHeight = height = unzoomedHeight = content.height();

      cssTransform();
    }

    // Prevent browser drag on images
    content.find('img').on('dragstart', function(event) {event.preventDefault();});

    content.on("touchstart MSPointerDown pointerdown", onTouchStart);

    var hammer = new Hammer.Manager( content.get( 0 ), {
      // The Asteikko Native iOS app WebView has a bug:
      // - Pinch out until both fingers are outside of the WebView (e.g. outside
      //   of the device's screen)
      // - Release both fingers
      // - Result: both or one of the Hammer.js touches gets "stuck", effectively
      //   breaking all pan gestures. Panning causes the view to zoom, because
      //   Hammer.js thinks there are two active touches, not one.
      //
      // This is probably caused by iOS 13 adding support for pointer events.
      // Therefore we disable pointer events and use good ol' touch events instead.
      // https://github.com/hammerjs/hammer.js/issues/1084#issuecomment-544220158
      inputClass: hasTouch ? Hammer.TouchInput : null
    } );

    hammer.add( new Hammer.Pan( { threshold: 4 } ) );
    hammer.on( 'panstart', onDragStart );
    hammer.on( 'panmove', onDrag );
    hammer.on( 'panend', onDragEnd );

    hammer.add( new Hammer.Pinch() );
    hammer.on( 'pinchstart', onTransformStart );
    hammer.on( 'pinchmove', onTransform );
    hammer.on( 'pinchend', onTransformEnd );
    hammer.on( 'pinchcancel', onTransformEnd );

    if ( hasTouch )
      hammer.add( new Hammer.Tap( { taps: 2, threshold: 5, posThreshold: 30, interval: 700, time: 400 } ) );
    else
      hammer.add( new Hammer.Tap( { taps: 1, threshold: 5, posThreshold: 30, time: 400 } ) );

    hammer.on( 'tap', onTapZoom );

    function onTouchStart(event) {

      event.stopPropagation();

      // Prevent entering overview view in iOS9 when zooming out with a pinch
      if ( event.originalEvent.touches && event.originalEvent.touches.length > 1 ) {
        event.preventDefault();
      }
    }

    function onDragStart(event) {
      isSwitcherPageChanged = true;
      /* Prepare for drag */
      prevDragPoint.x = event.center.x;
      prevDragPoint.y = event.center.y;
    }

    function onDrag(event) {
      move(event.center.x, event.center.y, event.srcEvent);
    }

    function onDragEnd(event) {
      var restore = false;

      if (translate.y > 0) {
        translate.y = 0;
        restore = true;
      } else if (translate.y < unzoomedHeight - height) {
        translate.y = unzoomedHeight - height;
        restore = true;
      }

      if (!horizontalScroller) {
        if (translate.x > 0) {
          translate.x = 0;
          restore = true;
        } else if (translate.x < unzoomedWidth - width) {
          translate.x = unzoomedWidth - width;
          restore = true;
        }
      }

      if (restore) {
        cssTransform(outOfBoundRestoreDuration);
      }
    }

    function onTransformStart(event) {
      screenOrigin.x = event.center.x;
      screenOrigin.y = event.center.y;

      /* Prepare for dragging with pinch */
      prevDragPoint.x = event.center.x;
      prevDragPoint.y = event.center.y;

      /* We always start with a valid transform */
      lastValidTranslate.x = translate.x;
      lastValidTranslate.y = translate.y;
      lastValidWidth = width;
      lastValidHeight = height;
    }

    var fakeTransformEndTimeout;

    function onTransform(event) {
      /* Support move drag while pinch zooming. This code is similiar to that of move()
       * function. There are enough differeneces to make this a separate thing. In general,
       * this is simpler.
       */
      var dX = event.center.x - prevDragPoint.x;
      var dY = event.center.y - prevDragPoint.y;

      /* Exponentially slow down dragging out of left or right bound */
      if (dX > 0 && translate.x + dX > 0) {
        dX *= Math.pow(expOutOfBoundBase, translate.x + dX);
      } else if (dX < 0 && translate.x + dX < unzoomedWidth - width) {
        dX *= Math.pow(expOutOfBoundBase, unzoomedWidth - width - translate.x - dX);
      }

      /* Exponentially slow down dragging out of upper or lower bound */
      if (dY > 0 && translate.y + dY > 0) {
        dY *= Math.pow(expOutOfBoundBase, translate.y + dY);
      } else if (dY < 0 && translate.y + dY < unzoomedHeight - height) {
        dY *= Math.pow(expOutOfBoundBase, unzoomedHeight - height - translate.y - dY);
      }

      translate.x += dX;
      translate.y += dY;
      prevDragPoint.x = event.center.x;
      prevDragPoint.y = event.center.y;

      /* Calculate the right scale. Exponentially slow down scaling when out of zoom limits */
      scaleFactor = prevScaleFactor * event.scale;
      if (scaleFactor < MIN_ZOOM) {
        scaleFactor = MIN_ZOOM - (1 - Math.pow(expDecreaseBase, MIN_ZOOM - scaleFactor));
      } else if (scaleFactor > MAX_ZOOM) {
        scaleFactor = MAX_ZOOM + (1 - Math.pow(expIncreaseBase, scaleFactor - MAX_ZOOM));
      }

      scale();

      // Sometimes hammerjs doesn't fire 'pinchend'
      window.clearTimeout( fakeTransformEndTimeout );
      fakeTransformEndTimeout = window.setTimeout( onTransformEnd, 1500 );
    }

    function onTransformEnd() {

      window.clearTimeout( fakeTransformEndTimeout );

      /* Restore back into zoom limits if needed */
      if (scaleFactor < MIN_ZOOM || scaleFactor > MAX_ZOOM) {
        scaleFactor = Math.max(MIN_ZOOM, Math.min(scaleFactor, MAX_ZOOM));
        scale(true);
      } else {
        var restore = false;

        if (translate.y > 0) {
          translate.y = 0;
          restore = true;
        } else if (translate.y < unzoomedHeight - height) {
          translate.y = unzoomedHeight - height;
          restore = true;
        }

        if (translate.x > 0) {
          translate.x = 0;
          restore = true;
        } else if (translate.x < unzoomedWidth - width) {
          translate.x = unzoomedWidth - width;
          restore = true;
        }

        if (restore) {
          cssTransform(outOfBoundRestoreDuration);
        }
      }

      prevScaleFactor = scaleFactor;
    }

    function onTapZoom(event) {
      if (scaleFactor < MAX_ZOOM) {
        screenOrigin.x = event.center.x * MAX_ZOOM;
        screenOrigin.y = event.center.y * MAX_ZOOM;
        prevScaleFactor = scaleFactor = MAX_ZOOM;
        setMoveCursor(true);
      } else {
        prevScaleFactor = scaleFactor = MIN_ZOOM;
        setMoveCursor(false);
      }
      scale(true);
    }

    function scale(forced) {

      var newWidth = unzoomedWidth * scaleFactor;
      var newHeight = unzoomedHeight * scaleFactor;

      origin.x = screenOrigin.x - translate.x;
      origin.y = screenOrigin.y - translate.y;
      translate.x += -origin.x * (newWidth - width) / newWidth;
      translate.y += -origin.y * (newHeight - height) / newHeight;

      var duration = "0";

      if (typeof forced == "boolean" && forced) {
        if (scaleFactor >= MAX_ZOOM) {
          /* recalculate */
          translate.x = lastValidTranslate.x;
          translate.y = lastValidTranslate.y;
          width = lastValidWidth;
          height = lastValidHeight;
          origin.x = screenOrigin.x - translate.x;
          origin.y = screenOrigin.y - translate.y;
          translate.x += -origin.x * (newWidth - width) / newWidth;
          translate.y += -origin.y * (newHeight - height) / newHeight;

          if (translate.y > 0) {
            translate.y = 0;
          } else if (translate.y < unzoomedHeight - newHeight) {
            translate.y = unzoomedHeight - newHeight;
          }

          if (translate.x > 0) {
            translate.x = 0;
          } else if (translate.x < unzoomedWidth - newWidth) {
            translate.x = unzoomedWidth - newWidth;
          }

          duration = tooLargeRestoreDuration;
        } else {
          /* When MIN_ZOOM < 1, might consider center the content */
          translate.x = translate.y = 0;
          duration = tooSmallRestoreDuration;
        }
      }

      if (scaleFactor <= MAX_ZOOM) {
        lastValidTranslate.x = translate.x;
        lastValidTranslate.y = translate.y;
        lastValidWidth = newWidth;
        lastValidHeight = newHeight;
      }

      cssTransform(duration);

      width = newWidth;
      height = newHeight;
    }

    function move(x, y, event) {
      //alert('called once');
      /* Update location */
      var dX, dY;
      dX = x - prevDragPoint.x;
      if (scaleFactor == MIN_ZOOM) {
        /* Vertical lock on minimum zoom level */
        dY = 0;
      } else {
        dY = y - prevDragPoint.y;
      }

      if ( !horizontalScroller ) {
        if ( deviceDetect.getBrowser() === 'opera' ) {
          if ( dX > 0 && translate.x + dX > 0 && isSwitcherPageChanged ) {
            /* Dragging left to right */
            Asteikko.pageSwitcher.switchPrevious();
            dX = -translate.x;
          } else if ( dX < 0 && translate.x + dX < unzoomedWidth - width && isSwitcherPageChanged ) {
            /* Dragging left to right */
            Asteikko.pageSwitcher.switchNext();
            dX = unzoomedWidth - width - translate.x;
          }
          isSwitcherPageChanged = false;
        } else {
            /* Exponentially slow down dragging out of left or right bound */
          if (dX > 0 && translate.x + dX > 0) {
            dX *= Math.pow(expOutOfBoundBase, translate.x + dX);
          } else if (dX < 0 && translate.x + dX < unzoomedWidth - width) {
            dX *= Math.pow(expOutOfBoundBase, unzoomedWidth - width - translate.x - dX);
          }
        }
      } else {
        if (dX > 0 && translate.x + dX > 0) {
          /* Dragging left to right */
          if (!horizontalScroller.initiated) {
            // alert('left to right')
            horizontalScroller.__start(event);
          }
          dX = -translate.x;
        } else if (dX < 0 && translate.x + dX < unzoomedWidth - width) {
          /* Dragging left to right */
          if (!horizontalScroller.initiated) {
            // alert('rigth to left');
            horizontalScroller.__start(event);
          }
          dX = unzoomedWidth - width - translate.x;
        } else {
          if (horizontalScroller.initiated) {
            return;
          }
        }
      }

      /* Exponentially slow down dragging out of upper or lower bound */
      if (dY > 0 && translate.y + dY > 0) {
        dY *= Math.pow(expOutOfBoundBase, translate.y + dY);
      } else if (dY < 0 && translate.y + dY < unzoomedHeight - height) {
        dY *= Math.pow(expOutOfBoundBase, unzoomedHeight - height - translate.y - dY);
      }

      translate.x += dX;
      translate.y += dY;
      cssTransform();

      prevDragPoint.x = x;
      prevDragPoint.y = y;
    }

    var transformTimeout;

    function cssTransform(duration) {
      var matrix = "matrix3d(" + scaleFactor + ", 0, 0, 0, 0, " + scaleFactor + ", 0, 0, 0, 0, " +
                scaleFactor + ", 0, " + translate.x + ", " + translate.y + ", 0, 1)";

      // If browser doesn't support 3d transforms, fall back to 2d transforms
      if (window.asmagModernizr && !asmagModernizr.csstransforms3d && asmagModernizr.csstransforms) {
          matrix = "matrix(" + scaleFactor + ", 0, 0, " + scaleFactor + ", " + translate.x + ", " +
                    translate.y + ")";
      }

      var props = {};
      var vendor = '';

      for ( var i = 0; i < vendorPrefixes.length; i++ ) {
        vendor = vendorPrefixes[ i ];
        props[vendor + 'transform-origin'] = "0 0";
        if (typeof duration != "undefined" && duration) {
          props[vendor + 'transition-duration'] = duration;
          props[vendor + 'transition-timing-function'] = "ease";
        } else {
          props[vendor + 'transition-duration'] = "0s";
          props[vendor + 'transition-timing-function'] = "linear";
        }
        props[vendor + 'transform'] =  matrix;
      }

      content.css(props);

      window.clearTimeout( transformTimeout );
      transformTimeout = window.setTimeout( finalizeTransform,
        ( duration > 0 ) ? duration : 200 );
    }

    function finalizeTransform() {

      var matrix = "matrix(" + scaleFactor + ", 0, 0, " + scaleFactor + ", " +
        translate.x + ", " + translate.y + ")";

      var props = {};
      var vendor = '';

      for ( var i = 0; i < vendorPrefixes.length; i++ ) {
        vendor = vendorPrefixes[ i ];
        props[vendor + 'transform-origin'] = "0 0";
        props[vendor + 'transition-duration'] = "";
        props[vendor + 'transition-timing-function'] = "";
        props[vendor + 'transform'] =  matrix;
      }

      content.css(props);
    }

    function onPageIndexChanged( event, oldIndex, newIndex ) {
      // Reset the zoom levels only if we actually flipped to another page
      if ( oldIndex != newIndex ) {
        asteikkoImg.checkImagesAreLoadedInside( content, function() {
          setTimeout(resetZoom, 1);
        } );
      }
    }
    $( window ).on( 'pageIndexChanged', onPageIndexChanged );

    function onResize() {
      computeMaxZoom();
      setTimeout(resetZoom, 1);
    }
    $(window).resize( onResize );

    // Init

    asteikkoImg.checkImagesAreLoadedInside( content, function() {
      computeMaxZoom();
      setTimeout(resetZoom, 1);
    } );

    setMoveCursor(false);
    cssTransform();

    return {

      destroy: function() {

        hammer.destroy();
        hammer.off( 'panstart' );
        hammer.off( 'panmove' );
        hammer.off( 'panend' );
        hammer.off( 'pinchstart' );
        hammer.off( 'pinchmove' );
        hammer.off( 'pinchend' );
        hammer.off( 'pinchcancel' );
        hammer.off( 'tap' );

        content.removeData( 'zoomable' );
        content.css( 'cursor', '' );
        content.off( "touchstart MSPointerDown pointerdown" );

        $( window ).off( 'resize', onResize );
        $( window ).off( 'pageIndexChanged', onPageIndexChanged );

        var props = {};
        for ( var i = 0; i < vendorPrefixes.length; i++ ) {
          var vendor = vendorPrefixes[ i ];
          props[vendor + 'transform-origin'] = "";
          props[vendor + 'transition-duration'] = "";
          props[vendor + 'transition-timing-function'] = "";
          props[vendor + 'transform'] = '';
        }
        content.css(props);
      },

      refresh: function() {
        computeMaxZoom();
        setTimeout(resetZoom, 1);
      }
    };
  }
};
