
if ( !window.Asteikko ) window.Asteikko = {};
if ( !Asteikko.worker ) window.Asteikko.worker = {};

Asteikko.worker.lzstring = {

  compressToUTF16: function( str, callback ) {
    callback( LZString.compressToUTF16( str ) );
  },

  decompressFromUTF16: function( str, callback ) {
    callback( LZString.decompressFromUTF16( str ) );
  }
};
