window.Asteikko = window.Asteikko || {};

/**
 * Abstraction of the HTML5 history API for Asteikko.
 */
window.Asteikko.history = ( function( history ) {

  "use strict";
  
  var ret = {};

  ret.pushState = function( state, title, url ) {

    if ( !history || !history.pushState )
      return;
    history.pushState( state, title, url );
  };

  ret.replaceState = function( state, title, url ) {

    if ( !history || !history.replaceState )
      return;
    history.replaceState( state, title, url );
  };

  $( window ).on( 'popstate', function( event ) {

    var state = event.originalEvent.state;

    if ( !state )
      return;

    if ( state.issueId ) {
      if ( window.asteikkoIssue && state.issueId == window.asteikkoIssue.id ) {
        if ( state.pageId )
          goToPageById( state.pageId );
      }
      else {
        window.location.reload();
      }
    }
  } );

  return ret;
  
} )( window.history );
