/**
 * These files handles the JS logic of the magazine listing widget.
 * The init() method binds a few events that handle issue cover clicks, 
 * page changes etc. These can be overridden by external code after the default
 * handlers in init() have been bound, if the external code requires something 
 * else to be done instead of these default actions.
 */

if ( !window.Asteikko ) window.Asteikko = {};
if ( !window.Asteikko.widgets ) window.Asteikko.widgets = {};

( function() {
  
  "use strict";
  
  var magList = {};

  magList.onClickPageLink = function( event ) {

    var el = $( this ).closest( '.magazineListingWidget' );
    magList.loadContent(
      el,
      $( this ).attr( 'data-page' ),
      el.attr( 'data-show-pagination' ) === 'true',
      el.attr( 'data-show-year-select' ) === 'true',
      el.attr( 'data-issues-per-page' ),
      el.attr( 'data-cover-size' ),
      el.attr( 'data-included-ids' ).split( ',' ),
      el.attr( 'data-excluded-ids' ).split( ',' ),
      el.attr( 'data-name-contains' ).split( ',' ),
      el.attr( 'data-name-not-contains' ).split( ',' ),
      el.attr( 'data-has-tags' ).split( ',' ),
      el.attr( 'data-has-not-tags' ).split( ',' ),
      el.attr( 'data-date-start' ),
      el.attr( 'data-date-end' )
    );
  };

  magList.onChangeYearSelect = function( event ) {

    var el = $( this ).closest( '.magazineListingWidget' );
    var year = $( this ).val();
    if ( !year )
      return;

    magList.loadContent(
      el,
      1,
      el.attr( 'data-show-pagination' ) === 'true',
      el.attr( 'data-show-year-select' ) === 'true',
      // If widget settings say so, we set the issue count to infinite if a user
      // has selected a year, so that all issues for that year will be shown
      ( el.attr( 'data-infinite-count-when-year-selected' ) === 'true' )
        ? 0
        : el.attr( 'data-issues-per-page' ),
      el.attr( 'data-cover-size' ),
      el.attr( 'data-included-ids' ).split( ',' ),
      el.attr( 'data-excluded-ids' ).split( ',' ),
      el.attr( 'data-name-contains' ).split( ',' ),
      el.attr( 'data-name-not-contains' ).split( ',' ),
      el.attr( 'data-has-tags' ).split( ',' ),
      el.attr( 'data-has-not-tags' ).split( ',' ),
      year +'-01-01',
      year +'-12-31 23:59:59'
    );
  };

  magList.onCoverClick = function( event ) {

    var el = $( this ).closest( '.magazineItem' );
    var issueId = el.attr( 'data-issue_id' );

    var userAccessLevel = parseInt( Asteikko.readCookie( 'asauth_user_accesslevel' ), 10 ) || 1;
    // Level 2 is 'logged in' in Asteikko Auth.
    var userIsLoggedIn = userAccessLevel > 1;

    Asteikko.createCookie( 'asmag_last_accessed_issue', issueId );

    var requiredAccessLevel = parseInt( el.attr( 'data-required_access_level' ), 10 ) || 0;
    // If user doesn't meet the required access level of the issue, he can still
    // access the issue if he owns the issue as an Asteikko Auth product
    if ( userAccessLevel >= requiredAccessLevel || el.hasClass( 'downloaded' ) || el.hasClass( 'bought' ) )
      return true; // follow the href
    
    event.preventDefault();
    
    if ( userIsLoggedIn ) {
      if ( window.settings.subsys.no_access_redirect_logged_in )
        window.location.href = ( settings.subsys.no_access_redirect_logged_in +'' );
      else
        Asteikko.showIssueLoggedInPaywall( issueId );
    }
    else {
      if ( settings.subsys.no_access_redirect_logged_out )
        window.location.href = ( settings.subsys.no_access_redirect_logged_out +'' );
      else
        Asteikko.showIssuePaywall( issueId );
    }

    return false;
  };
  
  magList.loadContent = function( oldWidgetElem, page, showPagination, showYearSelect, issuesPerPage, coverImageSize, issueIds, excludedIssueIds, nameContains, nameNotContains, hasTags, hasNotTags, dateStart, dateEnd ) {
    
    page = parseInt( page, 10 );
    issuesPerPage = parseInt( issuesPerPage, 10 );
    coverImageSize = ( coverImageSize +'' ).replace( /[^a-zA-Z_\d]/g, '' );
    
    var offset = Math.round( ( page - 1 ) * issuesPerPage ) || 0;
    
    if ( !oldWidgetElem.length )
      return;
    
    oldWidgetElem.css( 'opacity', '0.4' );
    
    var options = {
      blog_id: oldWidgetElem.attr( 'data-blog-id' ),
      widget_name: oldWidgetElem.attr( 'data-widget-name' ),
      title: oldWidgetElem.find( '.widgetTitle' ).first().text(),
      order: oldWidgetElem.attr( 'data-order' ),
      show_pagination: showPagination,
      show_year_select: showYearSelect,
      year_select_max_year: oldWidgetElem.attr( 'data-year-select-max-year' ),
      year_select_min_year: oldWidgetElem.attr( 'data-year-select-min-year' ),
      infinite_count_when_year_selected: oldWidgetElem.attr( 'data-infinite-count-when-year-selected' ),
      count: issuesPerPage,
      offset: offset,
      size: coverImageSize,
      date_start: dateStart,
      date_end: dateEnd
    };
    
    if ( $.isArray( issueIds ) && issueIds.length )
      options.ids = issueIds.join( ',' );
    if ( $.isArray( excludedIssueIds ) && excludedIssueIds.length )
      options.ids_exclude = excludedIssueIds.join( ',' );

    if ( $.isArray( nameContains ) && nameContains.length )
      options.name_contains = nameContains.join( ',' );
    if ( $.isArray( nameNotContains ) && nameNotContains.length )
      options.name_not_contains = nameNotContains.join( ',' );

    if ( $.isArray( hasTags ) && hasTags.length )
      options.has_tags = hasTags.join( ',' );
    if ( $.isArray( hasNotTags ) && hasNotTags.length )
      options.has_not_tags = hasNotTags.join( ',' );

    $.post( Asteikko.env.site_url + '/asmag/magazinelist', options, undefined, 'html' ).
      done( function( data ) {
        oldWidgetElem.replaceWith( data );
      } ).
      always( function() {
        oldWidgetElem.css( 'opacity', '' );
      } );
  };

  magList.getDownloadedIssuesHtml = function() {

    var context = {
      // Random element ID
      elemId: 'asmag'+ Math.floor( Math.random( 1 ) * 2147483640 ) + Math.floor( Math.random( 1 ) * 2147483640 ),
      issues: []
    };

    var issueIds = Asteikko.issueCache.getCachedIssueIds();

    for ( var i = 0; i < issueIds.length; i++ ) {
      var issue = Asteikko.issueCache.getIssueMeta( issueIds[ i ] );
      if ( !issue )
        continue;
      var pubDate = new Date( Number( issue.publish_date +'000' ) );
      issue.publish_date_for_tpl = pubDate.getDate() +'.'+ ( pubDate.getMonth() + 1 ) +'.'+ pubDate.getFullYear();
      issue.cover_url = 'data:image/jpeg;base64,'+ Asteikko.issueCache.getIssueCover( issue.id );
      context.issues.push( issue );
    }

    magList._sortIssues( context.issues );

    return Promise.resolve( Asteikko.ui.renderTemplate( 'widget_maglist', context ) );
  };

  magList._sortIssues = function( issues ) {

    issues.sort( function( a, b ) {
      return b.publish_date - a.publish_date;
    } );
  };

  magList._bindEventHandlers = function() {

    // These handlers can be overridden by external code. E.g. you might want to
    // not open the issue right away when clicking the cover, but rather show
    // a dialog with a table of contents.
    $( document.body ).on( 'change', '.magazinelisting-yearselect select', magList.onChangeYearSelect );
    $( document.body ).on( 'click', '.magazinelisting-pagination .pagelink', magList.onClickPageLink );
    $( document.body ).on( 'click', '.magazineItem a', magList.onCoverClick );
  };

  magList.init = function() {
    magList._bindEventHandlers();
    $(function() {
      if ($('.magazinelisting-yearselect select').val()) {
        $('.magazinelisting-yearselect select').change();
      }
    });
  };
  
  Asteikko.widgets.magList = magList;
  
} )();

