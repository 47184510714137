window.AsteikkoAnalytics = ( function() {
  var initialized = false;
  var blogId = 0;
  var gaVersion = ''; // 'ua' or 'ga4'

  function initDataFromServer() {
    $.ajax( {
      url: Asteikko.env.site_url +'/asmag/analytics/initjs',
      dataType: 'json',
      cache: false
    } ).
      done( function( data ) {
        if ( initialized ) {
          return;
        }

        if ( data && data.code === 0 ) {
          if ( gaVersion === 'ga4' ) {
            // New Google Analytics 4
            AsteikkoAnalyticsGA4.initGa( data.result.trackingId );
          }
          else if ( gaVersion === 'ua' ) {
            // Old Universal Analytics
            AsteikkoAnalyticsUA.initGa( blogId, data.result );
          }
          else {
            // No GA tracking ID, so we're never gonna send analytics to GA
          }
        }
      } ).
      always( function() {
        initialized = true;
      } );
  }

  var al = {};

  al.init = function( _wpBlogId, _gaVersion ) {
    if ( !_wpBlogId || initialized ) {
      return;
    }

    Asteikko.log( 'Initializing analytics for blog ' + _wpBlogId );

    blogId = _wpBlogId;
    gaVersion = _gaVersion || 'ga4';

    $( initDataFromServer );
  };

  // TODO: Remove these methods after you've removed all calls to them (or made
  // sure that nobody calls these anymore already)
  al.getAppTypeDim = function() { Asteikko.error( 'getAppTypeDim() is deprecated and is now a no-op' ); return null; };
  al.getUserTypeDim = function() { Asteikko.error( 'getUserTypeDim() is deprecated and is now a no-op' ); return null; };
  al.getIssueDim = function() { Asteikko.error( 'getIssueDim() is deprecated and is now a no-op' ); return null; };
  al.getArticleDim = function() { Asteikko.error( 'getArticleDim() is deprecated and is now a no-op' ); return null; };
  al.getIssuePriceDim = function() { Asteikko.error( 'getIssuePriceDim() is deprecated and is now a no-op' ); return null; };
  al.getIssueTagsDim = function() { Asteikko.error( 'getIssueTagsDim() is deprecated and is now a no-op' ); return null; };

  /**
   * issueTags should be string of comma-separated tags.
   */
  al.sendEvent = function( category, action, label, value, issue, article, issuePrice, issueTags ) {
    if ( gaVersion === 'ga4' ) {
      AsteikkoAnalyticsGA4.sendEvent.apply( AsteikkoAnalyticsGA4, arguments );
    }
    else if ( gaVersion === 'ua' ) {
      AsteikkoAnalyticsUA.sendEvent.apply( AsteikkoAnalyticsUA, arguments );
    }
  };

  /**
   * issueTags should be string of comma-separated tags.
   */
  al.sendPageView = function( page, title, issue, article, issuePrice, issueTags ) {
    if ( gaVersion === 'ga4' ) {
      AsteikkoAnalyticsGA4.sendPageView.apply( AsteikkoAnalyticsGA4, ( arguments ) );
    }
    else if ( gaVersion === 'ua' ) {
      AsteikkoAnalyticsUA.sendPageView.apply( AsteikkoAnalyticsUA, ( arguments ) );
    }
  };

  // TODO: Remove this method after you've removed all calls to it. This method
  // was only used by the iOS and Android apps, which don't exist anymore.
  al.sendToMeasurementProt = function( data, synchronous ) {
    Asteikko.error( 'sendToMeasurementProt() is deprecated and is now a no-op' );
  };

  return al;

} )();
