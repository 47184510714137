/*******************************************************************************
  Issue reading view
*******************************************************************************/

if ( !window.Asteikko ) window.Asteikko = {};

( function() {

  'use strict';

  Asteikko.reader = {
    _getRenderer: null,
    _previousLanguageStrings: null,
    _previousMetaViewport: null,
    _offlineImageDataUrls: {},
    _seeAdminBar: false
  };

  /**
   * TODO: make this be Asteikko.issue.init(), and get rid of the globals?
   * Initialize Asteikko.issue.metadata and Asteikko.issue.pages instead.
   */
  Asteikko.reader.initIssueGlobals = function( issueMeta ) {

    // Initialize the (deprecated) globals; TODO: put these in Asteikko.issue
    /**
     * @deprecated since 5.0.0
     */
    window.asteikkoIssue = issueMeta;
    window.pages = issueMeta.pages;

    // Make the global language that of the issue (the issue might have a 
    // different language than the default). The default UI language will be
    // reverted back to when the issue is closed.
    if ( !this._previousLanguageStrings )
      this._previousLanguageStrings = window.settings.lang;
    window.settings.lang = $.extend( true, {}, window.settings.lang, issueMeta.language_strings );
  };

  /**
   * The given function should return a renderer object. The given function 
   * will be passed a context object that represents the opened issue and 
   * Asteikko's environment, so that the function can decide which renderer
   * object to return for the issue and environment.
   *
   * The renderer is an object whose methods will be called at certain times
   * of rendering the Asteikko reader UI. Available methods:
   *
   *    beforeRender( context ) // Called before any HTML elements are created.
   *                            // This is the best place to modify the context
   *                            // object.
   *
   *    render( context, uiRootElem ) // Called when the core of the reader has 
   *                                  // been added to uiRootElem. It is the 
   *                                  // renderer object's job to append its own
   *                                  // UI elements to uiRootElem in render().
   *
   *    afterRender( context, uiRootElem ) // Called after the UI HTML has been appended to DOM.
   *
   *    beforeUnload( context, uiRootElem ) // Before the UI is removed from the 
   *                                        // DOM. This is a good place to 
   *                                        // unbind any event handlers.
   *
   *    afterUnload( context ) // After the UI has been removed from the DOM
   * 
   *    disableDefaultPageFlip( context ) // Whether to allow page changing by the user
   *                             // by horizontal swiping or with keyboard arrow
   *                             // keys. Pages can still be changed via 
   *                             // JavaScript, so disabling the default page
   *                             // switching is useful for custom page flip implementations.
   *
   * You can modify the context object in beforeRender() to add any custom data 
   * in it. The context object is a copy of the app state, so it can be modified
   * freely.
   *
   * The recommended way to add content to uiRootElem in render() is to use 
   * Handlebars templates. Use Asteikko.ui.renderTemplate( name, context ) to
   * render a template that exists in includes/(system|custom)/client_templates.
   */
  Asteikko.reader.setGetRendererFunction = function( func ) {

    if ( func && typeof func === 'function' )
      this._getRenderer = func;
    else
      throw 'Given argument is not a function';
  };

  Asteikko.reader.centerMagazine = function( seeAdminBar ) {

    if ( !window.asteikkoIssue )
      return;

    $('#asmag-issue #magazine-wrapper').css('max-width', asteikkoIssue.max_magazine_width);

    if ( $(window).width() > asteikkoIssue.max_magazine_width ) {

      $('#asmag-issue #magazine-wrapper').css( {'left': Math.floor( ($(window).width() - asteikkoIssue.max_magazine_width) / 2 ) } );
    }

    if ( $(window).width() < asteikkoIssue.max_magazine_width + 1 ) {
      $('#asmag-issue #magazine-wrapper').css( {'left': 0} );
    }

    if ( seeAdminBar ) {
      $( 'body' ).addClass( 'adminbar-active' );
    }

  };

  Asteikko.reader.refreshMagazineSize = function() {
    if (typeof pageScroller != "undefined") {
      pageScroller.refreshSize();
    }
  };

  Asteikko.reader.normalizePermalink = function( permalink ) {
    return ( permalink +'' ).
      replace( /^https?:\/\/(.+)/i, '$1' ).
      replace( /#.*$/, '' ).
      replace( /\?.*$/, '' ).
      replace( /\/$/, '' );
  };

  Asteikko.reader._getPageIdForPermalink = function( permalink ) {

    if ( !permalink || !window.pages || !window.pages.length )
      return 0;

    permalink = Asteikko.reader.normalizePermalink( permalink );

    var pageId = 0;
    for ( var i = 0; i < pages.length; i++ ) {
      if ( Asteikko.reader.normalizePermalink( pages[ i ].permalink ) === permalink ) {
        pageId = pages[ i ].id;
        break;
      }
    }

    return pageId;
  };

  Asteikko.reader.goToPageByPermalink = function( permalink ) {

    var pageId = this._getPageIdForPermalink( permalink );
    if ( !pageId )
      return false;
    return window.goToPageById( pageId );
  };

  Asteikko.reader._initReader = function() {

    // If another reader is already loaded, unload it first
    Asteikko.reader._unloadReader();

    return $( '<div id="asmag-issue" style="display: none;"></div>' );
  };

  Asteikko.reader._testBrowserSupport = function() {

    Asteikko.createCookie( 'cookietest', 'asteikko', 1 );
    if ( Asteikko.readCookie( 'cookietest' ) !== null ) {
      Asteikko.eraseCookie( 'cookietest' );
    }
    else {
      $( function() {
        $( '#asmag-nocookies' ).show().center();
      } );
    }
    if ( !asmagModernizr.multiplebgs || !asmagModernizr.backgroundsize ) {
      $( '#asmag-issue' ).show( '#asmag-nosupportforcss' );
    }
  };

  Asteikko.reader._initAnalytics = function( issueMeta ) {

    AsteikkoAnalytics.sendEvent(
      'AsteikkoMag', 
      'Open issue', 
      issueMeta.folder, 
      undefined, 
      issueMeta.id, 
      undefined, 
      issueMeta.paid ? 'paid' : 'free',
      issueMeta.tags.join( ',' )
    );

    // Fire pageview event for the initial page
    if ( issueMeta.pages[currentIndex] ) {
      var path = issueMeta.pages[currentIndex].permalink || '';

      AsteikkoAnalytics.sendPageView( 
        path,
        asteikkoIssue.name +': '+ issueMeta.pages[currentIndex].title,
        issueMeta.id, 
        issueMeta.pages[currentIndex].id,
        issueMeta.paid ? 'paid' : 'free',
        issueMeta.tags.join( ',' )
      );
    }

    function onPageIndexChangedAnalytics( event, oldIndex, currentIndex ) {
      var path = issueMeta.pages[currentIndex].permalink || '';

      window.setTimeout( function() {
        AsteikkoAnalytics.sendPageView( 
          path,
          asteikkoIssue.name +': '+ issueMeta.pages[currentIndex].title,
          issueMeta.id, 
          issueMeta.pages[currentIndex].id,
          issueMeta.paid ? 'paid' : 'free',
          issueMeta.tags.join( ',' )
        );
      }, 500 );
    }

    $( window ).off( 'pageIndexChanged.asmagissue_analytics' );
    $( window ).on( 'pageIndexChanged.asmagissue_analytics', onPageIndexChangedAnalytics );
  };

  Asteikko.reader._getRendererContext = function( issueMeta, initialPageIndex ) {

    // We clone all objects so that the renderer can't modify (read: break) them.
    // The renderer can freely modify the context object to suit its needs.
    var context = {
      issue: Asteikko.util.objectDeepClone( issueMeta ),
      initialPageIndex: initialPageIndex,
      settings: Asteikko.util.objectDeepClone( window.settings ),
      env: Asteikko.util.objectDeepClone( Asteikko.env ),
      device: {
        hasTouch: asmagModernizr.touchevents || deviceDetect.getOS() === 'windowsphone'
      }
    };

    return context;
  };

  Asteikko.reader._unloadReader = function( fadeOutIssueWrapper ) {

    var oldRendererContext = Asteikko.reader._rendererContext;

    if ( window.asteikkoIssue ) {
      if ( Asteikko.reader._renderer && typeof Asteikko.reader._renderer.beforeUnload === 'function' )
        Asteikko.reader._renderer.beforeUnload( oldRendererContext, $( '#asmag-issue' ).get( 0 ) );
    }

    // Empty caches
    Asteikko.reader._offlineImageDataUrls = {};
    delete Asteikko.reader._rendererContext;

    var issueId = ( window.asteikkoIssue ) ? window.asteikkoIssue.id : 0;

    // Remove the (deprecated) globals
    /**
     * @deprecated since 5.0.0
     */
    window.asteikkoIssue = undefined;
    window.pages = undefined;

    // Return back to the default UI language
    if ( Asteikko.reader._previousLanguageStrings )
      settings.lang = Asteikko.reader._previousLanguageStrings;
    Asteikko.reader._previousLanguageStrings = null;

    $('body, html').removeClass( 'asmag-active' );

    Asteikko.reader._restoreMetaViewport();
    document.title = Asteikko.env.site_name;

    function finalize() {

      if ( window.pageScroller )
        pageScroller.destroy();
      if ( window.pageSwitcher )
        pageSwitcher.destroy();
      $('#asmag-issue, #asmag-issue-loading').remove();
      $('#issue-css').empty();

      if ( issueId ) {
        if ( Asteikko.reader._renderer && typeof Asteikko.reader._renderer.afterUnload === 'function' )
          Asteikko.reader._renderer.afterUnload( oldRendererContext );
        AsteikkoAnalytics.sendPageView( '/asmag/home' );
        $( window ).trigger( 'asmag_issueclosed', issueId );
      }
    }

    if ( fadeOutIssueWrapper ) {
      $('#asmag-issue').fadeOut(200);
      window.setTimeout( function() {
        finalize();
      }, 250 );
    }
    else {
      finalize();
    }
  };

  Asteikko.reader._showReader = function( issueWrapper, issueMeta, pageId, seeAdminBar ) {

    Asteikko.log( 'Show reader' );

    var initialIndex = 0;
    if ( pageId ) {
      for ( var i = 0; i < issueMeta.pages.length; i++ ) {
        if ( issueMeta.pages[ i ].id == pageId ) {
          initialIndex = i;
          break;
        }
      }
    }

    this.initIssueGlobals( issueMeta );

    var context = this._getRendererContext( issueMeta, initialIndex );
    // Saved for passing to _renderer.beforeUnload() and _renderer.afterUnload()
    // when the reading view is destroyed
    this._rendererContext = context;
    this._renderer = this._getRenderer( context );

    if ( !this._renderer || typeof this._renderer !== 'object' )
      throw 'Renderer is not an object. Does the function passed to Asteikko.reader.setGetRendererFunction return an object?';

    // Let the UI renderer add its UI elements to the wrapper
    if ( this._renderer && typeof this._renderer.beforeRender === 'function' )
      this._renderer.beforeRender( context );

    var readerUi = $( Asteikko.ui.renderTemplate( 'core', context ) );
    issueWrapper.html( readerUi );

    // Tweak the DOM
    this.populateDomWithIssueData( issueWrapper, issueMeta );

    // Let the UI renderer add its UI elements to the wrapper
    if ( this._renderer && typeof this._renderer.render === 'function' )
      this._renderer.render( context, issueWrapper.get( 0 ) );

    // Let content be prepended and appended to the wrapper with 
    // 'extra_issue_head' and 'extra_issue_footer' templates
    var issueHead = Asteikko.ui.renderTemplate( 'extra_issue_head', context );
    if ( issueHead )
      issueWrapper.prepend( issueHead );
    var issueFooter = Asteikko.ui.renderTemplate( 'extra_issue_footer', context );
    if ( issueFooter )
      issueWrapper.append( issueFooter );

    // Remove any old issue wrapper and the loading screen
    $('#asmag-issue, #asmag-issue-loading').remove();
    issueWrapper.appendTo( 'body' );

    if ( seeAdminBar ) {
      var adminBarUi = $( Asteikko.ui.renderTemplate( 'admin_toolbar', context ) );
      adminBarUi.insertBefore( issueWrapper );

      var editorNoteUi = $( Asteikko.ui.renderTemplate( 'editor_note_ui', context ) );
      editorNoteUi.appendTo( issueWrapper );
    }

    this._testBrowserSupport();
    Asteikko.highlightsUi.create( '#mag' );

    issueWrapper.fadeIn('fast');
    Asteikko.reader.centerMagazine( seeAdminBar );
    Asteikko.reader.refreshMagazineSize();
    $('.asmag-notificationBox').center();

    if ( this._renderer && typeof this._renderer.afterRender === 'function' ) {
      this._renderer.afterRender( context, issueWrapper.get( 0 ) );
    }

    // Get rid of tap delay of onclick events on some mobile devices
    issueWrapper.find(".clickdelay").each(function (t) {
      new NoClickDelay(this);
    });

    Asteikko.createCookie( 'asmag_last_accessed_issue', issueMeta.id );

    if ( !issueMeta.user_can_access ) {

      /* If the user has no access to the issue, we don't want to show any 
      cached pages from localStorage. The user might have full versions of 
      the pages in localStorage if he accessed the issue earlier on with 
      reading privileges, and after that without reading privileges.

      It'd look weird with the full content of the pages visible but with a 
      small paywall on top of it. Therefore we clear the whole issue cache, so
      that the reading view will only show previews of the pages, not full versions. */

      Asteikko.issueCache.clearCacheForIssue( issueMeta.id );

      if ( Asteikko.lastLoginStatus )
        Asteikko.showIssueLoggedInPaywall( issueMeta.id );
      else
        Asteikko.showIssuePaywall( issueMeta.id );
    }

    var disableDefaultPageFlip = ( this._renderer && this._renderer.disableDefaultPageFlip )
      ? this._renderer.disableDefaultPageFlip( context )
      : false;

    Asteikko.reader._initPageChanger( issueMeta, initialIndex, disableDefaultPageFlip );
    Asteikko.reader._initAnalytics( issueMeta );
    Asteikko.showOrHideElementsForConnectivity( Asteikko.online );

    $(window).trigger( 'asmag_issueopened', [ issueMeta.id, initialIndex ] );
  };

  Asteikko.reader.populateDomWithIssueData = function( issueWrapper, issueMeta ) {

    issueWrapper.addClass( 'issue-'+ issueMeta.id );
    issueWrapper.attr( 'data-issue-id', issueMeta.id );
    issueWrapper.attr( 'data-issue-name',issueMeta.name );
    issueWrapper.attr( 'data-issue-tags', issueMeta.tags.join( ',' ) );
    issueWrapper.attr( 'data-issue-lang', issueMeta.language );

    if ( issueMeta.paid )
      issueWrapper.attr( 'data-issue-paid', 'paid' );

    if ( issueMeta.meta ) {
      var metaKeys = Object.keys( issueMeta.meta );
      for ( var i = 0; i < metaKeys.length; i++ ) {
        issueWrapper.attr( 'data-issue-meta-'+ metaKeys[ i ], issueMeta.meta[ metaKeys[ i ] ] );
      }
    }
  };

  Asteikko.reader._initPageChanger = function( issueMeta, initialIndex, disableDefaultPageFlip ) {

    Asteikko.log( 'Init page changer' );

    disableDefaultPageFlip = Boolean( disableDefaultPageFlip );

    function indexChangedFunc(oldIndex, currentIndex) {

      // Set the URL hash to point to the new page
      var page = issueMeta.pages[ currentIndex ];
      if ( page ) {
        if ( window.location.href !== page.permalink ) {
          var historyMethod = ( settings.navigation && settings.navigation.back_button_goes_to_previous_issue_page )
            ? 'pushState'
            : 'replaceState';
          Asteikko.history[ historyMethod ]( {
            issueId: issueMeta.id,
            pageId: page.id
          }, '', page.permalink );
        }
        document.title = page.title +' - '+ issueMeta.name +' - '+ Asteikko.env.site_name;
      }

      if (oldIndex !== currentIndex)
        $(window).trigger('pageIndexChanged', [oldIndex, currentIndex]);
    }

    function replaceImgSrcs(page) {

      $("#asmag-page-"+ issueMeta.pages[page].id +" img").each(function(e){
        var img = $(this);
        var source = img.attr('src');
        if ( !Asteikko.online && issueMeta.image_sizes[source] ) {
          var w = issueMeta.image_sizes[source].width;
          var h = issueMeta.image_sizes[source].height;
          var offlineImgSrc = Asteikko.reader._getOfflineImageDataUrl( w, h );
          img.attr( 'data-origsrc', source );
          if ( offlineImgSrc ) img.attr( 'src', offlineImgSrc );
        }
        else if ( Asteikko.online && img.attr( 'data-origsrc' ) 
          && source !== img.attr( 'data-origsrc' ) ) 
        {
          img.attr( 'src', img.attr( 'data-origsrc' ) );
        }
      });
    }

    function pageFinishLoadFunc( index ) {

      Asteikko.log("Page " + index + " loaded");

      replaceImgSrcs( index );
      $("#asmag-page-"+ issueMeta.pages[ index ].id +" img").bind('dragstart', function(event) {event.preventDefault();});
      $(window).trigger( 'pageLoaded', index );
    }

    function pageShownFunc( index ) {

      Asteikko.log("Page " + index + " shown");

      if ( Asteikko.isDeveloper ) {
        $('#tplLabel > span').show().html( 
          issueMeta.pages[index].folder +
          " - " + 
          issueMeta.pages[index].template.charAt( 0 ).toUpperCase() + 
          issueMeta.pages[index].template.slice( 1 ) + ".tpl"
        );
      }

      $(window).trigger( 'pageShown', index );
    }

    function afterPageDisplayedFunc(index) {

      Asteikko.log("After page " + index + " Displayed" + issueMeta.pages[index].id + "()");

      if ( settings.highlights.enabled ) {
        // Fired with a timeout, in case some piece of external code adds
        // HTML nodes to the DOM _after_ the currently shown page's
        // Displayed#() function has been run, screwing up the highlight
        // offsets or making the highlight texts not match the document anymore.
        //
        // Even though we've made lots of hacky fixes to the Rangy library that
        // we use with text highlights, our solution is in some situations
        // still sensitive to the text offset of a highlight changing between
        // the moment the highlight is created and serialized, and the moment
        // when the highlights are deserialized and recreated in the DOM.
        //
        window.setTimeout( function() {
          Asteikko.highlightsUi.applyToPage( issueMeta.pages[index].id );
        }, 1000 );
      }
    }

    function pagePreRemoveFunc( index ) {
      $(window).trigger( 'pagePreRemove', index );
    }

    function swipeStartFunc() {
      // window.currentIndex is set and updated by Asteikko.pageScroller
      $(window).trigger( 'asmag_page_swipe_start', window.currentIndex );
    }

    function swipeEndFunc() {
      // window.currentIndex is set and updated by Asteikko.pageScroller
      $(window).trigger( 'asmag_page_swipe_end', window.currentIndex );
    }

    function getCachedHtmlFunc( index ) {

      if ( !issueMeta.pages[ index ] )
        return null;

      if (settings.offline.enabled && !Asteikko.isDeveloper) {

        var cachedPage = Asteikko.issueCache.getPage( issueMeta.id, issueMeta.pages[ index ].id );

        // We compare the timestamps of the locally cached page, and the page in
        // the issueMeta.pages array. If they differ, it means that our locally
        // cached page data is stale, and we will instead load this page from
        // the server (unless the browser is offline, in which case we use the
        // locally cached page content).
        if ( cachedPage && ( !Asteikko.online || String( cachedPage.modified ) === String( issueMeta.pages[ index ].modified ) ) ) {
          Asteikko.log( 'Returned up-to-date page '+ index +' from local cache' );
          return cachedPage.content;
        }
        else if ( !Asteikko.online ) {
          Asteikko.log( 'Page '+ index +' was not in local cache or it is stale, and the browser is not online' );
          return '<div class="noncachedpage">'+ settings.lang.base.offline.pagenotcached +'</div>';
        }
      }

      Asteikko.log( 'Page '+ index +' was not in local cache or it is stale. It will be downloaded from the server.' );

      return null;
    }

    var pageHtmlRequests = {};

    /**
     * @return {Promise}
     */
    function requestPageHtmlFunc( pageIndex ) {

      cancelPageHtmlRequestFunc( pageIndex );

      var xhr = pageHtmlRequests[ pageIndex ] = $.ajax( {
        url: issueMeta.pages[ pageIndex ].url,
        dataType: 'html'
      } );

      // Convert the jQuery Deferred into an ES6 Promise
      return Promise.resolve( xhr ).
        then( function( html ) {
          delete pageHtmlRequests[ pageIndex ];
          return html;
        } ).
        catch( function( reason ) {
          delete pageHtmlRequests[ pageIndex ];
          throw reason;
        } );
    }

    /**
     * @return {Boolean}
     */
    function cancelPageHtmlRequestFunc( pageIndex ) {

      if ( !pageHtmlRequests[ pageIndex ] )
        return false;

      var request = pageHtmlRequests[ pageIndex ];
      delete pageHtmlRequests[ pageIndex ];
      request.abort();

      return true;
    }

    var attemptedReloadAfter403 = false;

    // Try to reload issue after a 403; the issue page's download URL is 
    // possibly expired, so we'll reload to get fresh download URLs.
    function pageLoadErrorFunc( httpStatusCode ) {

      if ( httpStatusCode == 403 && !attemptedReloadAfter403 ) {
        attemptedReloadAfter403 = true;
        window.location.reload();
      }
    }

    if ( initialIndex || issueMeta.pages.length ) {

      var pageUrl = issueMeta.pages[ initialIndex ].permalink;

      Asteikko.history.replaceState( {
        issueId: issueMeta.id,
        pageId: issueMeta.pages[ initialIndex ].id
      }, '', window.location.protocol + pageUrl.replace( /^https?:/, '' ) );

      document.title = issueMeta.pages[ initialIndex ].title +' - '+ 
        issueMeta.name +' - '+ Asteikko.env.site_name;
    }
    else {

      Asteikko.history.replaceState( { issueId: issueMeta.id }, '',
        window.location.protocol + issueMeta.permalink.replace( /^https?:/, '' ) );

      document.title = issueMeta.name +' - '+ Asteikko.env.site_name;
    }

    // ----------------- Init: page changer: AsteikkoScroller -----------------

    var isSlowBrowser = !asmagModernizr.csstransforms || !asmagModernizr.csstransitions;

    if ( !isSlowBrowser ) {

      Asteikko.pageScroller.create( {
        initialIndex: initialIndex,
        containerId: 'mag',
        pageClass: 'asmag-swipeview-page',
        numberOfPages: issueMeta.pages.length,
        requestPageHtmlFunc: requestPageHtmlFunc,
        cancelPageHtmlRequestFunc: cancelPageHtmlRequestFunc,
        indexChangedFunc: indexChangedFunc,
        pageFinishLoadFunc: pageFinishLoadFunc,
        pageShownFunc: pageShownFunc,
        afterPageDisplayedFunc: afterPageDisplayedFunc,
        getCachedHtmlFunc: getCachedHtmlFunc,
        pagePreRemoveFunc: pagePreRemoveFunc,
        pageLoadErrorFunc: pageLoadErrorFunc,
        swipeStartFunc: swipeStartFunc,
        swipeEndFunc: swipeEndFunc,
        maxColumns: issueMeta.max_column_count,
        minColumnWidth: issueMeta.min_column_width,
        disablePageFlip: disableDefaultPageFlip
      } );

      // When client comes back online, refresh images and pages
      $( window ).unbind( 'asmag_connectivity.asmagissue' );
      $( window ).bind( 'asmag_connectivity.asmagissue', function( event, isOnline ) {

        if ( !window.pages || !settings.offline.enabled || !Asteikko.online )
          return;

        // Load images from web
        $("#asmag-issue .asmag-page img").each( function(e){
          var img = $(this);
          var source = img.attr( 'src' );
          var origSource = img.attr( 'data-origsrc' );
          if ( origSource && source !== origSource ) {
            img.attr( 'src', origSource );
          }
        } );

        // Load pages from web for non-cached pages
        for (var i = currentIndex - 1; i <= currentIndex + 1; i++) {
          var pageIndex = ( i < 0 ) ? pages.length + i : i;
          if ( pageIndex > pages.length - 1 ) {
            pageIndex = pageIndex - ( pages.length );
          }
          if ( !$( '#asmag-swipeview-page-'+ pageIndex ).has( '.noncachedpage' ) ) continue;
          $.ajax({
            url: pages[pageIndex].url,
            dataType: "html",
            success: ( function( pageIndex ) {
              return function(data) {
                Asteikko.pageScroller.showPageForHtml(pageIndex, data);
              };
            } )( pageIndex )
          });
        }
      } );
    }
    // ----------- Init: page changer for old/slow browsers -----------
    else {
      Asteikko.pageSwitcher.create( {
        initialIndex: initialIndex,
        containerId: 'mag',
        pageClass: 'asmag-pageswitcher-page',
        indexChangedFunc: indexChangedFunc,
        pageFinishLoadFunc: pageFinishLoadFunc,
        pageShownFunc: pageShownFunc,
        afterPageDisplayedFunc: afterPageDisplayedFunc,
        getCachedHtmlFunc: getCachedHtmlFunc,
        pagePreRemoveFunc: pagePreRemoveFunc,
        pageLoadErrorFunc: pageLoadErrorFunc,
        disablePageFlip: disableDefaultPageFlip
      } );
    }

    $( document ).off( 'keyup.asmagreaderpageflip' );

    if ( !disableDefaultPageFlip ) {
      $( document ).on( 'keyup.asmagreaderpageflip', function(event) {
        if (event.keyCode == 39) {
          if ($(".ps-carousel").length == 0)
            issueNextPage();
        }
        if (event.keyCode == 37) {
          if ($(".ps-carousel").length == 0)
            issuePrevPage();
        }
      } );
    }
  };

  Asteikko.reader._setMetaViewport = function() {

    // width=device-width causes the iPhone 5 to letterbox the app, so
    // we want to exclude it for iPhone 5 to allow full screen apps
    if ( window.screen.height === 568 ) // iPhone 5
      var newViewport = 'minimum-scale=1.0, maximum-scale=1.0, initial-scale=1.0, user-scalable=no';
    else
      var newViewport = 'width=device-width, minimum-scale=1.0, maximum-scale=1.0, initial-scale=1.0, user-scalable=no';

    var metaViewport = $( 'head meta[name="viewport"][content][content!=""]' ).last();
    if ( metaViewport.length ) {
      if ( !this._previousMetaViewport )
        this._previousMetaViewport = metaViewport.attr( 'content' );
      metaViewport.attr( 'content', newViewport );
    }
    else {
      $( 'head' ).append( '<meta name="viewport" content="'+ newViewport +'">' );
    }
  };

  Asteikko.reader._restoreMetaViewport = function() {

    if ( this._previousMetaViewport )
      $( 'head meta[name="viewport"]' ).last().attr( 'content', this._previousMetaViewport );
    else
      $( 'head meta[name="viewport"]' ).last().remove();

    this._previousMetaViewport = null;
  };



  /* ---------------------------------------------------------------------------
    TODO: Functions below are not in the Asteikko.reader namespace for legacy
    reasons (external code still calls them). We should move them there ASAP.
  --------------------------------------------------------------------------- */

  Asteikko.onlineFuncs.loadMagazine = function( issueId, pageId, callback ) {

    var cachedIssueMeta = Asteikko.issueCache.getIssueMeta( issueId );
    var cachedPageCount = Asteikko.issueCache.getCachedPageCount( cachedIssueMeta );
    var issueWrapper = Asteikko.reader._initReader();

    Asteikko.createCookie( 'asmag_last_accessed_issue', issueId );
    Asteikko.reader._setMetaViewport();

    $('body, html').addClass('asmag-active');
    $('body').append('<div id="asmag-issue-loading"></div>');

    Asteikko.log('loadMagazine ' + issueId);

    // We init analytics at the latest possible moment, as doing that sends
    // an HTTP request, and we don't want any redundant requests on page load
    AsteikkoAnalytics.init( Asteikko.env.blog_id, Asteikko.env.analytics.gaVersion );

    // The whole magazine loading is wrapped into a getUserInfo() call, to make 
    // sure that the user session values (isDeveloper, lastLoginStatus) are fresh
    Asteikko.getUserInfo( onGetUserInfo );

    function onGetUserInfo( userInfo ) {

      if ( userInfo 
        && settings.offline.enabled 
        && Asteikko.localStorage.isActive()
        && !Asteikko.isDeveloper ) {

        // Whole issue is in localStorage, check if we should update it
        if ( cachedIssueMeta && cachedIssueMeta.pages.length == cachedPageCount ) {
          Asteikko.log( 'Found full issue data in cache. Checking if we should re-download.' );
          getIssueMetadata( onGetMetadataForUpdateCheck );
        }
        // Missing issue data in localStorage, or not all pages have been 
        // downloaded to localStorage
        else {
          Asteikko.log( 'Found missing or partial issue data in cache, will download new content' );
          getIssueMetadata( onGetMetadataForDownloading );
        }
      }
      // Local data saving is not enabled, so show the issue from server and 
      // don't download any data to localStorage
      else if ( userInfo ) {
        Asteikko.log( 'Bypassing issue cache data check. Fetching data directly from server.' );

        if ( userInfo.editorCapable && settings.editor_notes.enabled ) {
          Asteikko.reader._seeAdminBar = true;
        }

        getIssueMetadata( showIssueFromServer );
      }
      // If user info check failed, we assume we're offline
      else {
        Asteikko.offlineFuncs.loadMagazine( issueId, pageId, callback );
      }
    }

    function getIssueMetadata( onDone ) {

      $.get( Asteikko.env.site_url +'/asmag/issue/?id='+ issueId, undefined, undefined, 'json' ).
        done( onDone ).
        fail( onFail );

      function onFail( jqXHR, textStatus, errorThrown ) {

        Asteikko.closeMagazine();

        if ( jqXHR.status === 401 || jqXHR.status === 403 ) {
          if ( Asteikko.lastLoginStatus )
            Asteikko.showIssueLoggedInPaywall( issueId );
          else
            Asteikko.showIssuePaywall( issueId );
        }
      }
    }

    function onGetMetadataForUpdateCheck( issueMeta ) {

      // Issue has been updated, so show the issue from server and download the new version
      if ( issueMeta.revision != cachedIssueMeta.revision || issueMeta.build != cachedIssueMeta.build ) {

        Asteikko.log( 'Revision or build updated, current rev '+ cachedIssueMeta.revision +' vs server '+ issueMeta.revision +
          ', current build '+ cachedIssueMeta.build +' vs server ' + issueMeta.build );

        showIssueFromServer( issueMeta );
        if ( issueMeta.user_can_access )
          Asteikko.issueDownloader.downloadForMetadata( issueMeta );
      }
      // Issue has not been updated, so use the version from localStorage
      else {

        Asteikko.log( 'Revision and build match, current rev '+ cachedIssueMeta.revision +' vs server '+ issueMeta.revision +
          ', current build '+ cachedIssueMeta.build +' vs server ' + issueMeta.build +'. Showing from localStorage.' );

        showIssueCssFromCache( cachedIssueMeta );
        Asteikko.reader._showReader( issueWrapper, cachedIssueMeta, pageId );
        fireCallback();
      }
    }

    function onGetMetadataForDownloading( issueMeta ) {

      showIssueFromServer( issueMeta );
      if ( issueMeta.user_can_access )
        Asteikko.issueDownloader.downloadForMetadata( issueMeta );
    }

    function showIssueFromServer( issueMeta ) {

      showIssueCssFromServer( issueMeta );
      Asteikko.reader._showReader( issueWrapper, issueMeta, pageId, Asteikko.reader._seeAdminBar );
      fireCallback();
    }

    function showIssueCssFromServer( issueMeta ) {

      if ( !issueMeta.css_url )
        return;

      // Add a cachebuster in case the browser caches the CSS file
      var cacheBust = ( new Date() ).getTime();
      var cssUrl = ( issueMeta.css_url.indexOf( '?' ) === -1 )
        ? issueMeta.css_url += '?'+ cacheBust +'='+ cacheBust
        : issueMeta.css_url += '&'+ cacheBust +'='+ cacheBust;

      $.get( cssUrl, undefined, undefined, 'text' ).
        done( function( css ) {

          if ( settings.offline.enabled 
            && Asteikko.localStorage.isActive()
            && !Asteikko.isDeveloper )
          {
            Asteikko.issueCache.saveIssueCss( issueMeta.id, css );
          }

          $( "#issue-css" ).html( css );
        } );
    }

    function showIssueCssFromCache( issueMeta ) {

      var css = Asteikko.issueCache.getIssueCss( issueMeta.id );
      if ( css )
        $( "#issue-css" ).html( css );
    }

    function fireCallback() {
      if ( typeof callback === 'function' ) {
        callback( parseInt( issueId, 10 ), parseInt( pageId, 10 ) );
      }
    }
  };

  Asteikko.offlineFuncs.loadMagazine = function( issueId, pageId, callback ) {

    var cachedIssueMeta = Asteikko.issueCache.getIssueMeta( issueId );
    var cachedPageCount = Asteikko.issueCache.getCachedPageCount( cachedIssueMeta );
    var issueWrapper = Asteikko.reader._initReader();

    Asteikko.createCookie( 'asmag_last_accessed_issue', issueId );
    Asteikko.reader._setMetaViewport();

    $('body, html').addClass('asmag-active');
    $('body').append('<div id="asmag-issue-loading"></div>');

    Asteikko.log('loadMagazine ' + issueId);

    if ( !cachedPageCount ) {

      Asteikko.closeMagazine();
      Asteikko.log('Issue '+ issueId +' is not cached');
      alert(settings.lang.base.offline.errornoissuedlorselect);
    }
    else {

      Asteikko.log('Load issue '+ issueId +' from local storage');

      var css = Asteikko.issueCache.getIssueCss( issueId );
      if ( css )
        $( "#issue-css" ).html( css );

      Asteikko.reader._showReader( issueWrapper, cachedIssueMeta, pageId );
      if ( typeof callback === 'function' ) {
        callback( parseInt( issueId, 10 ), parseInt( pageId, 10 ) );
      }
    }
  };

  Asteikko.closeMagazine = function() {
    Asteikko.reader._unloadReader( true );
  };

  Asteikko.openShare = function(shareto) {

    var issuePrice = ( window.asteikkoIssue && asteikkoIssue.paid ) ? 'paid' : 'free';
    var issueTags = ( window.asteikkoIssue && asteikkoIssue.tags ) ? asteikkoIssue.tags : [];
    var issueName = ( window.asteikkoIssue && asteikkoIssue.name ) ? asteikkoIssue.name : '';
    var pageTitle = ( window.pages && pages[currentIndex] ) ? pages[currentIndex].title : '';

    var gourl = "";

    if (shareto == 'facebook') {
      AsteikkoAnalytics.sendEvent( 'AsteikkoMag', 'Share', 'Facebook', undefined, asteikkoIssue.id, pages[currentIndex].id, issuePrice, issueTags.join( ',' ) );
      gourl = "http://www.facebook.com/sharer.php?u="+encodeURIComponent(pages[currentIndex].share_url)+"%23share&t="+encodeURIComponent(pageTitle +' - '+ issueName +' - '+ Asteikko.env.site_name)+"&src=sp";
    }
    else if (shareto == "twitter") {
      AsteikkoAnalytics.sendEvent( 'AsteikkoMag', 'Share', 'Twitter', undefined, asteikkoIssue.id, pages[currentIndex].id, issuePrice, issueTags.join( ',' ) );
      gourl = "https://twitter.com/intent/tweet?source=tweetbutton&text="+encodeURIComponent(pageTitle +' - '+ issueName +' - '+ Asteikko.env.site_name)+"&url="+encodeURIComponent(pages[currentIndex].share_url);
    }
    else if (shareto == "linkedin") {
      AsteikkoAnalytics.sendEvent( 'AsteikkoMag', 'Share', 'LinkedIn', undefined, asteikkoIssue.id, pages[currentIndex].id, issuePrice, issueTags.join( ',' ) );
      gourl = "https://www.linkedin.com/shareArticle?mini=true&title="+ 
        encodeURIComponent( ( pageTitle +' - '+ issueName +' - '+ Asteikko.env.site_name ).substr( 0, 200 ) ) +
        "&source=Asteikko%20Magazine&url="+ encodeURIComponent( pages[currentIndex].share_url );
    }
    else if (shareto == 'googleplus') {
      AsteikkoAnalytics.sendEvent( 'AsteikkoMag', 'Share', 'Google Plus', undefined, asteikkoIssue.id, pages[currentIndex].id, issuePrice, issueTags.join( ',' ) );
      gourl = "https://plus.google.com/share?url="+ encodeURIComponent( pages[currentIndex].share_url );
    }

    // This method of opening 'mailto:' doesn't work on iOS8. mailto should be used with A elements.
    /**
     * @deprecated since 5.0.0
     */
    if (shareto == "email") {
      Asteikko.warn( "Called deprecated Asteikko.openShare() method 'email'. Use the 'mailto:' scheme with 'a' elements instead." );
      return;
    }

    window.open(gourl);
  };

  /**
   * Should be bound to links in issue pages. Used to gather click statistics.
   */
  Asteikko.onClickLink = function( event ) {

    var href = $( this ).attr( 'href' ) || '';

    // Ignore hash links
    if ( href.indexOf( '#' ) === 0 )
      return true;

    if ( Asteikko.reader.goToPageByPermalink( href ) ) {
      event.preventDefault();
      return false;
    }

    var issueId = ( window.asteikkoIssue ) ? asteikkoIssue.id : undefined;
    var issuePrice = ( window.asteikkoIssue && asteikkoIssue.paid ) ? 'paid' : 'free';
    var issueTags = ( window.asteikkoIssue && asteikkoIssue.tags ) ? asteikkoIssue.tags : [];
    var pageId = ( window.pages && pages[currentIndex] ) ? pages[currentIndex].id : undefined;
    AsteikkoAnalytics.sendEvent( 'AsteikkoMag', 'Open link', href, undefined, issueId, pageId, issuePrice, issueTags.join( ',' ) );

    return true;
  };

  function onClickMailtoShareLink() {

    var issuePrice = ( window.asteikkoIssue && asteikkoIssue.paid ) ? 'paid' : 'free';
    var issueTags = ( window.asteikkoIssue && asteikkoIssue.tags ) ? asteikkoIssue.tags : [];

    AsteikkoAnalytics.sendEvent( 'AsteikkoMag', 'Share', 'E-mail', undefined, asteikkoIssue.id, pages[currentIndex].id, issuePrice, issueTags.join( ',' ) );
  }

  function updateMailtoLinksOnPageChange( event, oldIndex, newIndex ) {

    var issueName = ( window.asteikkoIssue && asteikkoIssue.name ) ? asteikkoIssue.name : '';
    var pageTitle = ( window.pages && pages[currentIndex] ) ? pages[currentIndex].title : '';

    $( '#asmag-issue a.shareEmail' ).
      attr( 'href', "mailto:?subject="+
        encodeURIComponent(pageTitle +' - '+ issueName +' - '+ 
        Asteikko.env.site_name)+"&body=" + encodeURIComponent( 
        settings.lang.base.ui.sharemailprelink + pages[currentIndex].share_url + 
        settings.lang.base.ui.sharemailaftlink) ).
      off( 'click.asmag_sharemailto' ).
      on( 'click.asmag_sharemailto', onClickMailtoShareLink );
  }


  /*****************************************************************************
    Offline image fallbacks
  *****************************************************************************/

  var lastOfflineImageRenderTime = 0;
  var offlineImageCanvasTimeout = null;

  function scheduleRemoveOfflineImageCanvas() {
    window.clearTimeout( offlineImageCanvasTimeout );
    offlineImageCanvasTimeout = window.setTimeout( function() {
      $( '#asmag-imagerendercanvas' ).remove();
    }, 5000 );
  }

  Asteikko.reader._getOfflineImageDataUrl = function( width, height ) {

    if ( !asmagModernizr.canvas ) return '';

    var maxSideLength = 1200;
    var cacheSideThreshold = 25;
    var cacheByteLimit = 1000000;

    if ( width > maxSideLength || height > maxSideLength ) {
      if ( width > height ) {
        height = height * ( maxSideLength / width );
        width = maxSideLength;
      }
      else {
        width = width * ( maxSideLength / height );
        height = maxSideLength;
      }
    }

    width = Math.round( width );
    height = Math.round( height );

    // Try to find a data URL of an image with similar dimensions from the cache.
    // cacheSideThreshold determines how lenient the selection is (e.g 
    // cacheSideThreshold 10 selects a cached image if its width and height are 
    // inside +-10 pixels of the given width and height arguments).
    var src = '';
    if ( Asteikko.reader._offlineImageDataUrls[ width ] && Asteikko.reader._offlineImageDataUrls[ width ][ height ] ) {
      src = Asteikko.reader._offlineImageDataUrls[ width ][ height ];
    }
    else {
      for ( var i = 1; i <= cacheSideThreshold; i++ ) {
        for ( var x = -i; x <= i; x++ ) {
          if ( Asteikko.reader._offlineImageDataUrls[ width + x ] && Asteikko.reader._offlineImageDataUrls[ width + x ][ height + i ] ) {
            src = Asteikko.reader._offlineImageDataUrls[ width + x ][ height + i ];
            break;
          }
          if ( src ) break;
          if ( Asteikko.reader._offlineImageDataUrls[ width + x ] && Asteikko.reader._offlineImageDataUrls[ width + x ][ height - i ] ) {
            src = Asteikko.reader._offlineImageDataUrls[ width + x ][ height - i ];
            break;
          }
          if ( src ) break;
        }
        if ( src ) break;
        for ( var y = -i + 1; y < i; y++ ) {
          if ( Asteikko.reader._offlineImageDataUrls[ width + i ] && Asteikko.reader._offlineImageDataUrls[ width + i ][ height + y ] ) {
            src = Asteikko.reader._offlineImageDataUrls[ width + i ][ height + y ];
            break;
          }
          if ( src ) break;
          if ( Asteikko.reader._offlineImageDataUrls[ width - i ] && Asteikko.reader._offlineImageDataUrls[ width - i ][ height + y ] ) {
            src = Asteikko.reader._offlineImageDataUrls[ width - i ][ height + y ];
            break;
          }
          if ( src ) break;
        }
        if ( src ) break;
      }
    }

    // Draw the offline image using canvas, if we found no cached data URL
    if ( !src ) {
      var canvas = document.getElementById( 'asmag-imagerendercanvas' );
      if ( !canvas ) {
        canvas = $( '<canvas id="asmag-imagerendercanvas"></canvas>' ).
          appendTo( 'body' ).
          hide().
          get( 0 );
      }
      $( canvas ).attr( 'width', width );
      $( canvas ).attr( 'height', height );
      var ctx = canvas.getContext( '2d' );
      ctx.fillStyle = '#EEE';
      ctx.fillRect( 0, 0, width, height );
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#DDD';
      ctx.beginPath();
      ctx.moveTo( -1, -1 );
      ctx.lineTo( width + 1, height + 1 );
      ctx.moveTo( -1, height + 1 );
      ctx.lineTo( width + 1, -1 );
      ctx.stroke();
      ctx.font = '18px sans-serif';
      ctx.textAlign = 'center';
      ctx.fillStyle = '#999';
      ctx.fillText( settings.lang.base.offline.imgrequiresconnection, 
        Math.round( width / 2 ), Math.round( height / 2 ), width );
      src = canvas.toDataURL( 'image/jpeg' );
      var cacheSize = Asteikko.util.roughSizeOfObject( Asteikko.reader._offlineImageDataUrls ) + 
        ( src.length * 2 );
      //Asteikko.log( 'offline image cache size: '+ cacheSize );
      // Only store this data URL in cache if we won't exceed cache size limit
      if ( cacheSize <= cacheByteLimit ) {
        if ( !Asteikko.reader._offlineImageDataUrls[ width ] ) Asteikko.reader._offlineImageDataUrls[ width ] = {};
        Asteikko.reader._offlineImageDataUrls[ width ][ height ] = src;
      }
      lastOfflineImageRenderTime = ( new Date() ).getTime();
      scheduleRemoveOfflineImageCanvas();
    }
    return src;
  };

  Asteikko.reader.init = function() {

    $( window ).on( 'pageShown', updateMailtoLinksOnPageChange );
    $( document.body ).on( 'click', '#asmag-issue a', Asteikko.onClickLink );

    $(window).on("debouncedresize", function() {
      $('.asmag-notificationBox').center();
      Asteikko.reader.centerMagazine( Asteikko.reader._seeAdminBar );
      Asteikko.reader.refreshMagazineSize();
    });
  };

} )();



/****************************************************************************
    Global functions (FIXME: deprecate and move these to Asteikko.reader)
 ****************************************************************************/

function issueNextPage() {

  if ( !window.pages || !window.pages.length )
    return;

  if (typeof pageScroller != "undefined") {
    pageScroller.next();
  } else {
    Asteikko.pageSwitcher.switchNext();
  }
}

function issuePrevPage() {

  if ( !window.pages || !window.pages.length )
    return;

  if (typeof pageScroller != "undefined") {
    pageScroller.prev();
  } else {
    Asteikko.pageSwitcher.switchPrevious();
  }
}

function goToPageById( pageId ) {

  if ( !pageId || !window.pages || !window.pages.length )
    return false;

  var goToPageIndex = -1;
  for ( var i = 0; i < pages.length; i++ ) {
    if ( parseInt( pages[ i ].id, 10 ) === pageId ) {
      goToPageIndex = i;
      break;
    }
  }

  if ( goToPageIndex === -1 )
    return false;

  if ( window.pageScroller ) {

    var swipeviewPage = Asteikko.pageScroller.getSwipeviewPageForSubPage( goToPageIndex );
    var currSwipeviewPage = Asteikko.pageScroller.getSwipeviewPageForSubPage( currentIndex );

    if ( swipeviewPage != currSwipeviewPage ) {
      if ( currSwipeviewPage - swipeviewPage === -1 )
        issueNextPage();
      else if ( currSwipeviewPage - swipeviewPage === 1 )
        issuePrevPage();
      else
        pageScroller.goToPage( swipeviewPage );
    }
  }
  else if ( goToPageIndex > -1 ) {
    if ( currentIndex - goToPageIndex === -1 ) {
      issueNextPage();
    }
    else if ( currentIndex - goToPageIndex === 1 ) {
      issuePrevPage();
    }
    else if ( currentIndex != goToPageIndex ) {
      Asteikko.pageSwitcher.switchToIndex( goToPageIndex );
    }
  }

  return true;
}

