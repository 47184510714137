
if ( !window.AsteikkoArticleUi ) ( function( $ ) {
  // Immediately Invoked Function Expression: start

  "use strict";
  
  if ( typeof $ !== "function" || typeof $.prototype.jquery !== "string" ) {
    throw( "AsteikkoArticleUi.js: jQuery is not loaded" );
  }
  
  $( document ).keyup( function( e ) {
    // Esc
    if ( e.keyCode === 27 ) {
      AsteikkoArticleUi.closeOverlaysInside( "#asmag-issue" );
    }
  } );
  
  function onOverlayShown() {
    $( this ).trigger( 'AsteikkoArticleUi-overlayShown' );
  }
  function onOverlayHidden() {
    $( this ).trigger( 'AsteikkoArticleUi-overlayHidden' );
  }

  var AsteikkoArticleUi = {};

  AsteikkoArticleUi.overlayFadeInSpeed = 300;
  AsteikkoArticleUi.overlayFadeOutSpeed = 200;

  /*
   * Opens an overlay with the given id.
   */
  AsteikkoArticleUi.openOverlay = function( overlayId ) {
    
    var closeButton;
    var isOpen;
    var overlay = $( "#"+ overlayId );
    if ( !overlay.length ) return;
    
    isOpen = overlay.data( "is_open" );
    if ( isOpen ) return;
    
    // Close all other overlays
    AsteikkoArticleUi.closeOverlaysInside( overlay.closest( ".asmag-page" ) );
    
    // Create the close-button if it doesn't exist yet
    closeButton = overlay.find( ".close-button" ).eq( 0 );
    if ( !closeButton.length ) {
      closeButton = $( "<div class='close-button fa fa-times-circle' />" ).prependTo( overlay );
      closeButton.click( function() {
        AsteikkoArticleUi.closeOverlay( overlayId );
      } );
      new NoClickDelay( closeButton.get( 0 ) );
    }
    
    overlay.removeData( "is_open" );
    overlay.data( "is_open", true );
    overlay.fadeIn( AsteikkoArticleUi.overlayFadeInSpeed, onOverlayShown );
    // overlay.show();
  };

  /*
   * Closes an overlay that is bound to this button.
   */
  AsteikkoArticleUi.closeOverlay = function( overlayId ) {
    
    var isOpen;
    var overlay = $( "#"+ overlayId );
    if ( !overlay.length ) return;
    
    isOpen = overlay.data( "is_open" );
    if ( !isOpen ) return;
    
    overlay.removeData( "is_open" );
    overlay.data( "is_open", false );
    overlay.fadeOut( AsteikkoArticleUi.overlayFadeOutSpeed, onOverlayHidden );
    // overlay.hide();
  };
  
  AsteikkoArticleUi.closeOverlaysInside = function( parentElem ) {
    
    var parent = $( parentElem );
    if ( !parent.length ) return;
    parent.
      find( ".overlay" ).
      hide().
      removeData( "is_open" ).
      data( "is_open", false ).
      each( function() {
        if ( $( this ).is( ':visible' ) ) {
          onOverlayHidden.call( this );
        }
      } );
  };
  
  AsteikkoArticleUi.toggleOverlay = function( overlayId ) {
    
    var isOpen;
    var overlay = $( "#"+ overlayId );
    if ( !overlay.length ) return;
    
    isOpen = overlay.data( "is_open" );
    if ( isOpen ) AsteikkoArticleUi.closeOverlay( overlayId );
    else AsteikkoArticleUi.openOverlay( overlayId );
  };

  /*
   * Creates a PhotoSwipe 3 gallery given a set of a-elements. linkElems can be 
   * a jQuery selector, a set of HTML elements or a jQuery result set.
   */
  AsteikkoArticleUi.createPSGallery = function( linkElems ) {
    
    linkElems = $( linkElems ).
      filter( "a" ).
      has( 'img' ).
      filter( function( index, elem ) {
        return Boolean(
          $( this ).attr( 'href' ).match( /(jpg|jpeg|gif|png)$/i )
          && $( this ).attr( 'href' ).match( /\/asteikko_issues\//i )
        );
      } );

    if ( !linkElems.length ) return;
    
    var ps;
    var pageElem;
    var pageElemId;
    var psTargetContainer;
    var psTarget;
    
    if ( linkElems.length ) {
      pageElem = linkElems.eq( 0 ).closest( ".asmag-page" );
      if ( !pageElem.length ) throw( "AsteikkoArticleUi.createPSGallery: No page elem found" );
      psTargetContainer = pageElem.find( ".ps-target-container" );
      // If the page has no PhotoSwipe target, use a global target. Androids 
      // will always use window as the target.
      if ( $( ".isandroid" ).length ) {
        psTarget = window;
      }
      else if ( !psTargetContainer.length ) {
        psTarget = $( "#asteikkoarticleui-ps-target-global" );
        psTargetContainer = $( "#asteikkoarticleui-ps-target-global-container" );
        // Create the global PS target if it doesn't exist
        if ( !psTarget.length ) {
          psTargetContainer = $( "<div id='asteikkoarticleui-ps-target-global-container' /> )" ).
            append( $( "<div id='asteikkoarticleui-ps-target-global' />" ) ).
            appendTo( "#asmag-issue" );
          psTarget = $( "#asteikkoarticleui-ps-target-global" );
          // FIXME: native iPad app doesn't have #ajax-magazine...
          if ( !psTarget.length ) psTarget = window;
        }
      }
      else {
        psTarget = psTargetContainer.find( ".ps-target" );
        psTarget.unbind( "touchstart mousedown" ).bind( "touchstart mousedown", 
          function( event ) {
            event.stopPropagation();
          } );
      }
      ps = linkElems.photoSwipe( {
        target: psTarget,
        loop: false,
        enableMouseWheel: true , 
        captionAndToolbarFlipPosition: true, 
        enableKeyboard: true , 
        maxUserZoom: '4.0' , 
        minUserZoom: '1.0' , 
        doubleTapZoomLevel: 4,
        captionAndToolbarAutoHideDelay: '0' , 
        swipeThreshold: '150' , 
        swipeTimeThreshold: '200' , 
        preventSlideshow: true, 
        captionAndToolbarShowEmptyCaptions: true, 
        imageScaleMethod: 'fitNoUpscale',
        zIndex: 300,
        getImageCaption: function(el){
          if (el.nodeName === "IMG"){
            //var caption = htmlspecialchars_decode( window.Code.Util.DOM.getAttribute(el, 'alt') );
            var caption = window.Code.Util.DOM.getAttribute(el, 'alt');
            return $('<span/>').append(caption).get(0);
          }
          var i, j, childEl;
          for (i=0, j=el.childNodes.length; i<j; i++){
            childEl = el.childNodes[i];
            if (el.childNodes[i].nodeName === 'IMG'){
              //var caption = htmlspecialchars_decode( window.Code.Util.DOM.getAttribute(childEl, 'alt') );
              var caption = window.Code.Util.DOM.getAttribute(childEl, 'alt');
              return $('<span/>').append(caption).get(0);
            }
          }
        }
      } );
      if ( psTargetContainer.length && psTarget !== window ) {
        ps.addEventHandler( window.Code.PhotoSwipe.EventTypes.onHide, function( e ) {
          psTarget.empty();
          psTargetContainer.css( "display", "none" );
        } );
        ps.addEventHandler( window.Code.PhotoSwipe.EventTypes.onBeforeShow, function( e ) {
          psTargetContainer.fadeIn( 200 );
        } );
      }
    }
  };
  
  /*
   * Creates a PhotoSwipe 4 gallery given a set of a-elements. linkElems can be 
   * a jQuery selector, a set of HTML elements or a jQuery result set.
   */
  AsteikkoArticleUi.createPS4Gallery = function( linkElems ) {
    
    linkElems = $( linkElems ).
      filter( "a" ).
      has( 'img' ).
      filter( function( index, elem ) {
        return Boolean(
          $( this ).attr( 'href' ).match( /(jpg|jpeg|gif|png)$/i )
          && $( this ).attr( 'href' ).match( /\/asteikko_issues\//i )
        );
      } );

    if ( !linkElems.length )
      return;
    
    linkElems.addClass( 'ps4-link' );

    if ( !$( '.pswp' ).length ) {
      $( '#asmag-issue' ).append( Asteikko.ui.renderTemplate( 'photoswipe4', {} ) );
    }

    var pswpElement = $( '.pswp' )[ 0 ];
    var items = [];

    for ( var i = 0; i < linkElems.length; i++ ) {

      var linkElem = linkElems.eq( i );
      var href = linkElem.attr( 'href' );
      var img = linkElem.find( 'img' );

      if ( window.asteikkoIssue.image_sizes && 
        asteikkoIssue.image_sizes[ href ] ) 
      {
        items.push( {
          src: href,
          msrc: img.attr( 'src' ),
          title: img.attr( 'alt' ),
          w: asteikkoIssue.image_sizes[ href ].width,
          h: asteikkoIssue.image_sizes[ href ].height
        } );
      }
      else {
        items.push( {
          src: href,
          msrc: img.attr( 'src' ),
          title: img.attr( 'alt' ),
          w: 150,
          h: 150
        } );
      }
    }

    function getThumbBoundsFn( index ) {

      var linkElem = linkElems.eq( index );
      var pos = linkElem.offset();
      return {
        x: pos.left,
        y: pos.top,
        w: linkElem.find( 'img' ).width()
      };
    }

    function onClickAElem( event ) {

      event.preventDefault();

      var href = $( this ).attr( 'href' );

      for ( var i = 0; i < linkElems.length; i++ ) {
        if ( linkElems.eq( i ).attr( 'href' ) === href )
          break;
      }

      var options = {
        index: i,
        hideAnimationDuration: 500,
        showAnimationDuration: 500,
        showHideOpacity: true,
        bgOpacity: 0.9,
        loop: false,
        pinchToClose: true,
        tapToClose: false,
        closeOnVerticalDrag: false,
        closeOnScroll: false,
        arrowKeys: false,
        getThumbBoundsFn: getThumbBoundsFn,
        tapToToggleControls: false,
        closeEl: true,
        captionEl: true,
        fullscreenEl: true,
        zoomEl: true,
        shareEl: false,
        counterEl: true,
        arrowEl: true,
        preloaderEl: true
      };

      var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
      gallery.init();

      return false;
    }

    linkElems.click( onClickAElem );
  };
  

  window.AsteikkoArticleUi = AsteikkoArticleUi;

  // Immediately Invoked Function Expression: end
} )( window.jQuery );
