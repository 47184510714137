/*******************************************************************************
  Caching and offline related functionality
*******************************************************************************/

if ( !window.Asteikko ) window.Asteikko = {};

/*******************************************************************************

Asteikko.localStorage: browser localStorage wrapper

Notice that HTTP and HTTPS sites have their own sets of localStorage. We cannot
share them, and we shouldn't, as mixed content (HTTP and HTTPS resources) on
one site causes problems because of browser security restrictions.

*******************************************************************************/

( function() {

  "use strict";
  
  var isModuleActive = false;
  var lzstringWorker = null;
  
  Asteikko.localStorage = {
    
    init: function() {
      
      var hasLocalStorage = false;
      // If we disable cookies in Chrome or FF, accessing window.localStorage throws an error
      try {
        hasLocalStorage = ( typeof window.localStorage === 'object'
          && typeof localStorage.getItem === 'function' );
      }
      catch( e ) {}

      if ( !hasLocalStorage )
        return false;
      if ( isModuleActive )
        throw( 'Module is already active' );

      isModuleActive = true;
      // We use operative.js for abstracting Web Worker functionality.
      // lz-string.js is a dependency.
      lzstringWorker = operative( Asteikko.worker.lzstring,
        [ 'lz-string.js?ver='+ Asteikko.env.build ] );
      
      return true;
    },
    
    isActive: function() {
      return isModuleActive;
    },
    
    hasSpace: function() {

      if ( !isModuleActive )
        return false;

      var ret = false;
      try {
        window.localStorage.setItem( "_asteikko", "m" );
        ret = true;
      }
      catch(e) {}
      try {
        window.localStorage.removeItem( "_asteikko" );
      }
      catch(e) {}
      return ret;
    },
    
    has: function( name ) {

      if ( !isModuleActive )
        return false;

      return window.localStorage.hasOwnProperty( name ) &&
        window.localStorage.getItem( name ) !== null;
    },
    
    get: function( name ) {

      if ( !isModuleActive )
        return null;
      
      try {
        var value = window.localStorage.getItem( name );
        if ( value && value.substr( 0, 4 ) === 'lzs|' )
          return LZString.decompressFromUTF16( value.substr( 4 ) );
        else
          return value;
      }
      catch ( e ) {
        Asteikko.error( e );
        return null;
      }
    },
    
    getAsync: function( name, callback ) {

      if ( typeof callback !== 'function' ) {
        Asteikko.error( 'Calling getAsync() without a callback is a no-op' );
        return;
      }

      if ( !isModuleActive ) {
        callback( null );
        return;
      }

      try {
        var value = window.localStorage.getItem( name );
      }
      catch ( e ) {
        Asteikko.error( e );
        callback( null );
        return;
      }

      if ( value && value.substr( 0, 4 ) === 'lzs|' ) {
        lzstringWorker.decompressFromUTF16( value.substr( 4 ), function( ret ) {
          callback( ret );
        } );
      }
      else {
        callback( value );
      }
    },

    set: function( name, value ) {

      if ( !isModuleActive )
        return false;

      value = String( value );

      try {
        window.localStorage.setItem( name, 'lzs|'+ LZString.compressToUTF16( value ) );
        return true;
      }
      catch( e ) {
        Asteikko.error( e );
        return false;
      }
    },

    setAsync: function( name, value, callback ) {

      if ( !isModuleActive ) {
        if ( typeof callback === 'function' )
          callback( false );
        return;
      }

      value = String( value );

      lzstringWorker.compressToUTF16( value, function( compressed ) {
      
        try {
          window.localStorage.setItem( name, 'lzs|'+ compressed );
          var ret = true;
        }
        catch ( e ) {
          var ret = false;
        }

        if ( typeof callback === 'function' )
          callback( ret );
      } );
    },
    
    remove: function( name ) {

      if ( !isModuleActive )
        return false;

      try {
        window.localStorage.removeItem( name );
        return true;
      }
      catch( e ) {
        Asteikko.error( e );
        return false;
      }
    },
    
    clear: function() {

      if ( !isModuleActive )
        return false;

      for ( var prop in window.localStorage ) {
        if ( window.localStorage.hasOwnProperty( prop ) ) {
          try {
            window.localStorage.removeItem( prop );
          }
          catch( e ) {
            Asteikko.error( e );
          }
        }
      }
      return true;
    }
  };
  
} )();
