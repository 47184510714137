window.AsteikkoTableResize = ( function() {
  
  if ( !window.jQuery )
    throw 'jQuery not seen. Please include jQuery before including AsteikkoTableResize';
  if ( !window.jQuery.fn.kinetic )
    throw 'jQuery.kinetic not seen. Please include jQuery before including AsteikkoTableResize';

  var resizeTimeout;
  
  return {

    _resizeTable: function() {
        
      var table = $( this );
      var container = table.closest( '.textcontainer' );
      var containerW = container.width();
      
      var button = table.prevAll( '.atr-table-button-show' ).eq( 0 );
      if ( !button.length )
        return;
      
      table.removeClass( 'table-partial' );
      var tableW = table.width();
      
      if ( tableW > containerW ) {
        table.addClass( 'table-partial' );
        var tableW = Math.floor( table.width() );
        var tableH = Math.floor( table.height() );
        button.
          width( tableW ).
          height( tableH ).
          css( 'line-height', tableH +'px' ).
          css( 'margin-bottom', -tableH +'px').
          show();
      }
      else {
        button.hide();
      }
    },
    
    _onResizeWindow: function() {
  
      window.clearTimeout( resizeTimeout );
      resizeTimeout = window.setTimeout( AsteikkoTableResize.resizeTables, 500 );
    },
    
    resizeTables: function() {
      
      $( '.asmag-page' ).find( 'table' ).each( AsteikkoTableResize._resizeTable );
    },
    
    initTables: function( parent ) {
      
      $( parent ).find( 'table' ).each( function() {
        
        var table = $( this );
        if ( table.data( 'hasTableOverlay' ) )
          return;
        table.data( 'hasTableOverlay', true );
        var button = $( '<div class="atr-table-button-show">Avaa taulukko</div>' );
        button.click( AsteikkoTableResize.showTableOverlay );
        table.before( button );
      } );
      
      AsteikkoTableResize._onResizeWindow();
    },
    
    showTableOverlay: function() {
      
      var button = $( this );
      var table = button.nextAll( '.table-partial' ).eq( 0 );
      if ( !table )
        return;
      
      $( '#atr-table-overlay' ).remove();
      var overlay = $( '<div class="atr-overlay" id="atr-table-overlay"></div>' );
      var closeButton = $( '<div class="atr-close-button"></div>' );
      closeButton.click( function() {
        $( '#androidhelperleft, #androidhelperright' ).show();
        overlay.remove();
      } );
      overlay.append( closeButton );
      var overlayScroll = $( '<div id="atr-table-overlay-scroll"></div>' ).
        appendTo( overlay );
      var overlayContent = $( '<div id="atr-table-overlay-content"></div>' ).
        appendTo( overlayScroll );
      
      overlay.bind( 'touchstart MSPointerDown pointerdown', function( event ) {
        event.stopPropagation();
      } );
      
      overlayContent.append( table.clone().removeClass( 'table-partial' ) );
      table.closest( '.asmag-page' ).append( overlay );
      
      $( '#androidhelperleft, #androidhelperright' ).hide();
      if ( !asmagModernizr.touch )
        overlayScroll.kinetic();
    }
  };
  
} )();

$( function() {
  $( window ).unbind( 'resize', AsteikkoTableResize._onResizeWindow );
  $( window ).resize( AsteikkoTableResize._onResizeWindow );
} );

