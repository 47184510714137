
if ( !window.Asteikko ) window.Asteikko = {};

( function() {

  'use strict';

  $(window).on('galleryClosed.asmagissue', Asteikko.reader.refreshMagazineSize);

  var os = window.deviceDetect.getOS();
  var browser = window.deviceDetect.getBrowser();

  Asteikko.pageScroller = {

    requests: [ null, null, null ],
    requestProcessTimeout: null,

    addRequest: function( swipeviewPage, slot ) {

      if ( swipeviewPage === undefined || swipeviewPage < 0 )
        return;

      if ( Asteikko.pageScroller.requests[ slot ] ) {
        Asteikko.pageScroller.cancelRequest( slot );
      }

      Asteikko.pageScroller.requests[ slot ] = {
        swipeviewPage: swipeviewPage,
        slot: slot,
        promiseBySubPage: {},
        started: false
      };
    },

    cancelRequest: function( slot ) {

      if ( !Asteikko.pageScroller.requests[ slot ] )
        return;

      Asteikko.log( 'Cancelling page request '+ slot );

      var promises = Asteikko.pageScroller.requests[ slot ].promiseBySubPage;

      for ( var subPageIndex in promises ) {
        if ( promises.hasOwnProperty( subPageIndex ) ) {
          Asteikko.pageScroller.cancelPageHtmlRequestFunc( subPageIndex );
        }
      }
    },

    processRequest: function() {

      var request = null;

      // Find a pending request with the smallest page number
      for (var i = 0; i < Asteikko.pageScroller.requests.length; i++) {
        if ( Asteikko.pageScroller.requests[i] && !Asteikko.pageScroller.requests[i].started ) {
          if ( !request || Asteikko.pageScroller.requests[i].swipeviewPage < request.swipeviewPage ) {
            request = Asteikko.pageScroller.requests[i];
          }
        }
      }

      // No request found, or we're not in an issue anymore (Asteikko.reader was
      // closed)
      if ( !request || !window.pages || !pages.length ) {
        window.clearTimeout( Asteikko.pageScroller.requestProcessTimeout );
        Asteikko.pageScroller.requestProcessTimeout = null;
        return;
      }

      request.started = true;

      function onRequestSuccess( subPageIndex, html ) {

        Asteikko.pageScroller.showPageForHtml( subPageIndex, html );

        if ( !window.pages || !pages.length || !Asteikko.pageScroller.requests[ request.slot ] )
          return;

        delete Asteikko.pageScroller.requests[ request.slot ].promiseBySubPage[ subPageIndex ];

        var promises = Asteikko.pageScroller.requests[ request.slot ].promiseBySubPage;
        var requestsLeft = false;
        for ( var i in promises ) {
          if ( promises.hasOwnProperty( i ) ) {
            requestsLeft = true;
            break;
          }
        }

        if ( !requestsLeft )
          delete Asteikko.pageScroller.requests[ request.slot ];
      }

      function onRequestFail( subPageIndex, error ) {

        if ( !window.pages || !pages.length )
          return;

        var jqXhr = ( error && error.statusText ) ? error : null;

        // From asteikko-reader.js
        if ( jqXhr && ( jqXhr.statusText === 'abort' || jqXhr.statusText === 'timeout' ) )
          return;

        // From asteikko-reader-iosdecorator.js
        if ( error === 'cancelled' )
          return;

        if ( Asteikko.pageScroller.pageLoadErrorFunc )
          Asteikko.pageScroller.pageLoadErrorFunc( jqXhr ? jqXhr.status : 400 );

        if ( !Asteikko.pageScroller.requests[ request.slot ] )
          return;

        delete Asteikko.pageScroller.requests[ request.slot ].promiseBySubPage[ subPageIndex ];

        var promises = Asteikko.pageScroller.requests[ request.slot ].promiseBySubPage;
        var requestsLeft = false;
        for ( var i in promises ) {
          if ( promises.hasOwnProperty( i ) ) {
            requestsLeft = true;
            break;
          }
        }

        if ( !requestsLeft )
          delete Asteikko.pageScroller.requests[ request.slot ];
      }

      function addSubPageRequest( subPageIndex ) {
        Asteikko.pageScroller.requests[ request.slot ].promiseBySubPage[ subPageIndex ] =
          Asteikko.pageScroller.requestPageHtmlFunc( subPageIndex ).
            then( onRequestSuccess.bind( null, subPageIndex ) ).
            catch( onRequestFail.bind( null, subPageIndex ) );
      }

      var subPageStartIndex = Asteikko.pageScroller.getSubPageStartIndex( request.swipeviewPage );
      var subPagesToLoad = Asteikko.pageScroller.getSubPageCount( request.swipeviewPage );

      for ( var i = 0; i < subPagesToLoad; i++ ) {
        // If subpage already has content (i.e. it was loaded from cache), don't
        // download content for it from the server
        if ( !$( '#asmag-swipeview-subpage-'+ ( subPageStartIndex + i ) ).is( ':empty' ) )
          continue;

        addSubPageRequest( subPageStartIndex + i );
      }

      // Start the next Swipeview page's request, if there's any non-started
      // requests left
      Asteikko.pageScroller.requestProcessTimeout = setTimeout( Asteikko.pageScroller.processRequest, 100 );
    },

    getSubPageStartIndex: function( swipeviewPage ) {

      return ( swipeviewPage != 0 ) 
        ? Asteikko.pageScroller.currColumns * ( swipeviewPage - 1 ) + 1
        : 0;
    },

    getSubPageCount: function( swipeviewPage ) {

      var startIndex = Asteikko.pageScroller.getSubPageStartIndex( swipeviewPage );
      var count = ( startIndex > 0 && startIndex < pages.length - 1 )
        ? Asteikko.pageScroller.currColumns 
        : 1;
      if ( startIndex + count > pages.length )
        count -= startIndex + count - pages.length;
      return count;
    },

    getColumnCount: function() {

      var container = $( '#'+ Asteikko.pageScroller.containerId );
      var containerWidth = container.width();
      var containerHeight = container.height();
      // We only show multiple columns when the container is in landscape
      // orientation (i.e. wider than taller)
      var columns = ( containerWidth >= containerHeight )
        ? Math.floor( containerWidth / Asteikko.pageScroller.minColumnWidth )
        : 1;

      if ( columns < 1 )
        columns = 1;
      else if ( columns > Asteikko.pageScroller.maxColumns )
        columns = Asteikko.pageScroller.maxColumns;

      return columns;
    },

    /**
     * Cancel all ajax requests and reload the pages, if the column count has changed
     */
    resetColumns: function() {

      if ( !window.pages || !window.pages.length )
        return;

      var oldColCount = Asteikko.pageScroller.currColumns;
      Asteikko.pageScroller.currColumns = Asteikko.pageScroller.getColumnCount();

      if ( Asteikko.pageScroller.currColumns === oldColCount )
        return;

      for ( var i = 0; i < Asteikko.pageScroller.requests.length; ++i ) {
        Asteikko.pageScroller.cancelRequest( i );
      }

      Asteikko.pageScroller.requests.length = 0;
      pageScroller.updatePageCount( Asteikko.pageScroller.getPageCount() );
      pageScroller.setCurrentPage( Asteikko.pageScroller.getSwipeviewPageForSubPage( currentIndex ) );
      currentIndex = Asteikko.pageScroller.getCurrentIndex();
      Asteikko.pageScroller.initScroller( currentIndex );
    },

    getPageCount: function() {
      return Math.ceil( ( window.pages.length - 1 ) / Asteikko.pageScroller.currColumns ) + 1;
    },

    getCurrentIndex: function() {
      return Asteikko.pageScroller.getSubPageStartIndex( pageScroller.page );
    },

    getSwipeviewPageForSubPage: function( subPageIndex ) {

      return ( subPageIndex != 0 )
        ? Math.floor( ( subPageIndex - 1 ) / Asteikko.pageScroller.currColumns ) + 1
        : 0;
    },

    setColumnWidths: function( swipeviewPage ) {

      var swipeviewPageEl = $( '#asmag-swipeview-page-'+ swipeviewPage );
      if ( !swipeviewPageEl.length )
        return;

      var subPageElems = swipeviewPageEl.children();
      var colWidth = Math.floor( 100 / subPageElems.length );
      for ( var i = 0; i < subPageElems.length; ++i ) {
        subPageElems.eq( i ).css( 'width', colWidth +'%' );
        subPageElems.eq( i ).css( 'left', ( i * colWidth ) +'%' );
      }
    },

    allSubPagesAreZoomable: function( swipeviewPage ) {

      if ( !window.pages || !pages.length )
        return false;

      var subPageStartIndex = Asteikko.pageScroller.getSubPageStartIndex( swipeviewPage );
      var subPages = Asteikko.pageScroller.getSubPageCount( swipeviewPage );

      var allZoomable = true;
      for ( var i = subPageStartIndex; i < subPageStartIndex + subPages; i++ ) {
        if ( pages[ i ] && !pages[ i ].zoomable ) {
          allZoomable = false;
          break;
        }
      }

      return allZoomable;
    },

    resizeTimeout: null,
    onResize: function() {

      window.clearTimeout( Asteikko.pageScroller.resizeTimeout );
      Asteikko.pageScroller.resizeTimeout = 
        window.setTimeout( Asteikko.pageScroller.resetColumns, 500 );
    },

    /**
     * @return bool Whether or not all the swipeview page's subpages were found in cache.
     */
    showPageFromCache: function( swipeviewPage ) {

      if ( typeof Asteikko.pageScroller.getCachedHtmlFunc !== 'function' )
        return false;
      if ( !window.pages || !pages.length )
        return false;

      var subPageStartIndex = Asteikko.pageScroller.getSubPageStartIndex( swipeviewPage );
      var subPagesToLoad = Asteikko.pageScroller.getSubPageCount( swipeviewPage );
      var uncachedPageSeen = false;

      for ( var i = 0; i < subPagesToLoad; i++ ) {
        var pageHtml = Asteikko.pageScroller.getCachedHtmlFunc( subPageStartIndex + i );
        if ( pageHtml )
          Asteikko.pageScroller.showPageForHtml( subPageStartIndex + i, pageHtml );
        else
          uncachedPageSeen = true;
      }

      return !uncachedPageSeen;
    },

    showPageForHtml: function( subPageIndex, html ) {

      var swipeviewPage = Asteikko.pageScroller.getSwipeviewPageForSubPage( subPageIndex );

      var swipeviewPageEl = $( '#asmag-swipeview-page-'+ swipeviewPage );
      if ( !swipeviewPageEl.length )
        return;

      var subPageEl = swipeviewPageEl.find( '#asmag-swipeview-subpage-'+ subPageIndex );
      if ( !subPageEl.length )
        return;

      // Remove the loading icon whenever at least one subpage has been loaded
      swipeviewPageEl.removeClass( 'inLoad' );

      subPageEl.html( html );

      var currentPage = ( currentIndex != 0 )
        ? Math.floor( ( currentIndex - 1 ) / Asteikko.pageScroller.currColumns ) + 1
        : 0;

      // When appending the HTML with subPageEl.html(html) straight from 
      // localStorage, the 'pageDisplayedFuncPrefix<page ID>' function that was 
      // defined in the HTML might not be defined yet (for some reason)...
      // We fire it with a timeout to make sure the function exists.
      window.setTimeout( function() {

        if ( swipeviewPage == currentPage ) {
          if ( Asteikko.pageScroller.pageShownFunc ) {
            Asteikko.pageScroller.pageShownFunc( subPageIndex );
          }

          // Run a global Displayed123() function, where 123 is the issue page's
          // ID. This function is usually defined in an issue page's article
          // template.
          var displayedCallback = Asteikko.pageScroller.pageDisplayedFuncPrefix + pages[ subPageIndex ].id;
          if ( typeof window[ displayedCallback ] === 'function' )
            window[ displayedCallback ].call();

          if ( Asteikko.pageScroller.afterPageDisplayedFunc ) {
            Asteikko.pageScroller.afterPageDisplayedFunc( subPageIndex );
          }
        }

        if ( Asteikko.pageScroller.pageFinishLoadFunc ) {
          Asteikko.pageScroller.pageFinishLoadFunc( subPageIndex );
        }

        Asteikko.pageScroller.updateZoomablePages( swipeviewPage );
      }, 1 );
    },

    initScroller: function( shownSubPageIndex ) {

      for ( var i = ( this.getPageCount() < 3 ? 1 : 0 ); i < Math.min( this.getPageCount() + 1, 3 ); i++ ) {

        // Current Swipeview page
        if ( i == pageScroller.currentMasterPage ) {
          var subPageIndex = shownSubPageIndex;
        }
        // Prev Swipeview page
        else if ( ( pageScroller.currentMasterPage > 0 && i == pageScroller.currentMasterPage - 1 )
          || ( pageScroller.currentMasterPage == 0 && i == 2 ) ) 
        {
          var subPageIndex = shownSubPageIndex - Asteikko.pageScroller.currColumns;
          if ( subPageIndex <= -Asteikko.pageScroller.currColumns )
            subPageIndex = pages.length - 1;
          else if ( subPageIndex < 0 )
            subPageIndex = 0;
        }
        // Next Swipeview page
        else {
          var subPageIndex = shownSubPageIndex + Asteikko.pageScroller.currColumns;
          if ( subPageIndex >= ( pages.length - 1 ) + Asteikko.pageScroller.currColumns )
            subPageIndex = 0;
          else if ( subPageIndex > pages.length - 1 )
            subPageIndex = pages.length - 1;
        }

        var swipeviewPage = Asteikko.pageScroller.getSwipeviewPageForSubPage( subPageIndex );
        var subPageStartIndex = Asteikko.pageScroller.getSubPageStartIndex( swipeviewPage );
        var subPagesToLoad = Asteikko.pageScroller.getSubPageCount( swipeviewPage );

        // Allow outside code to clean up the old subpage before it's removed
        if ( Asteikko.pageScroller.pagePreRemoveFunc ) {
          for ( var j = 0; j < subPagesToLoad; ++j ) {
            if ( $( '#asmag-swipeview-subpage-'+ ( subPageStartIndex + j ) ).length )
              Asteikko.pageScroller.pagePreRemoveFunc( subPageStartIndex + j );
          }
        }

        $( pageScroller.masterPages[i] ).empty();

        var pageEl = document.createElement( 'div' );
        pageEl.className = Asteikko.pageScroller.pageClass +' inLoad';
        pageEl.id = 'asmag-swipeview-page-'+ swipeviewPage;
        pageScroller.masterPages[i].appendChild( pageEl );

        for ( var j = 0; j < subPagesToLoad; ++j ) {
          var subPageEl = document.createElement( 'div' );
          subPageEl.id = 'asmag-swipeview-subpage-'+ ( subPageStartIndex + j );
          subPageEl.className = 'asmag-swipeview-subpage';
          pageEl.appendChild( subPageEl );
        }

        Asteikko.pageScroller.setColumnWidths( swipeviewPage );

        if ( !Asteikko.pageScroller.showPageFromCache( swipeviewPage ) )
          Asteikko.pageScroller.addRequest( swipeviewPage, i );
      }

      Asteikko.pageScroller.processRequest();
    },

    onFlip: function() {

      var oldIndex = currentIndex;
      currentIndex = Asteikko.pageScroller.getCurrentIndex();

      if ( Asteikko.pageScroller.indexChangedFunc ) {
        Asteikko.pageScroller.indexChangedFunc( oldIndex, currentIndex );
      }

      for ( var i = ( Asteikko.pageScroller.getPageCount() < 3 ? 1 : 0 ); i < Math.min( Asteikko.pageScroller.getPageCount() + 1, 3 ); i++ ) {

        var nextSwipeviewPage = pageScroller.masterPages[i].dataset.upcomingPageIndex;
        var oldSwipeviewPage = pageScroller.masterPages[i].dataset.pageIndex;

        var pageEl = pageScroller.masterPages[i].children[0];
        var $pageEl = $( pageEl );

        var subPageStartIndex = Asteikko.pageScroller.getSubPageStartIndex( nextSwipeviewPage );
        var subPagesToLoad = Asteikko.pageScroller.getSubPageCount( nextSwipeviewPage );

        // A page that we didn't swipe to
        if ( nextSwipeviewPage != oldSwipeviewPage ) {

          // Allow outside code to clean up the old subpage before it's removed
          if ( Asteikko.pageScroller.pagePreRemoveFunc ) {

            var oldSubPageStartIndex = Asteikko.pageScroller.getSubPageStartIndex( oldSwipeviewPage );
            var oldSubPages = Asteikko.pageScroller.getSubPageCount( oldSwipeviewPage );

            for ( var j = 0; j < oldSubPages; ++j ) {
              Asteikko.pageScroller.pagePreRemoveFunc( oldSubPageStartIndex + j );
            }
          }

          // Destroy the replaced page's previous jquery.zoomable instances
          // (and their event handlers) before emptying the wrapper
          for ( var j = 0; j < pageEl.children.length; ++j ) {
            $( pageEl.children[ i ] ).zoomable( 'destroy' );
          }

          // Empty the wrapper
          $pageEl.empty();
          pageEl.className = Asteikko.pageScroller.pageClass +' inLoad';
          pageEl.id = 'asmag-swipeview-page-'+ nextSwipeviewPage;

          for ( var j = 0; j < subPagesToLoad; ++j ) {
            var subPageEl = document.createElement( 'div' );
            subPageEl.id = 'asmag-swipeview-subpage-'+ ( subPageStartIndex + j );
            subPageEl.className = 'asmag-swipeview-subpage';
            pageEl.appendChild( subPageEl );
          }

          Asteikko.pageScroller.setColumnWidths( nextSwipeviewPage );

          if ( !Asteikko.pageScroller.showPageFromCache( nextSwipeviewPage ) )
            Asteikko.pageScroller.addRequest( nextSwipeviewPage, i );
        }
        // The page we swiped to
        else if ( nextSwipeviewPage == pageScroller.page ) {

          // If the page has already loaded before we finish the swipe, 
          // immediately run the handlers
          if ( $( '#asmag-swipeview-page-'+ pageScroller.page ).length > 0 ) {

            var subPages = Asteikko.pageScroller.getSubPageCount( pageScroller.page );

            for ( var j = 0; j < subPages; ++j ) {

              if ( Asteikko.pageScroller.pageShownFunc )
                Asteikko.pageScroller.pageShownFunc( subPageStartIndex + j );

              // Run a global Displayed123() function, where 123 is the issue page's
              // ID. This function is usually defined in an issue page's article
              // template.
              var displayedCallback = Asteikko.pageScroller.pageDisplayedFuncPrefix + pages[pageScroller.page + j].id;
              if ( typeof window[ displayedCallback ] === 'function' )
                window[ displayedCallback ].call();

              if ( Asteikko.pageScroller.afterPageDisplayedFunc )
                Asteikko.pageScroller.afterPageDisplayedFunc( subPageStartIndex + j );
            }
          }
        }
      }

      Asteikko.pageScroller.processRequest();
    },

    updateZoomablePages: function( swipeviewPage ) {

      var $pageEl = $( '#asmag-swipeview-page-'+ swipeviewPage );

      var subPageStartIndex = Asteikko.pageScroller.getSubPageStartIndex( swipeviewPage );
      var subPagesToLoad = Asteikko.pageScroller.getSubPageCount( swipeviewPage );

      var allSubPagesZoomable = Asteikko.pageScroller.allSubPagesAreZoomable( swipeviewPage );
      if ( allSubPagesZoomable )
        $pageEl.zoomable();
      else
        $pageEl.zoomable( 'destroy' );

      var $subPages = $pageEl.children( '.asmag-swipeview-subpage' );

      for ( var j = 0; j < subPagesToLoad; ++j ) {

        var $elem = $subPages.eq( j );

        if ( !allSubPagesZoomable && pages[ subPageStartIndex + j ].zoomable )
          $elem.zoomable();
        else
          $elem.zoomable( 'destroy' );
      }
    },

    destroy: function() {

      // TODO: unbind any event handlers registered by create() and asteikkoscroller.js
    },

    create: function( settings ) {

      if ( typeof settings !== 'object' || !settings )
        throw 'First argument must be a settings object';

      var required = [ 'containerId', 'pageClass', 'numberOfPages' ];
      for ( var i = 0; i < required.length; i++ ) {
        if ( !settings[ required[ i ] ] )
          throw 'Setting \''+ required[ i ] +'\' is required';
      }

      this.containerId = settings.containerId.toString();
      this.pageClass = settings.pageClass.toString();
      // Prefix for the global function that is run when an issue page has been
      // displayed. Defaults to Displayed#(), where # is the issue page ID.
      this.pageDisplayedFuncPrefix = settings.pageDisplayedFuncPrefix || 'Displayed';
      // Function that is called when a page swipe happens, but before any HTML
      // has necessarily been loaded
      this.indexChangedFunc = settings.indexChangedFunc || null;
      // Function that is called when the issue page's HTML has been loaded and
      // added to the DOM, but only if the page is the current page. The
      // Displayed#() function is not called yet.
      this.pageShownFunc = settings.pageShownFunc || null;
      this.requestPageHtmlFunc = settings.requestPageHtmlFunc || null;
      this.cancelPageHtmlRequestFunc = settings.cancelPageHtmlRequestFunc || null;
      // Function that is called immediately after the Displayed#() function,
      // but only if the page is the current page
      this.afterPageDisplayedFunc = settings.afterPageDisplayedFunc || null;
      // Function that is called when all of the page HTML has been loaded. This
      // is called after pageShownFunc, Displayed#() and afterPageDisplayedFunc.
      // This is called even for pages that are not the current page (realize
      // that pageScroller keeps 3 pages loaded in the DOM).
      this.pageFinishLoadFunc = settings.pageFinishLoadFunc || null;
      // Called before the page wrapper element is removed from the DOM
      this.pagePreRemoveFunc = settings.pagePreRemoveFunc || null;
      this.getCachedHtmlFunc = settings.getCachedHtmlFunc || null;
      this.pageLoadErrorFunc = settings.pageLoadErrorFunc || null;
      this.swipeStartFunc = settings.swipeStartFunc || null;
      this.swipeEndFunc = settings.swipeEndFunc || null;
      this.maxColumns = settings.maxColumns || 2;
      this.minColumnWidth = settings.minColumnWidth || 500;
      window.currentIndex = settings.initialIndex || 0;

      this.currColumns = this.getColumnCount();

      window.pageScroller = new AsteikkoScroller( "#"+ this.containerId, {
        loop: false,
        numberOfPages: Asteikko.pageScroller.getPageCount(),
        useSwipeEvents: !settings.disablePageFlip
      });

      $( window ).unbind( 'resize', Asteikko.pageScroller.onResize );
      $( window ).resize( Asteikko.pageScroller.onResize );

      // A weird effect was seen on Android's nameless browser: touch events
      // weren't fired reliably when touching text on a page, but only when
      // a container element of the text didn't have corresponding move event
      // handlers bound. In addition to this hack, one might have to add move 
      // event handers to any element that has transform3d or translateZ
      // CSS styling.
      if ( deviceDetect.getBrowser() === 'androidnameless' ) {
        $( "#"+ this.containerId ).
          off( 'mousedown.pagescroller' ).
          off( 'mousemove.pagescroller' ).
          off( 'mouseup.pagescroller' ).
          on( 'mousedown.pagescroller', function() {} ).
          on( 'mousemove.pagescroller', function() {} ).
          on( 'mouseup.pagescroller', function() {} );
      }

      pageScroller.onFlip( this.onFlip );
      if ( this.swipeStartFunc )
        pageScroller.onMoveStart( this.swipeStartFunc );
      if ( this.swipeEndFunc )
        pageScroller.onMoveEnd( this.swipeEndFunc );

      pageScroller.setCurrentPage( Asteikko.pageScroller.getSwipeviewPageForSubPage( window.currentIndex ) );
      this.initScroller( window.currentIndex );
    }
  };

} )();
