window.AsteikkoAnalyticsGA4 = ( function() {
  var initialized = false;
  var eventCache = [];
  var gtag = null;

  function purgeEventCache() {
    if ( !initialized || !eventCache.length ) {
      return;
    }

    var sentEvent = eventCache.shift();

    gtag( 'event', sentEvent.action, sentEvent.data );

    Asteikko.log( 'Sent cached GA4 event with action "'+ sentEvent.action +'"' );
    Asteikko.log( 'GA4 events left: '+ eventCache.length );

    // Throttle the amount of events we send to GA
    if ( eventCache.length ) {
      window.setTimeout( purgeEventCache, 1000 );
    }
  }

  var al = {};

  al.initGa = function( ga4PropertyId ) {
    Asteikko.log( 'Initializing GA4 analytics' );

    // Load the gtag.js script dynamically

    var gtagElem = document.createElement( 'script' );
    gtagElem.type = 'text/javascript';
    gtagElem.setAttribute( 'async', 'true' );
    gtagElem.setAttribute( 'src', 'https://www.googletagmanager.com/gtag/js?id=' + ga4PropertyId );
    document.documentElement.firstChild.appendChild( gtagElem );

    window.dataLayer = window.dataLayer || [];
    gtag = function() {
      window.dataLayer.push( arguments );
    };
    gtag( 'js', new Date() );
    // TODO FIXME
    /*
    gtag( 'consent', 'default', {
      // GDPR: Don't store any cookies. This way we don't have to ask the user
      // for cookie permissions.
      // TODO: GA4 doesn't show these hits in reporting at all. You must ask the user for consent and then "update" these to granted: https://www.simoahava.com/analytics/consent-mode-google-tags/
      analytics_storage: 'denied',
      ad_storage: 'denied',
    } );
    */
    gtag( 'config', ga4PropertyId, {
      // GDPR: Don't store any cookies, and anonymize IPs. This way we don't
      // have to ask the user for cookie permissions.
      client_storage: 'none',
      anonymize_ip: true,
      // We're sending our own pageviews with sendPageView(), and we don't
      // want GA to send its own pageview automatically at startup
      send_page_view: false,
    } );

    initialized = true;

    purgeEventCache();
  };

  al.sendEvent = function( category, action, label, value ) {
    var data = {
      'event_category': category,
      'event_label': label,
      'value': value
    };

    if ( initialized ) {
      gtag( 'event', action, data );
    }
    else {
      Asteikko.log( 'Caching GA4 sendEvent' );
      eventCache.push( {
        action: action,
        data: data
      } );
    }
  };

  al.sendPageView = function( page, title ) {
    var data = {};

    if ( page ) data.page_location = page;
    if ( title ) data.page_title = title;

    if ( initialized ) {
      gtag( 'event', 'page_view', data );
    }
    else {
      Asteikko.log( 'Caching GA4 sendPageView' );
      eventCache.push( {
        action: 'page_view',
        data: data
      } );
    }
  };

  return al;

} )();
