/*****************************************************************************
  Page bookmarking feature
*****************************************************************************/

if ( !window.Asteikko ) window.Asteikko = {};

( function() {

  function setBookmark(issue, page, set, callback) {
    if (typeof issue === "undefined" || !issue ||
        typeof page === "undefined" || !page) {
      return;
    }

    $.ajax({
      url: Asteikko.env.site_url +'/asmag/bookmarks/?action=' + ((typeof set !== "undefined" && set) ? 'add' : 'remove'),
      type: 'POST',
      dataType: 'json',
      data: {issue: issue, page: page}
    }).done(function(stat) {
      if (typeof callback === "function" && callback) {
        if (stat !== false) {
          callback(true);
        } else {
          callback(false);
        }
      }
    }).fail(function() {
      if (typeof callback === "function" && callback) {
        callback(false);
      }
    });
  }
  
  function addBookmarkLocal(issue, page) {
    issue = parseInt(issue, 10);
    page = parseInt(page, 10);
    var bookmarks = JSON.parse(Asteikko.localStorage.get('bookmarks_cache'));
    if (!bookmarks) bookmarks = {};
    if (bookmarks[issue]) {
      if ($.inArray(String(page), bookmarks[issue]) === -1) {
        bookmarks[issue].push(String(page));
      }
    }
    else {
      bookmarks[issue] = [page];
    }
    return Asteikko.localStorage.set('bookmarks_cache', JSON.stringify(bookmarks));
  }
  
  function removeBookmarkLocal(issue, page) {
    issue = parseInt(issue, 10);
    page = parseInt(page, 10);
    var bookmarks = JSON.parse(Asteikko.localStorage.get('bookmarks_cache'));
    if (!bookmarks || !bookmarks[issue]) return false;
    for (var i = bookmarks[issue].length - 1; i >= 0; i--) {
      if (bookmarks[issue][i] == page) {
        bookmarks[issue].splice(i,1);
      }
    }
    return Asteikko.localStorage.set('bookmarks_cache', JSON.stringify(bookmarks));
  }
  
  function saveServerBookmarksLocally(bookmarks) {
    return Asteikko.localStorage.set('bookmarks_cache', JSON.stringify(bookmarks));
  }
  
  function getBookmarksLocal(issue) {
    issue = parseInt(issue, 10);
    var bookmarks = JSON.parse(Asteikko.localStorage.get('bookmarks_cache'));
    if (!bookmarks || (issue && !bookmarks[issue])) return null;
    if (issue) {
      var ret = {};
      ret[issue] = bookmarks[issue];
    }
    else {
      var ret = bookmarks;
    }
    return ret;
  }

  Asteikko.onlineFuncs.addBookmark = function(issue, page, callback) {
    // If user is not logged in, add bookmark temporarily to local storage
    if (Asteikko.lastLoginStatus === false) 
      Asteikko.offlineFuncs.addBookmark(issue, page, callback);
    else 
      setBookmark(issue, page, true, callback);
  };

  Asteikko.onlineFuncs.removeBookmark = function(issue, page, callback) {
    // If user is not logged in, remove bookmark temporarily from local storage
    if (Asteikko.lastLoginStatus === false) 
      Asteikko.offlineFuncs.removeBookmark(issue, page, callback);
    else 
      setBookmark(issue, page, false, callback);
  };

  Asteikko.offlineFuncs.addBookmark = function(issue, page, callback) {
  
    if ( !window.settings.bookmarks.enabled ) throw 'Bookmarks are not enabled';
    
    var added = addBookmarkLocal(issue, page);
    if (typeof callback === 'function') {
      if (added) callback(true);
      else callback(false);
    }
  };

  Asteikko.offlineFuncs.removeBookmark = function(issue, page, callback) {
  
    if ( !window.settings.bookmarks.enabled ) throw 'Bookmarks are not enabled';
    
    var removed = removeBookmarkLocal(issue, page);
    if (typeof callback === 'function') {
      if (removed) callback(true);
      else callback(false);
    }
  };

  Asteikko.onlineFuncs.getBookmarks = function(callback, issue) {
    
    // If user is not logged in, get bookmarks from local storage
    if (Asteikko.lastLoginStatus === false) {
      Asteikko.offlineFuncs.getBookmarks(callback, issue);
      return;
    }

    var request = {
      url: Asteikko.env.site_url +'/asmag/bookmarks/?action=get',
      type: 'POST',
      dataType: 'json'
    };

    if (typeof issue !== "undefined" && issue) {
      request.data = {issue: issue};
    }

    $.ajax(request).done(function(bookmarks) {
      if (bookmarks) {
        saveServerBookmarksLocally(bookmarks);
        if (typeof callback === "function") callback(bookmarks);
      } else {
        if (typeof callback === "function") callback(null);
      }
    }).fail(function() {
      if (typeof callback === "function") callback(null);
    });
  };

  Asteikko.offlineFuncs.getBookmarks = function(callback, issue) {
    var bookmarks = getBookmarksLocal(issue);
    if (typeof callback === "function") callback(bookmarks);
  };
  
  // When user is logged in, save locally added bookmarks to the server
  // asmag_loginstatus is also fired when network connectivity changes.
  $(window).bind('asmag_loginstatus', function(newStatus) {
  
    if ( !window.settings.bookmarks.enabled )
      return;

    if (newStatus === false)
      return;
  
    var localBookmarks = JSON.parse(Asteikko.localStorage.get('bookmarks_cache'));
    if (!localBookmarks)
      return;
  
    function onBookmarkSaved() {
      currSaveIndex++;
      if ( currSaveIndex === bookmarksToSave.length ) {
        // Cache saved, update the local cache from the server
        Asteikko.localStorage.remove('bookmarks_cache');
        Asteikko.onlineFuncs.getBookmarks( function( serverBookmarks ) {
          $( window ).trigger( 'asmag_bookmarks_localsavedtoserver', [ localBookmarks, serverBookmarks ] );
        } );
      }
      else {
        saveNextBookmark();
      }
    }
    
    function saveNextBookmark() {
      var issueId = bookmarksToSave[ currSaveIndex ].issueId;
      var pageId = bookmarksToSave[ currSaveIndex ].pageId;
      setBookmark(issueId, pageId, true, onBookmarkSaved);
    }
    
    function onGetServerBookmarks( serverBookmarks ) {
      for (var issueId in localBookmarks) {
        if (!localBookmarks.hasOwnProperty(issueId)) continue;
        for (var i = 0; i < localBookmarks[issueId].length; i++) {
          if ( !serverBookmarks || !serverBookmarks[issueId] 
            || $.inArray(String(localBookmarks[issueId][i]), serverBookmarks[issueId]) === -1 )
          {
            bookmarksToSave.push( { issueId: issueId, pageId: localBookmarks[issueId][i] } );
          }
        }
      }
      if ( bookmarksToSave.length ) saveNextBookmark();
    }
    
    var bookmarksToSave = [];
    var currSaveIndex = 0;
    Asteikko.onlineFuncs.getBookmarks( onGetServerBookmarks );
  });

} )();