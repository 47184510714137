/*******************************************************************************
  Information about the browser and OS.
*******************************************************************************/

( function() {
    
  function isAndroidNamelessBrowser() {

    var navU = navigator.userAgent;

    // Android Mobile
    var isAndroidMobile = navU.indexOf('Android') > -1 && navU.indexOf('Mozilla/5.0') > -1 && navU.indexOf('AppleWebKit') > -1;

    // Apple webkit
    var regExAppleWebKit = new RegExp(/AppleWebKit\/([\d.]+)/);
    var resultAppleWebKitRegEx = regExAppleWebKit.exec(navU);
    var appleWebKitVersion = (resultAppleWebKitRegEx === null ? null : parseFloat(regExAppleWebKit.exec(navU)[1]));

    // Chrome
    var regExChrome = new RegExp(/Chrome\/([\d.]+)/);
    var resultChromeRegEx = regExChrome.exec(navU);
    var chromeVersion = (resultChromeRegEx === null ? null : parseFloat(regExChrome.exec(navU)[1]));

    // Native Android Browser
    return isAndroidMobile && (appleWebKitVersion !== null && appleWebKitVersion < 537) || (chromeVersion !== null && chromeVersion < 37);
  }
  
  window.deviceDetect = {

    isWebKit: function () {
      return bowser.webkit;
    },

    getOS: function() {

      var OSName = "unknown";

      if (navigator.appVersion.indexOf("Win")!=-1) OSName="win";
      if (navigator.appVersion.indexOf("Mac")!=-1) OSName="mac";
      if (navigator.appVersion.indexOf("X11")!=-1) OSName="unix";
      if (navigator.appVersion.indexOf("Linux")!=-1) OSName="linux";
      // Mobile operating systems
      if (navigator.userAgent.indexOf('like Mac OS X') != -1) OSName="ios";
      if (navigator.userAgent.indexOf('Android') != -1) OSName="android";
      if (navigator.userAgent.indexOf('Windows Phone') != -1) OSName="windowsphone";

      return OSName;
    },

    getBrowser: function() {

      // We don't check the Android nameless browser with bowser.js, as its
      // detection seems to require a more elaborate check.
      if ( isAndroidNamelessBrowser() )
        return 'androidnameless';
      
      var bowserResults = [ 'chrome', 'firefox', 'msie', 'msedge', 'opera', 'safari' ];
      for ( var i = 0; i < bowserResults.length; i++ ) {
        if ( bowser[ bowserResults[ i ] ] )
          return bowserResults[ i ];
      }

      return 'unknown';
    }
  };
  
} )();
