/*******************************************************************************
  Issue cache: caching of pages on client device for performance and offline.
*******************************************************************************/

if ( !window.Asteikko ) window.Asteikko = {};

( function() {

  "use strict";

  Asteikko.issueCache = {

    getCachedIssueIds: function() {

      var ids = [];

      try {
        for ( var i in window.localStorage ) {
          if ( !window.localStorage.hasOwnProperty( i ) )
            continue;
          var match = /^asmag_i(\d+)(_|$)/.exec( i );
          if ( !match )
            continue;
          var id = parseInt( match[ 1 ], 10 );
          if ( match && $.inArray( id, ids ) === -1 )
            ids.push( id );
        }
      }
      catch ( e ) {
        Asteikko.error( e );
      }

      return ids;
    },

    // Currently we store only one issue's data in localStorage at a time.
    // In the future we might store multiple issues; adjust this method to 
    // remove only the issue given by 'issueId' then.
    clearCacheForIssue: function( issueId ) {

      var regexp = new RegExp( '^asmag_i'+ issueId +'(_|$)' );

      try {
        for ( var i in window.localStorage ) {
          if ( !window.localStorage.hasOwnProperty( i ) )
            continue;
          if ( regexp.test( i ) ) {
            Asteikko.localStorage.remove( i );
          }
        }
      }
      catch ( e ) {
        Asteikko.error( e );
      }
    },

    clearCacheForOtherIssuesThan: function( issueId ) {

      var removedIssues = [];

      try {
        for ( var i in window.localStorage ) {
          if ( !window.localStorage.hasOwnProperty( i ) )
            continue;
          if ( /^asmag_i\d+/.test( i ) ) {
            var id = i.replace( /^asmag_i(\d+).*$/, '$1' );
            if ( $.inArray( id, removedIssues ) === -1 && id != issueId )
              removedIssues.push( id );
          }
        }
      }
      catch ( e ) {
        Asteikko.error( e );
      }
      
      for ( var i = 0; i < removedIssues.length; i++ )
        this.clearCacheForIssue( removedIssues[ i ] );
    },
    
    saveIssueMeta: function( issueId, issueMeta ) {

      Asteikko.localStorage.setAsync( 'asmag_i'+ issueId, JSON.stringify( issueMeta ) );

      // Delete previously cached pages that don't exists in the issue anymore

      var pageIds = [];
      for ( var i = 0; i < issueMeta.pages.length; i++ )
        pageIds.push( parseInt( issueMeta.pages[ i ].id, 10 ) );

      var isCachedPageRegexp = new RegExp( '^asmag_i'+ issueId +'_p\\d+$' );
      var retainedPagesRegexp = new RegExp( '^asmag_i'+ issueId +'_p('+ pageIds.join( '|' ) +')$' );

      try {
        for ( var i in window.localStorage ) {
          if ( !window.localStorage.hasOwnProperty( i ) )
            continue;
          if ( isCachedPageRegexp.test( i ) && !retainedPagesRegexp.test( i ) )
            Asteikko.localStorage.remove( i );
        }
      }
      catch ( e ) {
        Asteikko.error( e );
      }
    },

    getIssueMeta: function( issueId ) {
      
      var issueData = JSON.parse( Asteikko.localStorage.get( 'asmag_i'+ issueId ) );
      return issueData || null;
    },
    
    saveIssueCss: function( issueId, css ) {

      Asteikko.localStorage.setAsync( 'asmag_i'+ issueId +'_css', css +'' );
    },
    
    getIssueCss: function( issueId ) {
      
      var issueCss = Asteikko.localStorage.get( 'asmag_i'+ issueId +'_css' );
      return issueCss || null;
    },
    
    saveIssueCover: function( issueId, cover ) {

      Asteikko.localStorage.setAsync( 'asmag_i'+ issueId +'_cover', cover +'' );
    },
    
    getIssueCover: function( issueId ) {
      
      var cover = Asteikko.localStorage.get( 'asmag_i'+ issueId +'_cover' );
      return cover || null;
    },
    
    savePage: function( issueId, pageId, pageContent, modifiedStamp ) {

      var cachedObj = {
        id: pageId,
        content: pageContent,
        modified: modifiedStamp
      };

      Asteikko.localStorage.setAsync( 'asmag_i'+ issueId +'_p'+ pageId, JSON.stringify( cachedObj ) );
    },

    getPage: function( issueId, pageId ) {

      var content = JSON.parse( Asteikko.localStorage.get( 'asmag_i'+ issueId +'_p'+ pageId ) );
      return content || null;
    },

    getCachedPageCount: function( issueMeta ) {

      if ( !issueMeta )
        return 0;

      var pageIds = [];
      var foundPages = 0;

      for ( var i = 0; i < issueMeta.pages.length; i++ )
        pageIds.push( parseInt( issueMeta.pages[ i ].id, 10 ) );

      var regexp = new RegExp( '^asmag_i'+ issueMeta.id +'_p('+ pageIds.join( '|' ) +')$' );

      try {
        for ( var i in window.localStorage ) {
          if ( !window.localStorage.hasOwnProperty( i ) )
            continue;
          if ( regexp.test( i ) )
            foundPages++;
        }
      }
      catch ( e ) {
        Asteikko.error( e );
      }

      return foundPages;
    }
  };
  
} )();

