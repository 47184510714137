//
// The renderer is an object whose methods will be called at certain times
// of rendering the Asteikko reader UI. Available methods:
//
//    beforeRender( context ) // Called before any HTML elements are created.
//                            // This is the best place to modify the context
//                            // object.
//
//    render( context, uiRootElem ) // Called when the core of the reader has 
//                                  // been added to uiRootElem. It is the 
//                                  // renderer object's job to append its own
//                                  // UI elements to uiRootElem in render().
//
//    afterRender( context, uiRootElem ) // Called after the UI HTML has been appended to DOM.
//
//    beforeUnload( context, uiRootElem ) // Before the UI is removed from the 
//                                        // DOM. This is a good place to 
//                                        // unbind any event handlers.
//
//    afterUnload( context ) // After the UI has been removed from the DOM
// 
//    disableDefaultPageFlip( context ) // Whether to allow page changing by the user
//                             // by horizontal swiping or with keyboard arrow
//                             // keys. Pages can still be changed via 
//                             // JavaScript, so disabling the default page
//                             // switching is useful for custom page flip implementations.
//
// You can modify the context object in beforeRender() to add any custom data 
// in it. The context object is a copy of the app state, so it can be modified
// freely.
//
// The recommended way to add content to uiRootElem in these methods is to use 
// Handlebars templates. Use Asteikko.ui.renderTemplate(templateFilename, context)
// to render a template that exists in includes/custom/client_templates.
//
var CustomReaderUiRenderer = Object.create( AsteikkoDefaultUiRenderer );

// We make a deep copy of the menu object
CustomReaderUiRenderer.options.menu = JSON.parse( JSON.stringify( AsteikkoDefaultUiRenderer.options.menu ) );

CustomReaderUiRenderer.beforeRender = function( context ) {

  // Call the "super-class" method that we've overridden. If you edit this
  // method, keep this file here and add your own logic below it.
  Object.getPrototypeOf( this ).beforeRender.call( this, context );
};

CustomReaderUiRenderer.render = function( context, uiRootElem ) {

  // Call the "super-class" method that we've overridden. If you edit this
  // method, keep this file here and add your own logic below it.
  Object.getPrototypeOf( this ).render.call( this, context, uiRootElem );
};

CustomReaderUiRenderer.afterRender = function( context, uiRootElem ) {

  // Call the "super-class" method that we've overridden. If you edit this
  // method, keep this file here and add your own logic below it.
  Object.getPrototypeOf( this ).afterRender.call( this, context, uiRootElem );
};

CustomReaderUiRenderer.beforeUnload = function( context, uiRootElem ) {

  // Call the "super-class" method that we've overridden. If you edit this
  // method, keep this file here and add your own logic below it.
  Object.getPrototypeOf( this ).beforeUnload.call( this, context, uiRootElem );
};

CustomReaderUiRenderer.afterUnload = function( context ) {

  // Call the "super-class" method that we've overridden. If you edit this
  // method, keep this file here and add your own logic below it.
  Object.getPrototypeOf( this ).beforeUnload.call( this, context );
};

// Uncomment this to add a new menu item to the menu. The contentTpl property
// defines the .tpl file in the templates/ directory that is used to show the
// menu item's content.
/*
CustomReaderUiRenderer.options.menu.push( {
  'label': "Label shown in menu",
  'contentTpl': "some_tpl_file_in_the_templates_directory"
} );
*/

/**
 * Tells Asteikko Mag to use a specific object to render the reading view.
 */
Asteikko.reader.setGetRendererFunction( function( context ) {
  return CustomReaderUiRenderer;
} );
