
// Make the shorter col follow the screen top/bottom when the user scrolls down

window.AsteikkoDockedColumn = ( function() {

  var dockedColumns = [];

  function create( pageId, minWidth ) {

    var posStaticTimeout;
    var page = $( '#asmag-page-'+ pageId );
    var palstat =  page.find( '.palstat' ).eq( 0 );
    var vasenPalsta = page.find( '.vasenpalsta' ).eq( 0 );
    var oikeaPalsta = page.find( '.oikeapalsta' ).eq( 0 );
    var scrollElem = page.find( '.scroll' ).not( '.overlay .scroll' ).eq( 0 );

    var shortPalsta = null;
    var shortPalstaHeight = 0;
    var shortPalstaWidth = 0;
    var shortPalstaOffset = null;

    var tallPalsta = null;
    var tallPalstaHeight = 0;
    
    var pageHeight = page.height();
    var pageWidth = page.width();

    var lastScreenTop = 0;
    var shortPalstaIsFixed = false;
    var shortPalstaTopOffsetFromOrig = 0;

    var useAnimation = $( 'body' ).hasClass( 'asmag-os-ios' );
    var shortPalstaTransformY = 0;
    
    function resetOffsets() {

      //vasenPalsta.add( oikeaPalsta ).css( 'position', 'static' );
      //vasenPalsta.add( oikeaPalsta ).css( 'top', '' );
      //vasenPalsta.add( oikeaPalsta ).css( 'bottom', '' );
      //vasenPalsta.add( oikeaPalsta ).css( 'left', '' );
      //vasenPalsta.add( oikeaPalsta ).css( 'width', '' );

      vasenPalsta.add( oikeaPalsta ).css( 'transform', '' );

      lastScreenTop = 0;
      shortPalstaTopOffsetFromOrig = 0;
      shortPalstaIsFixedTop = false;
      shortPalstaIsFixedBottom = false;
      shortPalstaTransformY = 0;
    }

    function calcDimensions() {

      pageHeight = page.height();
      pageWidth = page.width();
      var vasenPalstaHeight = vasenPalsta.outerHeight( true );
      var oikeaPalstaHeight = oikeaPalsta.outerHeight( true );
      
      if ( vasenPalstaHeight > oikeaPalstaHeight ) {
        shortPalsta = oikeaPalsta;
        tallPalsta = vasenPalsta;
        shortPalstaHeight = oikeaPalstaHeight;
        tallPalstaHeight = vasenPalstaHeight;
      }
      else {
        shortPalsta = vasenPalsta;
        tallPalsta = oikeaPalsta;
        shortPalstaHeight = vasenPalstaHeight;
        tallPalstaHeight = oikeaPalstaHeight;
      }

      shortPalstaWidth = shortPalsta.outerWidth();
      shortPalstaOffset = shortPalsta.offset();

      vasenPalsta.add( oikeaPalsta ).removeClass( 'shortcol' );
      shortPalsta.
        addClass( 'shortcol' ).
        /* Prevent a rendering error on Chrome when scrolling the column with translate3d */
        css( '-webkit-backface-visibility', 'hidden' ).
        css( 'backface-visibility', 'hidden' );
    }

    var offsetTimeout;
    function updateShortPalstaTransformCss() {
      shortPalsta.css( 'transform', 'translate3d(0,'+ Math.round( shortPalstaTransformY ) +'px,0)' );
    }

    function updateShortPalstaTransform() {

      if ( useAnimation ) {
        window.clearTimeout( offsetTimeout );
        offsetTimeout = window.setTimeout( updateShortPalstaTransformCss, 100 );
      }
      else {
        updateShortPalstaTransformCss();
      }
    }

    function setShortPalstaOffset() {
      
      if ( ( minWidth && pageWidth < minWidth ) || !oikeaPalsta.is( ':visible' ) ) {
        resetOffsets();
        return;
      }
      
      var palstaContainerOffsetScreenTop = palstat.offset().top;
      var screenTop = scrollElem.scrollTop();
      var palstaContainerTop = palstaContainerOffsetScreenTop + screenTop;

      var screenBottom = screenTop + pageHeight;
      var shortPalstaBottom = palstaContainerTop + shortPalstaHeight;
      var tallPalstaBottom = palstaContainerTop + tallPalstaHeight;

      var scrollDelta = screenTop - lastScreenTop;
      
      if ( shortPalstaHeight < pageHeight ) {

        if ( palstaContainerOffsetScreenTop < 0 ) {
          //shortPalsta.css( 'position', 'fixed' );
          //shortPalsta.css( 'top', '0px' );
          //shortPalsta.css( 'bottom', '' );
          //shortPalsta.css( 'left', shortPalstaOffset.left );
          //shortPalsta.outerWidth( shortPalstaWidth );
          shortPalstaTransformY = -palstaContainerOffsetScreenTop;
        }
        else {
          resetOffsets();
        }
      }
      // Scrolling down
      else if ( scrollDelta > 0 ) {

        if ( !shortPalstaIsFixedTop && !shortPalstaIsFixedBottom ) {
          if ( shortPalstaBottom + shortPalstaTopOffsetFromOrig <= screenBottom
            && shortPalstaBottom + shortPalstaTopOffsetFromOrig < tallPalstaBottom )
          {
            //shortPalsta.css( 'position', 'fixed' );
            //shortPalsta.css( 'top', '' );
            //shortPalsta.css( 'bottom', '0px' );
            //shortPalsta.css( 'left', shortPalstaOffset.left );
            //shortPalsta.outerWidth( shortPalstaWidth );
            shortPalstaTransformY = screenBottom - palstaContainerTop - shortPalstaHeight;
            shortPalstaTopOffsetFromOrig += screenBottom - 
              ( shortPalstaBottom + shortPalstaTopOffsetFromOrig );
            shortPalstaIsFixedBottom = true;
          }
        }
        else if ( shortPalstaIsFixedTop ) {
          //shortPalsta.css( 'position', 'relative' );
          //shortPalsta.css( 'top', Math.round( shortPalstaTopOffsetFromOrig ) );
          //shortPalsta.css( 'bottom', '' );
          //shortPalsta.css( 'left', '' );
          //shortPalsta.css( 'width', '' );
          shortPalstaIsFixedTop = false;
        }
        else if ( shortPalstaIsFixedBottom ) {
          shortPalstaTopOffsetFromOrig += scrollDelta;
          if ( shortPalstaBottom + shortPalstaTopOffsetFromOrig > tallPalstaBottom ) {
            //shortPalsta.css( 'position', 'relative' );
            //shortPalsta.css( 'top', Math.round( shortPalstaTopOffsetFromOrig ) );
            //shortPalsta.css( 'bottom', '' );
            //shortPalsta.css( 'left', '' );
            //shortPalsta.css( 'width', '' );
            shortPalstaIsFixedBottom = false;
          }
          else {
            shortPalstaTransformY = screenBottom - palstaContainerTop - shortPalstaHeight;
          }
        }
      }
      // Scrolling up
      else if ( scrollDelta < 0 ) {

        if ( !shortPalstaIsFixedTop && !shortPalstaIsFixedBottom ) {
          if ( palstaContainerTop + shortPalstaTopOffsetFromOrig >= screenTop 
            && palstaContainerTop + shortPalstaTopOffsetFromOrig > palstaContainerTop )
          {
            //shortPalsta.css( 'position', 'fixed' );
            //shortPalsta.css( 'top', '0px' );
            //shortPalsta.css( 'bottom', '' );
            //shortPalsta.css( 'left', shortPalstaOffset.left );
            //shortPalsta.outerWidth( shortPalstaWidth );
            shortPalstaTransformY = -palstaContainerOffsetScreenTop;
            shortPalstaTopOffsetFromOrig -= ( palstaContainerTop + shortPalstaTopOffsetFromOrig ) - screenTop;
            shortPalstaIsFixedTop = true;
          }
        }
        else if ( shortPalstaIsFixedBottom ) {
          //shortPalsta.css( 'position', 'relative' );
          //shortPalsta.css( 'top', Math.round( shortPalstaTopOffsetFromOrig ) );
          //shortPalsta.css( 'bottom', '' );
          //shortPalsta.css( 'left', '' );
          //shortPalsta.css( 'width', '' );
          shortPalstaIsFixedBottom = false;
        }
        else if ( shortPalstaIsFixedTop ) {
          shortPalstaTopOffsetFromOrig += scrollDelta;
          if ( shortPalstaTopOffsetFromOrig <= 0 )
            resetOffsets();
          else
            shortPalstaTransformY = -palstaContainerOffsetScreenTop;
        }
      }
      
      //tallPalsta.css( 'position', 'static' );
      tallPalsta.css( 'transform', '' );
      updateShortPalstaTransform();

      lastScreenTop = screenTop;
    }

    function onScroll() {
      setShortPalstaOffset();
    }

    scrollElem.eq( 0 ).scroll( onScroll );
    
    asteikkoImg.checkImagesAreLoadedInside( page, function(parent, imgs) {
      window.setTimeout( function() {
        resetOffsets();
        calcDimensions();
        setShortPalstaOffset();
      }, 1600 );
    } );
    
    resetOffsets();
    calcDimensions();

    function onResize() {
      resetOffsets();
      calcDimensions();
      setShortPalstaOffset();
    }

    function onPageShown( event, index ) {

      if ( !$( '#asmag-page-'+ window.pages[ index ].id ).length ) {
        unbindEventHandlers();
      }
      else {
        resetOffsets();
        calcDimensions();
        setShortPalstaOffset();
      }
    }

    function onIssueClosed( event, issueId ) {

      unbindEventHandlers();
    }

    function bindEventHandlers() {

      $( window ).resize( onResize );
      $( window ).on( 'pageShown', onPageShown );
      $( window ).on( 'asmag_issueclosed', onIssueClosed );
    }

    function unbindEventHandlers() {

      $( window ).off( 'resize', onResize );
      $( window ).off( 'pageShown', onPageShown );
      $( window ).off( 'asmag_issueclosed', onIssueClosed );
    }

    bindEventHandlers();

    dockedColumns[ pageId ] = {
      refresh: onResize
    };
  }

  function refreshPage( pageId ) {

    if ( !dockedColumns[ pageId ] )
      return;
    dockedColumns[ pageId ].refresh();
  }

  return {
    create: create,
    refreshPage: refreshPage
  };

} )();

