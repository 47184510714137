/*****************************************************************************
  Editor Notes Ajax functions
*****************************************************************************/

( function( $ ) {

	Asteikko.editorNotes = {};

	function getEditorNotesByPage( issue_id, page_id, callback ) {
		if ( typeof issue_id === "undefined" || !issue_id || typeof page_id === "undefined" || !page_id ) {
	      return;
	    }

	    $.ajax({
	      url: Asteikko.env.site_url +'/asmag/editornotes/?action=getbypage',
	      type: 'POST',
	      dataType: 'json',
	      data: { issue_id: issue_id, page_id: page_id }
	    }).done( function( data ) {
	    	//console.log(data);
	    	if (typeof callback === "function" && callback) {
		        if ( data ) {
		          callback( data );
		        } else {
		          callback( null );
		        }
		    }
	    }).fail( function( err ) {
	    	// console.log(err);
			if ( typeof callback === "function" && callback ) {
				callback( false );
			}
	    });
	}

	function addEditorNotes( issue_id, page_id, data, callback ) {
		if ( typeof issue_id === "undefined" || !issue_id || typeof page_id === "undefined" || !page_id || typeof data === "undefined" || !data ) {
	      return;
	    }

	    $.ajax({
	      url: Asteikko.env.site_url +'/asmag/editornotes/?action=add',
	      type: 'POST',
	      dataType: 'json',
	      data: { issue_id: issue_id, page_id: page_id, data: data }
	    }).done( function( data ) {
	    	// console.log(data);
	    	if (typeof callback === "function" && callback) {
		        if ( data ) {
		          callback( data );
		        } else {
		          callback( null );
		        }
		    }
	    }).fail( function( err ) {
	    	// console.log(err);
			if ( typeof callback === "function" && callback ) {
				callback( false );
			}
	    });
	}

	function getEditorNoteById( id, issue_id, page_id, callback ) {
		if ( typeof issue_id === "undefined" || !issue_id || typeof page_id === "undefined" || !page_id || typeof id === "undefined" || !id ) {
	      return;
	    }
	    
	    $.ajax({
	    	url: Asteikko.env.site_url +'/asmag/editornotes/?action=getbyid',
	    	type: 'POST',
	    	dataType: 'json',
	    	data: { id: id, issue_id: issue_id, page_id: page_id }
	    }).done( function( data ) {
	    	// console.log(data);
	    	if (typeof callback === "function" && callback) {
		        if ( data ) {
		          callback( data );
		        } else {
		          callback( null );
		        }
		    }
	    }).fail( function( err ) {
	    	// console.log(err);
	    	if ( typeof callback === "function" && callback ) {
				callback( false );
			}
	    });
	}

	function updateEditorNote( params, callback ) {
		if ( typeof params === "undefined" || !params ) {
	      return;
	    }
	    
	    $.ajax({
	    	url: Asteikko.env.site_url +'/asmag/editornotes/?action=update',
	    	type: 'POST',
	    	dataType: 'json',
	    	data: params
	    }).done( function( data ) {
	    	// console.log(data);
	    	if (typeof callback === "function" && callback) {
		        if ( data ) {
		          callback( data );
		        } else {
		          callback( false );
		        }
		    }
	    }).fail( function( err ) {
	    	// console.log(err);
	    	if ( typeof callback === "function" && callback ) {
				callback( false );
			}
	    });
	}

	function removeEditorNote( params, callback ) {
		if ( typeof params === "undefined" || !params ) {
	      return;
	    }
	    
	    $.ajax({
	    	url: Asteikko.env.site_url +'/asmag/editornotes/?action=remove',
	    	type: 'POST',
	    	dataType: 'json',
	    	data: params
	    }).done( function( data ) {
	    	// console.log(data);
	    	if (typeof callback === "function" && callback) {
		        if ( data ) {
		          callback( data );
		        } else {
		          callback( false );
		        }
		    }
	    }).fail( function( err ) {
	    	// console.log(err);
	    	if ( typeof callback === "function" && callback ) {
				callback( false );
			}
	    });
	}

	Asteikko.editorNotes.ajaxFuncs = {
		getEditorNotesByPage: getEditorNotesByPage,
		addEditorNotes: addEditorNotes,
		getEditorNoteById: getEditorNoteById,
		updateEditorNote: updateEditorNote,
		removeEditorNote: removeEditorNote
	};
} ) ( jQuery );

