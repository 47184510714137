
if ( /uusi-zoomaus/.test( window.location.search ) ) {

  var AsteikkoScroller = (function (window, document) {
    var dummyStyle = document.createElement('div').style,
      vendor = (function () {
        var vendors = 't,webkitT,MozT,msT,OT'.split(','),
          t,
          i = 0,
          l = vendors.length;

        for ( ; i < l; i++ ) {
          t = vendors[i] + 'ransform';
          if ( t in dummyStyle ) {
            return vendors[i].substr(0, vendors[i].length - 1);
          }
        }

        return false;
      })(),
      cssVendor = vendor ? '-' + vendor.toLowerCase() + '-' : '',

      // Style properties
      transform = prefixStyle('transform'),
      transitionDuration = prefixStyle('transitionDuration'),

      // Browser capabilities
      has3d = prefixStyle('perspective') in dummyStyle,
      hasTouch = 'ontouchstart' in window,
      hasPointer = ( window.PointerEvent && window.navigator.maxTouchPoints )
        || ( window.navigator.msPointerEnabled && window.navigator.msMaxTouchPoints )
        || ( window.navigator.pointerEnabled && window.navigator.maxTouchPoints ),
      hasTransform = !!vendor,
      hasTransitionEnd = prefixStyle('transition') in dummyStyle,

      // Helpers
      translateZ = has3d ? ' translateZ(0)' : '',

      // Events
      transitionEndEvent = (function () {
        if ( vendor === false ) return false;

        var transitionEnd = {
            ''      : 'transitionend',
            'webkit'  : 'webkitTransitionEnd',
            'Moz'    : 'transitionend',
            'O'      : 'oTransitionEnd',
            'ms'    : 'MSTransitionEnd'
          };

        return transitionEnd[vendor];
      })(),
      
      AsteikkoScroller = function (el, options) {
        var i,
          div,
          pageIndex;

        this.wrapper = typeof el == 'string' ? document.querySelector(el) : el;
        this.options = {
          text: null,
          numberOfPages: 3,
          hastyPageFlip: false,
          loop: true,
          eventPropagation: true,
          useSwipeEvents: true,
          useMouseEvents: true
        };
      
        // User defined options
        for (i in options) this.options[i] = options[i];
        
        this.wrapper.style.overflow = 'hidden';
        this.wrapper.style.position = 'relative';
        
        this.masterPages = [];
        
        div = document.createElement('div');
        div.id = 'swipeview-slider';
        div.style.cssText = 'position:relative;top:0;height:100%;width:100%;' + cssVendor + 'transition-duration:0;' + cssVendor + 'transform:translateZ(0);' + cssVendor + 'transition-timing-function:ease-out';
        //FIXME div.style.cssText = 'position:relative;top:0;height:100%;width:100%;' + cssVendor + 'transition-duration:0;' + cssVendor + 'transition-timing-function:ease-out';
        this.wrapper.appendChild(div);
        this.slider = div;

        this.refreshSize();

        for (i=-1; i<2; i++) {
          div = document.createElement('div');
          div.id = 'swipeview-masterpage-' + (i+1);
          //FIXME div.style.cssText = cssVendor + 'transform:translateZ(0);position:absolute;top:0;height:100%;width:100%;left:' + i*100 + '%';
          div.style.cssText = 'position:absolute;top:0;height:100%;width:100%;left:' + i*100 + '%';
          if (!div.dataset) div.dataset = {};
          pageIndex = i == -1 ? this.maxPage : i;
          div.dataset.pageIndex = pageIndex;
          div.dataset.upcomingPageIndex = pageIndex;
          
          if (!this.options.loop && i == -1) div.style.visibility = 'hidden';

          this.slider.appendChild(div);
          this.masterPages.push(div);
        }
        
        this.masterPages[1].setAttribute( 'data-active', 'true' );

        if ( this.options.useSwipeEvents && ( hasTouch || hasPointer || this.options.useMouseEvents ) ) {
          this.wrapper.addEventListener(startEvent, this, false);
          this.wrapper.addEventListener(moveEvent, this, false);
          this.wrapper.addEventListener(endEvent, this, false);
        }

        this.slider.addEventListener(transitionEndEvent, this, false);
        // in Opera >= 12 the transitionend event is lowercase so we register both events
        if ( vendor == 'O' ) this.slider.addEventListener(transitionEndEvent.toLowerCase(), this, false);
      };
    
    if ( window.PointerEvent || ( window.navigator.pointerEnabled && window.navigator.maxTouchPoints ) ) {
      var startEvent = 'pointerdown';
      var moveEvent = 'pointermove';
      var endEvent = 'pointerup';
      var cancelEvent = 'pointerup';
    }
    else if ( window.navigator.msPointerEnabled && window.navigator.msMaxTouchPoints ) {
      var startEvent = 'MSPointerDown';
      var moveEvent = 'MSPointerMove';
      var endEvent = 'MSPointerUp';
      var cancelEvent = 'MSPointerUp';
    }
    else {
      var startEvent = hasTouch ? 'touchstart' : 'mousedown';
      var moveEvent = hasTouch ? 'touchmove' : 'mousemove';
      var endEvent = hasTouch ? 'touchend' : 'mouseup';
      var cancelEvent = hasTouch ? 'touchcancel' : 'mouseup';
    }

    AsteikkoScroller.prototype = {
      currentMasterPage: 1,
      x: 0,
      page: 0, // Absolute page number; can be negative
      pageIndex: 0, // Page number relative to the first page (in case options.loop === true); can't be negative
      customEvents: [],
      
      onFlip: function (fn) {
        this.wrapper.addEventListener('swipeview-flip', fn, false);
        this.customEvents.push(['flip', fn]);
      },
      
      onMoveOut: function (fn) {
        this.wrapper.addEventListener('swipeview-moveout', fn, false);
        this.customEvents.push(['moveout', fn]);
      },

      onMoveIn: function (fn) {
        this.wrapper.addEventListener('swipeview-movein', fn, false);
        this.customEvents.push(['movein', fn]);
      },
      
      onTouchStart: function (fn) {
        this.wrapper.addEventListener('swipeview-touchstart', fn, false);
        this.customEvents.push(['touchstart', fn]);
      },
      
      onMoveStart: function (fn) {
        this.wrapper.addEventListener('swipeview-movestart', fn, false);
        this.customEvents.push(['movestart', fn]);
      },
      
      onMoveEnd: function (fn) {
        this.wrapper.addEventListener('swipeview-moveend', fn, false);
        this.customEvents.push(['moveend', fn]);
      },

      destroy: function () {
        while ( this.customEvents.length ) {
          this.wrapper.removeEventListener('swipeview-' + this.customEvents[0][0], this.customEvents[0][1], false);
          this.customEvents.shift();
        }
        
        // Remove the event listeners
        this.wrapper.removeEventListener(startEvent, this, false);
        this.wrapper.removeEventListener(moveEvent, this, false);
        this.wrapper.removeEventListener(endEvent, this, false);
        this.slider.removeEventListener(transitionEndEvent, this, false);

  /*      if (!hasTouch) {
          this.wrapper.removeEventListener('mouseout', this, false);
        }*/
      },

      // You should call this from outside code whenever AsteikkoScroller's 
      // wrapper (e.g. the browser window) changes size.
      refreshSize: function () {
        this.wrapperWidth = this.wrapper.clientWidth;
        this.wrapperHeight = this.wrapper.clientHeight;
        this.pageWidth = this.wrapperWidth;
        this.maxPage = this.options.numberOfPages - 1;
        // On tablets and mobile phones we want the max threshold to be a bit
        // lower, since it's harder to swipe a long way with a finger than it
        // is with a mouse
        var maxSnapThreshold = ( this.pageWidth <= 1280 ) ? 150 : 300;
        this.snapThreshold = Math.min( Math.round(this.pageWidth * 0.20 ), maxSnapThreshold );
        console.log( this.snapThreshold );
      },
      
      updatePageCount: function (n) {
        this.options.numberOfPages = n;
        this.maxPage = this.options.numberOfPages - 1;
      },
      
      goToPage: function (p) {
        var i;

        this.masterPages[this.currentMasterPage].removeAttribute( 'data-active' );
        for (i=0; i<3; i++) {
          className = this.masterPages[i].className;
          if ( ! /(^|\s)swipeview-loading(\s|$)/.test(className) ) {
            this.masterPages[i].className = ( !className )
              ? 'swipeview-loading'
              : className + ' swipeview-loading';
          }
        }
        
        p = p < 0 ? 0 : p > this.maxPage ? this.maxPage : p;
        this.page = p;
        this.pageIndex = p;

        this.currentMasterPage = (this.page + 1) - Math.floor((this.page + 1) / 3) * 3;

        this.masterPages[this.currentMasterPage].setAttribute( 'data-active', 'true' );
        
        this.__finalizeTransition();

        if (this.currentMasterPage === 0) {
          this.masterPages[2].dataset.upcomingPageIndex = this.page === 0 ? this.maxPage : this.page - 1;
          this.masterPages[0].dataset.upcomingPageIndex = this.page;
          this.masterPages[1].dataset.upcomingPageIndex = this.page == this.maxPage ? 0 : this.page + 1;
        } else if (this.currentMasterPage == 1) {
          this.masterPages[0].dataset.upcomingPageIndex = this.page === 0 ? this.maxPage : this.page - 1;
          this.masterPages[1].dataset.upcomingPageIndex = this.page;
          this.masterPages[2].dataset.upcomingPageIndex = this.page == this.maxPage ? 0 : this.page + 1;
        } else {
          this.masterPages[1].dataset.upcomingPageIndex = this.page === 0 ? this.maxPage : this.page - 1;
          this.masterPages[2].dataset.upcomingPageIndex = this.page;
          this.masterPages[0].dataset.upcomingPageIndex = this.page == this.maxPage ? 0 : this.page + 1;
        }
        
        this.__flip();
        this.__checkVisibility();
      },
      
      setCurrentPage: function (p) {
        var i;

        this.masterPages[this.currentMasterPage].removeAttribute( 'data-active' );
        
        p = p < 0 ? 0 : p > this.maxPage ? this.maxPage : p;
        this.page = p;
        this.pageIndex = p;

        this.currentMasterPage = (this.page + 1) - Math.floor((this.page + 1) / 3) * 3;

        this.masterPages[this.currentMasterPage].setAttribute( 'data-active', 'true' );
        
        this.__finalizeTransition();

        if (this.currentMasterPage === 0) {
          this.masterPages[2].dataset.upcomingPageIndex = this.page === 0 ? this.maxPage : this.page - 1;
          this.masterPages[0].dataset.upcomingPageIndex = this.page;
          this.masterPages[1].dataset.upcomingPageIndex = this.page == this.maxPage ? 0 : this.page + 1;
        } else if (this.currentMasterPage == 1) {
          this.masterPages[0].dataset.upcomingPageIndex = this.page === 0 ? this.maxPage : this.page - 1;
          this.masterPages[1].dataset.upcomingPageIndex = this.page;
          this.masterPages[2].dataset.upcomingPageIndex = this.page == this.maxPage ? 0 : this.page + 1;
        } else {
          this.masterPages[1].dataset.upcomingPageIndex = this.page === 0 ? this.maxPage : this.page - 1;
          this.masterPages[2].dataset.upcomingPageIndex = this.page;
          this.masterPages[0].dataset.upcomingPageIndex = this.page == this.maxPage ? 0 : this.page + 1;
        }
        
        for (var i=0; i<3; i++) {
          this.masterPages[i].className = this.masterPages[i].className.replace(/(^|\s)swipeview-loading(\s|$)/, '');    // Remove the loading class
          this.masterPages[i].dataset.pageIndex = this.masterPages[i].dataset.upcomingPageIndex;
        }
        this.__checkVisibility();
      },
      
      next: function () {
        if (!this.options.loop && this.page == this.maxPage) return;

        // Emulate an infinitesimal "swipe" towards the next page by incrementing
        // the x pixel position by 1 pixel. Then we can snap to the new page.
        this.moveStartDeltaX = -1;
        this.x -= 1;
        this.__snapToPosition();
      },

      prev: function () {
        if (!this.options.loop && this.page === 0) return;

        // Emulate an infinitesimal "swipe" towards the prev page by decrementing
        // the x pixel position by 1 pixel. Then we can snap to the new page.
        this.moveStartDeltaX = 1;
        this.x += 1;
        this.__snapToPosition();
      },

      handleEvent: function (e) {
        
        switch (e.type) {
          case startEvent:
            this.__start(e);
            if (!this.options.eventPropagation) {
              e.stopPropagation();
            }
            break;
          case moveEvent:
            this.__move(e);
            if (!this.options.eventPropagation) {
              e.stopPropagation();
            }
            break;
          case cancelEvent:
          case endEvent:
            this.__end(e);
            if (!this.options.eventPropagation) {
              e.stopPropagation();
            }
            break;
          case transitionEndEvent:
          case 'otransitionend':
            if (e.target == this.slider) {
              this.__finalizeTransition();
              if (!this.options.hastyPageFlip) this.__flip();
            }
            break;
        }
      },


      /**
      *
      * Pseudo private methods
      *
      */
      __setPosition: function (x) {
        this.x = x;
        this.slider.style[transform] = 'translate(' + x + 'px,0)' + translateZ;
      },

      __start: function (e) {
        //e.preventDefault();

        if (this.initiated) return;
        
        var point = ( window.TouchEvent && e instanceof TouchEvent ) ? e.touches[0] : e;
        
        this.initiated = true;
        this.moved = false;
        this.thresholdExceeded = false;
        this.startX = point.pageX;
        this.startY = point.pageY;
        this.moveStartX = point.pageX;
        this.moveStartY = point.pageY;
        this.pointX = point.pageX;
        this.pointY = point.pageY;
        this.stepsX = 0;
        this.stepsY = 0;
        this.directionX = 0;
        this.directionLocked = false;
        
  /*      var matrix = getComputedStyle(this.slider, null).webkitTransform.replace(/[^0-9-.,]/g, '').split(',');
        this.x = matrix[4] * 1;*/

        this.slider.style[transitionDuration] = '0s';
        
        this.__event('touchstart');
      },
      
      __move: function (e) {
        if (!this.initiated) return;

        var point = ( window.TouchEvent && e instanceof TouchEvent ) ? e.touches[0] : e,
          deltaX = point.pageX - this.pointX,
          deltaY = point.pageY - this.pointY,
          newX = this.x + deltaX,
          dist = Math.abs(point.pageX - this.startX);

        this.moved = true;
        this.pointX = point.pageX;
        this.pointY = point.pageY;
        this.directionX = deltaX > 0 ? 1 : deltaX < 0 ? -1 : 0;
        this.stepsX += Math.abs(deltaX);
        this.stepsY += Math.abs(deltaY);

        // We take a 10px buffer to figure out the direction of the swipe
        if (this.stepsX < 10 && this.stepsY < 10) {
          // e.preventDefault();
          this.moveStartX = point.pageX;
          this.moveStartY = point.pageY;
          return;
        }

        if (!this.directionLocked) {
          // We are scrolling vertically, so skip AsteikkoScroller and give the control back to the browser
          if (this.stepsY > this.stepsX) {
            this.initiated = false;
            return;
          }
          this.directionLocked = true;
          this.__event('movestart');
        }

        e.preventDefault();

        if (!this.options.loop && 
          ((this.page === 0 && newX > 0) || (this.page === this.maxPage && newX < 0))) 
        {
          newX = this.x + (deltaX / 2);
        }

        if (!this.thresholdExceeded && dist >= this.snapThreshold) {
          this.thresholdExceeded = true;
          this.__event('moveout');
        } else if (this.thresholdExceeded && dist < this.snapThreshold) {
          this.thresholdExceeded = false;
          this.__event('movein');
        }
        
        this.__setPosition(newX);
      },
      
      __end: function (e) {
        if (!this.initiated) return;
        
        var point = ( window.TouchEvent && e instanceof TouchEvent ) ? e.changedTouches[0] : e,
          dist = Math.abs(point.pageX - this.startX);

        this.initiated = false;
        
        if (!this.moved) return;

        e.preventDefault();
        
        this.__event('moveend');

        if (!this.options.loop && 
          ((this.page === 0 && this.x > 0) || (this.page === this.maxPage && this.x < 0))) 
        {
          dist = 0;
          this.__event('movein');
        }

        // Check if we exceeded the snap threshold
        if (dist < this.snapThreshold) {
          this.slider.style[transitionDuration] = Math.floor(300 * dist / this.snapThreshold) + 'ms';
          this.__setPosition(0);
          return;
        }
        
        this.moveStartDeltaX = point.pageX - this.moveStartX;

        this.__snapToPosition();
      },
      
      __snapToPosition: function () {
        var pageFlip,
          pageFlipIndex,
          className;
        
        //this.masterPages[0].style[transform] = translateZ;
        //this.masterPages[1].style[transform] = translateZ;
        //this.masterPages[2].style[transform] = translateZ;

        this.masterPages[this.currentMasterPage].removeAttribute( 'data-active' );

        // Flip the page
        if (this.moveStartDeltaX > 0) { // moving to earlier page

          this.page--;
          this.currentMasterPage = (this.page + 1) - Math.floor((this.page + 1) / 3) * 3;
          this.pageIndex = this.pageIndex === 0 ? this.maxPage : this.pageIndex - 1;

          pageFlip = this.currentMasterPage - 1;
          pageFlip = pageFlip < 0 ? 2 : pageFlip;
          this.masterPages[pageFlip].style.left = -Math.ceil(this.x / this.pageWidth) * 100 - 100 + '%';

          pageFlipIndex = this.page - 1;

          newX = this.x + this.pageWidth - this.moveStartDeltaX;
        }
        else { // moving to later page

          this.page++;
          this.currentMasterPage = (this.page + 1) - Math.floor((this.page + 1) / 3) * 3;
          this.pageIndex = this.pageIndex == this.maxPage ? 0 : this.pageIndex + 1;

          pageFlip = this.currentMasterPage + 1;
          pageFlip = pageFlip > 2 ? 0 : pageFlip;
          this.masterPages[pageFlip].style.left = -Math.floor(this.x / this.pageWidth) * 100 + 100 + '%';

          pageFlipIndex = this.page + 1;
        
          newX = this.x - this.pageWidth - this.moveStartDeltaX;
        }

        // Add active attr to current page
        this.masterPages[this.currentMasterPage].setAttribute( 'data-active', 'true' );

        // Add loading class to flipped page
        className = this.masterPages[pageFlip].className;
        if ( ! /(^|\s)swipeview-loading(\s|$)/.test(className) ) {
          this.masterPages[pageFlip].className = ( !className )
            ? 'swipeview-loading'
            : className + ' swipeview-loading';
        }
        
        pageFlipIndex = pageFlipIndex - Math.floor(pageFlipIndex / this.options.numberOfPages) * this.options.numberOfPages;
        this.masterPages[pageFlip].dataset.upcomingPageIndex = pageFlipIndex;    // Index to be loaded in the newly flipped page

        this.slider.style[transitionDuration] = Math.floor(500 * Math.abs(this.x - newX) / this.pageWidth) + 'ms';
        
        // Hide the next page if we decided to disable looping
        if (!this.options.loop) {
          this.masterPages[pageFlip].style.visibility = this.page === 0 || this.page === this.maxPage ? 'hidden' : '';
        }

        if (this.x == newX) {
          this.__finalizeTransition();
          this.__flip();    // If we swiped all the way long to the next page (extremely rare but still)
        } else {
          this.__setPosition(newX);
          if (this.options.hastyPageFlip) this.__flip();
        }
      },
      
      __flip: function () {
        this.__event('flip');

        for (var i=0; i<3; i++) {
          this.masterPages[i].className = this.masterPages[i].className.replace(/(^|\s)swipeview-loading(\s|$)/, '');    // Remove the loading class
          this.masterPages[i].dataset.pageIndex = this.masterPages[i].dataset.upcomingPageIndex;
        }
      },
      
      __event: function (type) {
        var ev = document.createEvent("Event");
        
        ev.initEvent('swipeview-' + type, true, true);

        this.wrapper.dispatchEvent(ev);
      },

      /* Method to fix visibility issues */
      __checkVisibility: function () {
        if (this.options.loop) {
          return;
        }

        if (this.pageIndex == 0) {
          this.masterPages[0].style.visibility = 'hidden';
          this.masterPages[1].style.visibility = '';
          if (this.options.numberOfPages == 1) {
            this.masterPages[2].style.visibility = 'hidden';
          } else {
            this.masterPages[2].style.visibility = '';
          }
        } else if (this.pageIndex == this.maxPage) {
          for (var i = 0; i < 3; i++) {
            if (i == this.currentMasterPage ||
                i == this.currentMasterPage - 1 ||
                (i == 2 && this.currentMasterPage == 0)) {
              this.masterPages[i].style.visibility = '';
            } else {
              this.masterPages[i].style.visibility = 'hidden';
            }
          }
        } else {
          for (var i = 0; i < 3; i++) {
            this.masterPages[i].style.visibility = '';
          }
        }
      },
      
      __finalizeTransition: function() {
        
        this.x = 0;
        this.slider.style[transitionDuration] = '0s';

        //this.slider.style[transform] = ''; // FIXME
        this.slider.style[transform] = translateZ; // FIXME
        
        if (this.currentMasterPage === 0) {
          this.masterPages[2].style.left = '-100%';
          this.masterPages[0].style.left = '0%';
          this.masterPages[1].style.left = '100%';
        } else if (this.currentMasterPage == 1) {
          this.masterPages[0].style.left = '-100%';
          this.masterPages[1].style.left = '0%';
          this.masterPages[2].style.left = '100%';
        } else {
          this.masterPages[1].style.left = '-100%';
          this.masterPages[2].style.left = '0%';
          this.masterPages[0].style.left = '100%';
        }
        
        // FIXME
        //this.masterPages[0].style[transform] = '';
        //this.masterPages[1].style[transform] = '';
        //this.masterPages[2].style[transform] = '';
      }
    };

    function prefixStyle (style) {
      if ( vendor === '' ) return style;

      style = style.charAt(0).toUpperCase() + style.substr(1);
      return vendor + style;
    }

    return AsteikkoScroller;
  })(window, document);
}
