/*****************************************************************************
  Note-taking feature
*****************************************************************************/

if ( !window.Asteikko ) window.Asteikko = {};

( function() {  
  
  function saveServerIssueNotesLocally(issueNotes) {
  
    var localNotes = JSON.parse(Asteikko.localStorage.get('notes_cache'));
    if (!localNotes) localNotes = [];
    
    // First remove local notes that haven't been created locally, and haven't 
    // been modified locally for a while.
    for (var i = localNotes.length - 1; i >= 0; i--) {
      if ( !localNotes[i]._createdLocally && 
       ( !localNotes[i]._modifiedLocally || localNoteExpired(localNotes[i]) ) )
      {
        localNotes.splice( i, 1 );
      }
    }
    
    // Populate local notes with notes from server
    for (var i = 0; i < issueNotes.length; i++) {
      var localNoteSeen = false;
      for (var j = 0; j < localNotes.length; j++) {
        if (issueNotes[i].id == localNotes[j].id && !localNotes[j]._createdLocally) {
          localNoteSeen = true;
          if (!localNotes[j]._modifiedLocally) {
            localNotes[j] = issueNotes[i];
          }
          break;
        }
      }
      if (!localNoteSeen) {
        localNotes.push(issueNotes[i]);
      }
    }
    return Asteikko.localStorage.set('notes_cache', JSON.stringify(localNotes));
  }
  
  function localNoteExpired( localNote ) {
  
    var noteExpireDate = new Date( localNote._lastModified );
    noteExpireDate.setUTCDate( noteExpireDate.getUTCDate() + 7 ); // 1 week
    var noteExpire = noteExpireDate.getTime();
    var now = ( new Date() ).getTime();
    return now >= noteExpire;
  }

  Asteikko.onlineFuncs.addNote = function(issue, page, section, content, callback) {
    if (typeof issue === "undefined" || !issue || typeof page === "undefined" ||
        !page || typeof content === "undefined") {
      return;
    }
    
    // If user is not logged in, add note temporarily to local storage
    if (Asteikko.lastLoginStatus === false) {
      return Asteikko.offlineFuncs.addNote(issue, page, section, content, callback);
    }

    var params = {issue: issue, page: page, content: content};

    if (typeof section !== "undefined" && section) {
      params.section = section;
    }

    $.ajax({
      url: Asteikko.env.site_url +'/asmag/notes/?action=add',
      type: 'POST',
      dataType: 'json',
      data: params
    }).done(function(stat) {
      if (typeof callback === "function" && callback) {
        if (stat !== false) {
          callback(stat);
        } else {
          callback(false);
        }
      }
    }).fail(function() {
      if (typeof callback === "function" && callback) {
        callback(false);
      }
    });
  };

  Asteikko.offlineFuncs.addNote = function(issue, page, section, content, callback) {
  
    if ( !window.settings.notes.enabled ) throw 'Notes are not enabled';
    
    if (typeof issue === "undefined" || !issue || typeof page === "undefined" ||
        !page || typeof content === "undefined") {
      return;
    }
    
    var notes = JSON.parse(Asteikko.localStorage.get('notes_cache'));
    if (!notes) notes = [];
    
    // addNote() returns the note's database ID when the note is created. We need 
    // to create an ID when offline, so we'll find an ID that isn't used by the 
    // notes in the local cache, but this ID might conflict with a note's ID on the 
    // server. Therefore we'll add an additional property to any notes created locally
    // (_createdLocally), so we know that their ID should NOT be used to update a 
    // server-note with the text content of a local-note.
    var newNoteId = 1;
    for (var i = 0; i < notes.length; i++) {
      if (notes[i].id >= newNoteId) newNoteId = parseInt( notes[i].id, 10 ) + 1;
    }
    
    notes.push( { 
      id: newNoteId,
      issue_id: String(Number(issue)),
      page_id: String(Number(page)),
      section_id: section ? String(Number(section)) : null,
      text: content,
      user_id: null,
      _createdLocally: true
    } );
    var saved = Asteikko.localStorage.set('notes_cache', JSON.stringify(notes));
    if (typeof callback === "function") callback(newNoteId);
  };

  Asteikko.onlineFuncs.updateNote = function(note, content, callback) {
    if (typeof note === "undefined" || !note || typeof content === "undefined") {
      return;
    }
    
    // If user is not logged in, update note temporarily in local storage
    if (Asteikko.lastLoginStatus === false) {
      return Asteikko.offlineFuncs.updateNote(note, content, callback);
    }

    $.ajax({
      url: Asteikko.env.site_url +'/asmag/notes/?action=update',
      type: 'POST',
      dataType: 'json',
      data: {note: note, content: content}
    }).done(function(stat) {
      if (typeof callback === "function" && callback) {
        if (stat !== false) {
          callback(true);
        } else {
          callback(false);
        }
      }
    }).fail(function() {
      if (typeof callback === "function" && callback) {
        callback(false);
      }
    });
  };

  Asteikko.onlineFuncs.updateOrAddNote = function(issue, page, section, content, callback) {
    if (typeof issue === "undefined" || !issue || typeof page === "undefined" ||
        !page || typeof content === "undefined") {
      return;
    }

    // If user is not logged in, add note temporarily to local storage
    if (Asteikko.lastLoginStatus === false) {
      return Asteikko.offlineFuncs.updateOrAddNote(issue, page, section, content, callback);
    }

    var params = {issue: issue, page: page, content: content};

    if (typeof section !== "undefined" && section) {
      params.section = section;
    }

    $.ajax({
      url: Asteikko.env.site_url +'/asmag/notes/?action=update',
      type: 'POST',
      dataType: 'json',
      data: params
    }).done(function(stat) {
      if (typeof callback === "function" && callback) {
        if (stat !== false) {
          callback(stat);
        } else {
          callback(false);
        }
      }
    }).fail(function() {
      if (typeof callback === "function" && callback) {
        callback(false);
      }
    });
  };
  
  Asteikko.offlineFuncs.updateOrAddNote = function(issue, page, section, content, callback) {
  
    if ( !window.settings.notes.enabled ) throw 'Notes are not enabled';
    
    if (typeof issue === "undefined" || !issue || typeof page === "undefined" ||
        !page || typeof content === "undefined") {
      return;
    }
    
    var notes = JSON.parse(Asteikko.localStorage.get('notes_cache'));
    if (notes) {
      for (var i = 0; i < notes.length; i++) {
        if (notes[i].issue_id == issue && notes[i].page_id == page) {
          if ((section && notes[i].section_id == section) || !section && notes[i].section_id === null) {
            return Asteikko.offlineFuncs.updateNote(notes[i].id, content, callback);
          }
        }
      }
    }
    return Asteikko.offlineFuncs.addNote(issue, page, section, content, callback);
  };
  
  Asteikko.offlineFuncs.updateNote = function(note, content, callback) {
  
    if ( !window.settings.notes.enabled ) throw 'Notes are not enabled';
  
    if (typeof note === "undefined" || !note) {
      return;
    }
    
    var notes = JSON.parse(Asteikko.localStorage.get('notes_cache'));
    if (!notes) {
      if (typeof callback === "function") callback(false);
      return;
    }
    
    for (var i = 0; i < notes.length; i++) {
      if (notes[i].id == note) {
        notes[i].text = content;
        notes[i]._modifiedLocally = true;
        notes[i]._lastModified = ( new Date() ).getTime();
        break;
      }
    }
    var saved = Asteikko.localStorage.set('notes_cache', JSON.stringify(notes));
    if (typeof callback === "function") callback(saved);
  };

  Asteikko.onlineFuncs.removeNote = function(note, callback) {
    if (typeof note === "undefined" || !note) {
      return;
    }
    
    // If user is not logged in, remove note temporarily from local storage
    if (Asteikko.lastLoginStatus === false) {
      return Asteikko.offlineFuncs.removeNote(note, callback);
    }

    $.ajax({
      url: Asteikko.env.site_url +'/asmag/notes/?action=remove',
      type: 'POST',
      dataType: 'json',
      data: {note: note}
    }).done(function(stat) {
      if (typeof callback === "function" && callback) {
        if (stat !== false) {
          callback(true);
        } else {
          callback(false);
        }
      }
    }).fail(function() {
      if (typeof callback === "function" && callback) {
        callback(false);
      }
    });
  };
  
  Asteikko.offlineFuncs.removeNote = function(note, callback) {
  
    if ( !window.settings.notes.enabled ) throw 'Notes are not enabled';
    // We won't allow removing notes offline, only modifying them. This simplifies 
    // syncing the notes with the server when going online.
    return Asteikko.offlineFuncs.updateNote(note, '', callback);
  };

  Asteikko._getNotesReal = function(params, callback) {
    if (typeof params === "undefined" || !params || typeof callback === "undefined" || !callback) {
      return;
    }

    $.ajax({
      url: Asteikko.env.site_url +'/asmag/notes/?action=get',
      type: 'POST',
      dataType: 'json',
      data: params
    }).done(function(data) {
      if (typeof callback === "function" && callback) {
        if (data) {
          saveServerIssueNotesLocally(data);
          callback(data);
        } else {
          callback(null);
        }
      }
    }).fail(function() {
      if (typeof callback === "function" && callback) {
        callback(null);
      }
    });
  };

  Asteikko.onlineFuncs.getNotes = function(issue, page, section, callback) {
    if (typeof issue === "undefined" || !issue || typeof callback === "undefined" || !callback) {
      return;
    }
    
    // If user is not logged in, get notes from local storage
    if (Asteikko.lastLoginStatus === false) {
      return Asteikko.offlineFuncs.getNotes(issue, page, section, callback);
    }

    var params = {issue: issue};

    if (typeof page !== "undefined" && page) {
      params.page = page;
      if (typeof section !== "undefined" && section) {
        params.section = section;
      }
    }

    Asteikko._getNotesReal(params, callback);
  };
  
  Asteikko.offlineFuncs.getNotes = function(issue, page, section, callback) {
  
    if ( !window.settings.notes.enabled ) throw 'Notes are not enabled';
  
    if (typeof issue === "undefined" || !issue || typeof callback === "undefined" || !callback) {
      return;
    }
    
    var notes = JSON.parse(Asteikko.localStorage.get('notes_cache'));
    if (!notes) return callback(null);
    
    if (page) {
      if (section) {
        var ret = [];
        for (var i = 0; i < notes.length; i++) {
          if (notes[i].issue_id == issue && notes[i].page_id == page && notes[i].section_id == section)
            ret.push(notes[i]);
        }
        callback(ret);
      }
      else {
        var ret = [];
        for (var i = 0; i < notes.length; i++) {
          if (notes[i].issue_id == issue && notes[i].page_id == page)
            ret.push(notes[i]);
        }
        callback(ret);
      }
    }
    else {
      var ret = [];
      for (var i = 0; i < notes.length; i++) {
        if (notes[i].issue_id == issue) ret.push(notes[i]);
      }
      callback(ret);
    }
  };

  Asteikko.onlineFuncs.getNoteByID = function(note, callback) {
    if (typeof note === "undefined" || !note || typeof callback === "undefined" || !callback) {
      return;
    }
    // If user is not logged in, get note from local storage
    if (Asteikko.lastLoginStatus === false) {
      return Asteikko.offlineFuncs.getNoteByID(note, callback);
    }
    Asteikko._getNotesReal({note: note}, callback);
  };

  Asteikko.offlineFuncs.getNoteByID = function(note, callback) {
  
    if ( !window.settings.notes.enabled ) throw 'Notes are not enabled';
    
    if (typeof note === "undefined" || !note || typeof callback === "undefined" || !callback) {
      return;
    }
    var notes = JSON.parse(Asteikko.localStorage.get('notes_cache'));
    if (!notes) return callback(null);
    var ret = null;
    for (var i = 0; i < notes.length; i++) {
      if (notes[i].id == notes) ret = [notes[i]];
    }
    callback(ret);
  };

  Asteikko.onlineFuncs.listNotes = function(callback, issue, page) {
    if (typeof callback === "undefined" || !callback) {
      return;
    }
    
    // If user is not logged in, get notes from local storage
    if (Asteikko.lastLoginStatus === false) {
      return Asteikko.offlineFuncs.listNotes(callback, issue, page);
    }

    var request = {
      url: Asteikko.env.site_url +'/asmag/notes/?action=list',
      type: 'POST',
      dataType: 'json'
    };

    if (typeof issue !== "undefined" && issue) {
      request.data = {issue: issue};

      if (typeof page !== "undefined" && page) {
        request.data.page = page;
      }
    }

    $.ajax(request).done(function(data) {
      if (typeof callback === "function" && callback) {
        if (data !== false) {
          callback(data);
        } else {
          callback(null);
        }
      }
    }).fail(function() {
      if (typeof callback === "function" && callback) {
        callback(null);
      }
    });
  };
  
  Asteikko.offlineFuncs.listNotes = function(callback, issue, page) {
  
    if ( !window.settings.notes.enabled ) throw 'Notes are not enabled';
  
    function getSimplifiedNote(noteObj) {
      return {
        id: noteObj.id,
        issue_id: noteObj.issue_id,
        page_id: noteObj.page_id,
        section_id: noteObj.section_id
      };
    }
  
    if (typeof callback === "undefined" || !callback) {
      return;
    }
    
    var notes = JSON.parse(Asteikko.localStorage.get('notes_cache'));
    if (!notes) return callback(null);
    
    var ret = [];
    if (issue) {
      if (page) {
        for (var i = 0; i < notes.length; i++) {
          if (notes[i].issue_id == issue && notes[i].page_id == page)
            ret.push(getSimplifiedNote(notes[i]));
        }
      }
      else {
        for (var i = 0; i < notes.length; i++) {
          if (notes[i].issue_id == issue)
            ret.push(getSimplifiedNote(notes[i]));
        }
      }
    }
    else {
      for (var i = 0; i < notes.length; i++) {
        ret.push(getSimplifiedNote(notes[i]));
      }
    }
    if (ret.length) callback(ret);
    else callback(null);
  };
  
  // When user is logged in, save locally added notes to the server.
  // asmag_loginstatus is also fired when network connectivity changes.
  $(window).bind('asmag_loginstatus', function(newStatus) {

    if ( !window.settings.notes.enabled )
      return;
  
    if (newStatus === false)
      return;

    var localNotes = JSON.parse(Asteikko.localStorage.get('notes_cache'));
    if (!localNotes || !localNotes.length)
      return;
  
    function onNoteSaved() {
    
      currSaveIndex++;
      
      if ( currSaveIndex === notesToCreate.length + notesToUpdate.length ) {
        // Cache saved to server, update the local cache from the server
        Asteikko.localStorage.remove('notes_cache');
        // Get IDs of all the issues that have notes
        var noteIssueIds = [];
        for ( var i = 0; i < localNotes.length; i++ ) {
          if ( $.inArray( localNotes[ i ].issue_id, noteIssueIds ) === -1 ) {
            noteIssueIds.push( localNotes[ i ].issue_id );
          }
        }
        var getNotesCount = 0;
        var allNotesFromServer = [];
        for ( var i = 0; i < noteIssueIds.length; i++ ) {
          // Calling this function will update the local cache with the issue's notes
          Asteikko.onlineFuncs.getNotes( noteIssueIds[ i ], null, null, function( serverIssueNotes ) {
            getNotesCount++;
            allNotesFromServer = allNotesFromServer.concat( serverIssueNotes );
            if ( getNotesCount === noteIssueIds.length ) {
              $( window ).trigger( 'asmag_notes_localsavedtoserver', [ localNotes, allNotesFromServer ] );
            }
          } );
        }
      }
      else {
        saveNextNote();
      }
    }
    
    function saveNextNote() {
      if ( currSaveIndex < notesToCreate.length ) {
        var issueId = notesToCreate[ currSaveIndex ].issue_id;
        var pageId = notesToCreate[ currSaveIndex ].page_id;
        var sectionId = notesToCreate[ currSaveIndex ].section_id;
        var content = notesToCreate[ currSaveIndex ].text;
      }
      else {
        var issueId = notesToUpdate[ currSaveIndex - notesToCreate.length ].issue_id;
        var pageId = notesToUpdate[ currSaveIndex - notesToCreate.length ].page_id;
        var sectionId = notesToUpdate[ currSaveIndex - notesToCreate.length ].section_id;
        var content = notesToUpdate[ currSaveIndex - notesToCreate.length ].text;
      }
      Asteikko.onlineFuncs.updateOrAddNote(issueId, pageId, sectionId, content, onNoteSaved);
    }
    
    var notesToCreate = [];
    var notesToUpdate = [];
    var currSaveIndex = 0;
    
    for ( var i = 0; i < localNotes.length; i++ ) {
      if ( localNotes[i]._createdLocally ) {
        notesToCreate.push(localNotes[i]);
      }
      else if ( localNotes[i]._modifiedLocally ) {
        if ( !localNoteExpired( localNotes[i] ) ) {
          notesToUpdate.push(localNotes[i]);
        }
      }
    }
    if ( notesToCreate.length || notesToUpdate.length ) saveNextNote();
  });

} )();