
/**
 * Firefox has a weird behaviour where it retains some of the earlier pages'
 * scrollTop() in later pages, even if the later pages was never scrolled:
 *
 * 1. Scroll an overflow:auto area down on an issue page
 * 2. Flip the pages forward a few times
 * 3. At some point a later page will have the scrollTop() of the earlier 
 *    page that you scrolled down in
 */
$(window).bind( 'pageLoaded', function( event, index ) {
  window.setTimeout( function() {
    $( '#asmag-page-'+ window.pages[ index ].id +' .scroll' ).scrollTop( 0 );
  }, 50 );
} );
