/*****************************************************************************
  Highlight feature's UI-related functions
*****************************************************************************/

if ( !window.Asteikko ) window.Asteikko = {};

( function() {

  var issueHighlights = {};
  
  function sendPageHighlightsToServer( pageId ) {

    Asteikko.log( 'Sending highlights for page ID '+ pageId +' to the server' );

    pageId = parseInt( pageId, 10 );
  
    var serializedHighlights = $._highlighter.serialize( {
      containerElementId: 'asmag-page-'+ pageId,
      serializeHighlightText: true
    } );
    
    if (serializedHighlights) {
      Asteikko.addHighlight(asteikkoIssue.id, pageId, serializedHighlights);
      issueHighlights[ pageId ] = serializedHighlights;
    } else {
      Asteikko.removeHighlight(asteikkoIssue.id, pageId);
      delete issueHighlights[ pageId ];
    }
  }
  
  function onLoadHighlights(data) {
  
    if (data) {

      Asteikko.log( 'Received highlights for '+ data.length +' pages from the server' );

      for (var i = 0; i < data.length; i++) {
        if (data[i].page_id && data[i].highlight && data[i].issue_id == asteikkoIssue.id) {
          issueHighlights[ parseInt( data[i].page_id, 10 ) ] = data[i].highlight;
        }
      }
    }
  }
  
  function getNotifyElem( pageIndex ) {
    
    var elem = $( '#notapplied-highlights' );
    elem.find( '.notapplied-highlights-row' ).remove();
    
    if ( !elem.length ) {
    
      var elem = $( '<div id="notapplied-highlights"></div>' );
      var pageNr = pageIndex + 1 + ( window.asteikkoIssue.page_numbering_offset || 0 );
      
      $( '<div id="notapplied-highlights-content"></div>' ).
        append( settings.lang.base.ui.highlights_not_applied.replace( '%%PAGENR%%', pageNr ) ).
        appendTo( elem );
      $( '<div id="notapplied-highlights-close" class="asmag-textButton"><span>OK</span></div>' ).
        appendTo( elem ).
        click( function() {
          $( '#notapplied-highlights' ).fadeOut();
        } );
    }
    elem.hide();
    return elem;
  }
  
  function onHighlightsSavedToServer( event, localHighlights, serverHighlights ) {
    onLoadHighlights( serverHighlights );
  }


  /********************** Public **********************/

  Asteikko.highlightsUi = {};

  /**
   * Create <span> elements for user's highlit text in the current issue page.
   * 
   * NOTE: this method must never be called _before_
   * Asteikko.pageScroller.pageFinishLoadFunc() or
   * Asteikko.pageSwitcher.pageFinishLoadFunc() has fired, as an issue page's
   * article template can add HTML nodes to the page before pageFinishLoadFunc().
   * 
   * When nodes are added to the page dynamically in a page's Displayed###()
   * callback (@see Asteikko.pageScroller.pageDisplayedFuncPrefix), the user can
   * be highlighting the added nodes. If we call applyToPage() before the
   * Displayed###() callback, those nodes are not there yet, and the highlights'
   * offsets will be invalid.
   */
  Asteikko.highlightsUi.applyToPage = function( pageId ) {
    
    pageId = parseInt( pageId, 10 );

    var pageElem = $( '#asmag-page-'+ pageId );

    if ( !pageElem.length || !$._highlighter )
      return;
    
    // Don't apply highlights twice, as it causes wrong texts to be highlit
    // on Firefox 48.0 on the second apply
    if ( pageElem.data( 'asmag_highlights_applied' ) )
      return;

    pageElem.data( 'asmag_highlights_applied', true );

    if (typeof issueHighlights[pageId] === "string" && issueHighlights[pageId]) {

      Asteikko.log( 'Applying highlights to page ID '+ pageId );

      var deserialized = $._highlighter.deserialize(issueHighlights[pageId]);
      
      // Inform the user if any highlights were ignored
      if ( deserialized.notApplied.length ) {
        
        var pageIndex = '';
        for ( var i = 0; i < pages.length; ++i ) {
          if ( pages[ i ].id == pageId ) {
            pageIndex = i;
            break;
          }
        }
        var notifyElem = getNotifyElem( pageIndex );
        var notifyContent = notifyElem.find( '#notapplied-highlights-content' );
        
        for ( var i = 0; i < deserialized.notApplied.length; ++i ) {
          if ( !deserialized.notApplied[ i ][ 5 ] ) // no orig highlight text
            continue;
          var text = decodeURIComponent( deserialized.notApplied[ i ][ 5 ] ).substr( 0, 200 );
          notifyContent.append( '<div class="notapplied-highlights-row">'+ text +'</div>' );
        }
        
        $( '#magazine-wrapper' ).append( notifyElem );
        notifyElem.fadeIn();
      }
    }
  };

  Asteikko.highlightsUi.create = function( elem ) {

    if ( settings.highlights.enabled && window.getSelection ) {
      $( elem ).textSelect({
        getID: function() {
          return 'asmag-page-'+ pages[ currentIndex ].id;
        },
        highlightLabel: settings.highlights.highlightLabel,
        unhighlightLabel: settings.highlights.unhighlightLabel,
        labelParent: $( '#asmag-issue' )
      });
    }
    
    $( elem ).unbind( "highlightChanged.asmagissue" );
    $( elem ).bind( "highlightChanged.asmagissue", function() {
      sendPageHighlightsToServer( pages[ currentIndex ].id );
    } );

    $( window ).unbind( 'asmag_highlights_localsavedtoserver', onHighlightsSavedToServer );
    $( window ).bind( 'asmag_highlights_localsavedtoserver', onHighlightsSavedToServer );

    if ( settings.highlights.enabled && window.getSelection ) {
      Asteikko.getHighlights(asteikkoIssue.id, null, onLoadHighlights);
    }
  };

} )();
