/*****************************************************************************
  Issue downloading
*****************************************************************************/

if ( !window.Asteikko ) window.Asteikko = {};

( function() {
  
  var currentIssueDownload = null;
  var requestTimeout = 15000;

  function downloadIssue( issueMeta ) {

    if ( issueMeta.id == currentIssueDownload ) {
      Asteikko.warn( 'Already downloading issue '+ issueMeta.id +'; not starting another download' );
      return;
    }

    currentIssueDownload = issueMeta.id;
    $( window ).trigger( 'asmag_startpagecaching', issueMeta.id );

    // We can't fit multiple issues into localStorage at the moment
    Asteikko.issueCache.clearCacheForOtherIssuesThan( issueMeta.id );
    Asteikko.issueCache.saveIssueMeta( issueMeta.id, issueMeta );
    
    var pageLoadingIndex = 0;
    var pageContents = [];
    var maxDownloadRetries = 5;
    var downloadRetries = 0;
    
    // get issue cover for magazine listing widgets
    $.ajax( {
      url: Asteikko.env.site_url +'/asmag/issuecover/?id='+ issueMeta.id +'&base64=true',
      dataType: 'text',
      cache: false,
      timeout: requestTimeout
    } ).
    done( function( base64Str ) {
      Asteikko.issueCache.saveIssueCover( issueMeta.id, base64Str );
      savePageData();
      loadNextPage();
    } ).
    fail( function() {
      savePageData();
      loadNextPage();
    } );

    function loadNextPage() {

      if (currentIssueDownload !== issueMeta.id)
        return;
      if (pageLoadingIndex >= issueMeta.pages.length)
        return;

      // If the page is already downloaded and hasn't been modified,
      // don't redownload it.
      var page = issueMeta.pages[pageLoadingIndex];
      var oldPage = Asteikko.issueCache.getPage( issueMeta.id, page.id );

      if (oldPage && oldPage.modified == page.modified) {
        Asteikko.log( 'Skipped downloading of page '+ ( pageLoadingIndex + 1 ) );
        $( window ).trigger( 'asmag_pagecached', [ issueMeta.id, pageLoadingIndex + 1, issueMeta.pages.length ] );
        pageLoadingIndex++;
        loadNextPage();
        return;
      }

      $.ajax( {
        url: page.url,
        dataType: 'text',
        cache: false,
        timeout: requestTimeout
      } ).
        done( function(content) {

          if (currentIssueDownload !== issueMeta.id)
            return;

          pageContents.push( {
            id: page.id,
            modified: page.modified,
            content: content
          } );

          $( window ).trigger( 'asmag_pagecached', [ issueMeta.id, pageLoadingIndex + 1, issueMeta.pages.length ] );
          pageLoadingIndex++;
          Asteikko.log( issueMeta.id +" page data downloaded: "+ pageLoadingIndex +"/"+ issueMeta.pages.length +" done" );

          setTimeout(loadNextPage, 800);
        } ).
        fail( function() {

          if (currentIssueDownload !== issueMeta.id)
            return;

          downloadRetries++;
          if (downloadRetries > maxDownloadRetries) {
            savePageData();
            if (pageLoadingIndex < issueMeta.pages.length)
              finishIssueDownload();
            return;
          }

          setTimeout(loadNextPage, 2000);
        } );
    }
    
    function savePageData() {

      if (currentIssueDownload !== issueMeta.id)
        return;

      Asteikko.log('save cache page data');

      for ( var i = 0; i < pageContents.length; i++ ) {
        Asteikko.issueCache.savePage(
          issueMeta.id,
          pageContents[ i ].id,
          pageContents[ i ].content,
          pageContents[ i ].modified
        );
      }

      pageContents.length = 0;

      if (pageLoadingIndex < issueMeta.pages.length) {
        // If we haven't downloaded all pages yet, do the next save after
        // a delay so as not to make the device do too much work
        // (saving this large strings to localStorage is a heavy operation)
        window.setTimeout( savePageData, 5000 ); // write to localStorage every 5 sec
      }
      else {
        // After we've downloaded all pages
        finishIssueDownload();
      }
    }
    
    function finishIssueDownload() {

      if (currentIssueDownload !== issueMeta.id)
        return;

      Asteikko.log( 'Finish issue download' );
      currentIssueDownload = null;
      pageContents = undefined;
      
      $( window ).trigger( 'asmag_endpagecaching', issueMeta.id );
    }
    
    return true;
  }
  
  Asteikko.issueDownloader = {

    downloadForMetadata: function( issueMeta ) {

      downloadIssue( issueMeta );
    },

    download: function( issueId ) {

      $.ajax( {
        url: Asteikko.env.site_url +'/asmag/issue/?id='+ issueId,
        dataType: 'json',
        cache: false,
        timeout: requestTimeout
      } ).
        done( downloadIssue ).
        fail( function( jqXHR, textStatus, errorThrown ) {
          Asteikko.error( 'Could not start issue '+ issueId +' download because request failed' );
        } );
    },

    init: function() {

      if ( settings.offline.enabled ) {
        // Whenever connectivity changes, try re-downloading current issue
        $(window).bind('asmag_connectivity', function( event, isOnline ) {
          if ( isOnline && window.asteikkoIssue ) {
            var issueId = parseInt( window.asteikkoIssue.id, 10 );
            Asteikko.issueDownloader.download( issueId );
          }
        });
      }
    }
  };

} )();
