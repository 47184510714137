(function($) {

  'use strict';
  
  // Bail when we have an unsupported browser, like IE8
  if ( !window.getSelection ) {
    return;
  }
  
  if ( !window.rangy ) throw 'textSelect requires Rangy';
  
  
  var unappliedHighlights = [];

  $._textSelectionChangedTimeout = null;
  $._tapholdTimeout = null;

  function clearSelection() {
    if (window.getSelection) {
      if (window.getSelection().empty) {  // Chrome
       window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {  // Firefox
       window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {  // IE?
      document.selection.empty();
    }
  }

  // Return current selection bounding coordinates. If no selection is found returns null
  function getSelectionBoundaries() {
    var sel = document.selection, range;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.rangeCount) {
        range = sel.getRangeAt(0).cloneRange();
        if (range.getBoundingClientRect) {
          var rect = range.getBoundingClientRect();
          return { top: rect.top , right: rect.right , bottom: rect.bottom, left: rect.left};
        }
      }
    }
    return null;
  }
  
  $._generateTextSelectionChangedEvent = function(event) {
  
    var selection = window.getSelection();
    if (selection && selection.toString()) {
      // Notify textSelectionChanged (from the first node of the selection)
      var topMostObj = selection.getRangeAt(0).startContainer.parentNode;
      $(topMostObj).trigger("textSelectionChanged");
    }
    else {
      var highlightPopup = $("#highlightPopup");
      // Hide the popup if we're not unhighlighting
      if (!$('#highlightPopupOff').is(':visible')) {
        highlightPopup.hide();
      }
    }
  };

  $._checkTextSelection = function(e) {
    
    clearTimeout($._textSelectionChangedTimeout);
    $._textSelectionChangedTimeout = setTimeout($._generateTextSelectionChangedEvent, 300);
  };
  
  $._hideUnhighlight = function(event) {
    // If the touch/mousedown target is the highlight button, return
    if ($(event.target).is('#highlightPopup, #highlightPopup *')) {
      return;
    }
    var selection = window.getSelection();
    var hasSelection = selection && selection.toString();
    if (!hasSelection || $('#highlightPopupOff').is(':visible')) {
      $("#highlightPopup").hide();
    }
  };
  
  $._onTextHoldStart = function(event){
      
      var highlightSpan = $(this).closest('.highlight');
      var highlightable = $(this).closest('.texthighlightable');
      if (highlightSpan.length) {
        var highlightNode = highlightSpan.get(0);
        if (highlightNode) {
          (function(_node, _obj) {
            $._tapholdTimeout = setTimeout(function() {
            
              clearTimeout($._textSelectionChangedTimeout);
              unappliedHighlights = $._highlighter.getHighlightsForElement(_node);

              var rect = highlightNode.getBoundingClientRect();
      
              $("#highlightPopupOff").show();
              $("#highlightPopupOn").hide();
              $("#highlightPopup").show().css( {
                top: Math.floor( rect.top - $("#highlightPopup").height() ),
                left: Math.floor( rect.right )
              } );
      
           }, event.type == 'click' ? 0 : 800);
         })(highlightNode, highlightable);
         event.stopPropagation();
       }
     }
  };
  
  $._onTextHoldEnd = function(event){
    clearTimeout($._tapholdTimeout);
  };

  $.fn.textSelect = function(options) {
    var settings = $.extend({
      highlightLabel: "Highlight",
      unhighlightLabel: "Unhighlight",
      getID: null,
      labelParent: null
    }, options);

    function highlightSelection() {
      if (typeof $._highlightSelection === "undefined" || !$._highlightSelection) {
        return;
      }

      var topMostObj = $._highlightSelection.getRangeAt(0).nativeRange.startContainer.parentNode;
      var thObj = $(topMostObj).closest(".texthighlightable");

      var id = null;
      if (typeof settings.getID === "function" && settings.getID) {
        if (thObj.length) {
          id = settings.getID(thObj);
        } else {
          id = settings.getID();
        }
      }
      if (id) {
        $._highlighter.highlightSelection("highlight", {selection: $._highlightSelection, containerElementId: id});
      } else {
        $._highlighter.highlightSelection("highlight", {selection: $._highlightSelection});
      }
      
      clearSelection();

      // Notify highlightChanged (from the first node of the selection)
      if (thObj) {
        thObj.trigger("highlightChanged");
      }

      $("#highlightPopup").hide();
    }

    function unhighlightSelection() {

      if (unappliedHighlights.length) {
        for ( var i = 0; i < unappliedHighlights.length; i++ )
          unappliedHighlights[ i ].unapply();
        $._highlighter.removeHighlights(unappliedHighlights);
        clearSelection();
        var thObj = $( unappliedHighlights[ 0 ].getContainerElement() ).
          closest(".texthighlightable");
        if (thObj.length) {
          thObj.trigger("highlightChanged");
        }
        unappliedHighlights = [];
      }

      $("#highlightPopup").hide();
    }

    var highlightPopup = $("#highlightPopup");
    if (highlightPopup.length <= 0) {
      var el = document.createElement("div");
      highlightPopup = $(el);
      highlightPopup.attr("id", "highlightPopup");
      highlightPopup.html("<div id='highlightPopupOn'>" + settings.highlightLabel +
                          "</div><div id='highlightPopupOff'>" + settings.unhighlightLabel +
                          "</div>");
      highlightPopup.children("#highlightPopupOn").click(highlightSelection);
      highlightPopup.children("#highlightPopupOff").click(unhighlightSelection);
      $(settings.labelParent || "body").append(highlightPopup);
    }

    return this.each(function() {
      var $this = $(this);

      $this.addClass("texthighlightable");

      $this.bind("textSelectionChanged", function() {

        $._highlightSelection = rangy.getSelection();

        if ( !$('#highlightPopupOff').is(':visible') ) {

          $("#highlightPopupOn").show();
          $("#highlightPopupOff").hide();

          var bounds = getSelectionBoundaries();
          // Initialize the coordinates to prevent the values from being summed
          $("#highlightPopup").css( {top: 0, left: 0 } ).show();
          $("#highlightPopup").css( {
            top: Math.floor( bounds.top - $("#highlightPopup").height() ),
            left: Math.floor( bounds.right )
          } );
        }
      });
    });
  };
  
  /* Initialize rangy */
  $(document).ready(function() {
  
    try {
      rangy.init();
      $._highlighter = rangy.createAsteHighlighter(null, 'textContent');
      $._highlighter.addClassApplier(rangy.createClassApplier("highlight"));
    }
    catch ( e ) {
      Asteikko.log( 'Highlighter failed to load: '+ e );
      return; // fail silently
    }
  
    if ( $._checkTextSelection ) {
      $(document).unbind("selectionchange", $._checkTextSelection);
      $(document.body).unbind("mouseup", $._checkTextSelection);
      $(document.body).off("mousedown touchstart", $._hideUnhighlight);
      $(document.body).off("mousedown touchstart", ".highlight, .highlight *", $._onTextHoldStart);
      $(document.body).off("mouseup mouseleave touchend touchleave", ".highlight, .highlight *", $._onTextHoldEnd);
    }
  
    if ( "onselectionchange" in window.document ) {
      $(document).bind("selectionchange", $._checkTextSelection);
    }
    else {
      $(document.body).bind("mouseup", $._checkTextSelection);
    }

    $(document.body).on("mousedown touchstart MSPointerDown", $._hideUnhighlight);
    $(document.body).on("mousedown touchstart MSPointerDown", ".highlight, .highlight *", $._onTextHoldStart);
    $(document.body).on("mouseup mouseleave touchend touchleave MSPointerUp", ".highlight, .highlight *", $._onTextHoldEnd);
  });

})(jQuery);